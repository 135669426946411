import React from 'react';
import {
    Card,
    withStyles
} from '@material-ui/core';

const styles = {
    root: {
        backgroundColor: '#fafafa',
    },
};

const CardWithBackground = ({children, classes}) => {
    return (
        <Card className={classes.root}>
            {children}
        </Card>
    );
}

export default withStyles(styles)(CardWithBackground);
