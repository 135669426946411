import React, {useState} from 'react';
import {Button} from 'react-admin';
import {Https as LockIcon} from '@material-ui/icons';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from '@material-ui/core';
import {withTranslation} from "react-i18next";

const WebhookSecret = ({record: {description, destination, name, secret}, t}) => {
    const [open, setOpen] = useState(false);

    return <React.Fragment>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{t('Webhook secret')}</DialogTitle>
            <DialogContent>
                <Typography variant={'caption'} paragraph={true}>
                    {t('The POST request has a header x-signature containing a Hash HMAC SHA256 of the body keyed with the secret')}
                </Typography>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('Description')}</TableCell>
                            <TableCell>{description}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t('Target URL')}
                            </TableCell>
                            <TableCell>
                                <Typography component={'div'} >{destination}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{
                                whiteSpace: 'nowrap',
                            }}>{t('Secret key')}</TableCell>
                            <TableCell><Typography component={'div'}>{secret}</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
        <Button label={t('Webhook details')} onClick={() => setOpen(true)}><LockIcon/></Button>
    </React.Fragment>;
}

export default withTranslation()(WebhookSecret);
