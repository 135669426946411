import API from "../../api";
import {getResponseUrl} from "../entrypoint";

export async function getLandingPagesMergeTags(translator) {
    const landingPages = await getLandingPages();

    return {
        name: translator('Landing pages'),
        items: formatLandingPages(landingPages)
    };
}

async function getLandingPages() {
    return await API.getLandingPages(true);
}

function formatLandingPages(landingPages) {
    return landingPages.map(landingPage => formatLandingPage(landingPage))
}

function formatLandingPage(landingPage) {
    return {
        value: formatLandingPageUrl(landingPage),
        text: landingPage.name,
        label: landingPage.name,
    };
}

function formatLandingPageUrl(landingPage) {
    return `<a href="${getResponseUrl()}/page-${landingPage.id}">${landingPage.name}</a>`;
}

