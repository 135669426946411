import {
    SMTP_STATISTICS_DAY_NEXT, SMTP_STATISTICS_DAY_PREVIOUS,
    SMTP_STATISTICS_FETCH_FAILED,
    SMTP_STATISTICS_FETCH_START,
    SMTP_STATISTICS_FETCH_SUCCESS, SMTP_STATISTICS_MONTH_NEXT, SMTP_STATISTICS_MONTH_PREVIOUS
} from "./actionTypes";

export const smtpStatisticsFetchStart = () => ({type: SMTP_STATISTICS_FETCH_START});
export const smtpStatisticsFetchFailed = () => ({
    type: SMTP_STATISTICS_FETCH_FAILED,
});
export const smtpStatisticsFetchSuccess = (year, month, data) => ({
    type: SMTP_STATISTICS_FETCH_SUCCESS,
    payload: {
        year,
        month,
        data,
    },
})
export const smtpStatisticsDayNext = () => ({type: SMTP_STATISTICS_DAY_NEXT});
export const smtpStatisticsDayPrevious = () => ({type: SMTP_STATISTICS_DAY_PREVIOUS});
export const smtpStatisticsMonthNext = () => ({type: SMTP_STATISTICS_MONTH_NEXT});
export const smtpStatisticsMonthPrevious = () => ({type: SMTP_STATISTICS_MONTH_PREVIOUS});