import React from 'react';
import {connect} from 'react-redux';
import Dashboard from "./Dashboard";

const DashboardContainer = (props) => (
    <Dashboard {...props} />
);

const mapStateToProps = (state) => {
    return {
        campaignsScheduled: state.dashboard.campaignsScheduled,
        campaignsScheduledLoading: state.dashboard.campaignsScheduledLoading,
        campaignsSent: state.dashboard.campaignsSent,
        campaignsSentLoading: state.dashboard.campaignsSentLoading,
        contactCount: state.account.info.subscription.contactCount,
        contactLimit: state.account.info.subscription.contactLimit,
        infoLoading: state.account.infoLoading,
        isOverContactLimit: state.account.info.subscription.isOverContactLimit,
        messages: state.dashboard.messages,
        messagesLoading: state.dashboard.messagesLoading,
        messageCount: state.account.info.subscription.messageCount,
        messageLimit: state.account.info.subscription.messageLimit,
        isOverMessageLimit: state.account.info.subscription.isOverMessageLimit,
        stats: state.account.stats,
        statsLoading: state.account.statsLoading,
        subscription: state.account.info.subscription['@type'],
        username: state.account.info.name,
    }
};

export default connect(mapStateToProps)(DashboardContainer);
