import React from 'react';
import PropTypes from 'prop-types';
import {Toolbar} from 'react-admin';
import StructuredMessageSaveButton from './StructuredMessageSaveButton';
import HtmlMessageSaveButton from './HtmlMessageSaveButton';
import {useTranslation} from 'react-i18next';

const MessageEditToolbar = ({record, ...rest}) => {
    const {t} = useTranslation();

    return (
        <Toolbar {...rest}>
            {record.builder === 'html' &&
            <HtmlMessageSaveButton
                action={'save'}
                label={t('Save')}

            />}

            {record.builder === 'html' &&
            <HtmlMessageSaveButton
                action={'saveClose'}
                label={t('Save and Close')}
                style={{marginLeft: '10px'}}
            />}

            {record.builder === 'structured' &&
            <StructuredMessageSaveButton
                action={'save'}
                label={t('Save')}
            />}

            {record.builder === 'structured' &&
            <StructuredMessageSaveButton
                action={'saveClose'}
                label={t('Save and Close')}
                style={{marginLeft: '10px'}}
            />}
        </Toolbar>
    );
}

MessageEditToolbar.propTypes = {
    record: PropTypes.object,
};

export default MessageEditToolbar;