import React from 'react';
import {connect} from 'react-redux';
import MessageEdit from './MessageEdit';
import {
    messageChange,
    messageSave,
    messageToggleLinkEnabled, messageTopolSaving,
} from '../../actions/messageActions';
import {selectIsMessageSaving, selectMessage} from '../../selectors/messageSelectors';

class MessageEditContainer extends React.Component
{
    handleLinksTabClicked = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const {message, saveStructuredMessage, saveHtmlMessage} = this.props;

        if (message.builder === 'structured') {
            saveStructuredMessage('links');
        } else {
            saveHtmlMessage('links');
        }
    };

    render = () => {
        const {saveStructuredMessage, saveHtmlMessage, ...rest} = this.props;

        return (
            <MessageEdit
                {...rest}
                handleLinksTabClicked={this.handleLinksTabClicked}
            />);
    }
}

const mapStateToProps = (state) => {
    return {
        messageSaving: selectIsMessageSaving(state),
        message: selectMessage(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        change: (field, value) => dispatch(messageChange(field, value)),
        saveHtmlMessage: (redirect) => dispatch(messageSave(redirect)),
        saveStructuredMessage: (redirect) => dispatch(messageTopolSaving(redirect)),
        toggleEnabled: (url) => dispatch(messageToggleLinkEnabled(url)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageEditContainer);