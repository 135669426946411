import React from 'react';
import {
    Filter,
    TextInput,
} from 'react-admin';

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput
            autoFocus
            label={'Company'}
            source={'profile.company'}
        />
        <TextInput
            autoFocus
            label={'Email'}
            source={'email'}
        />
        <TextInput
            autoFocus
            label={'First Name'}
            source={'profile.firstName'}
        />
        <TextInput
            autoFocus
            label={'Last Name'}
            source={'profile.lastName'}
        />
    </Filter>
);

export default UserFilter;