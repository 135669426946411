import {TextField} from "react-admin";
import React from "react";
import PropTypes from 'prop-types';

const DateField = ({label, record, sortBy, source, style}) => {
    if (!record.hasOwnProperty(source) || !record[source]) {
        return <span/>;
    }

    const parts = record[source].split(' ');
    const input = parts[0] + 'T' + parts[1] + '.000+00:00';
    const formatter = new Intl.DateTimeFormat('be-NL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    const dateParts = formatter.formatToParts(
        new Date(
            Date.parse(input)
        )
    );

    record.parsedCreatedAt =
        dateParts[4].value + '-' +
        dateParts[2].value + '-' +
        dateParts[0].value + ' ' +
        dateParts[6].value + ':' +
        dateParts[8].value + ':' +
        dateParts[10].value;

    return <TextField source={'parsedCreatedAt'} label={label} record={record} sortBy={sortBy} style={style}/>;
};

DateField.propTypes = {
    label: PropTypes.string.isRequired,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
};

export default DateField;