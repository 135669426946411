import React from 'react';
import {
    FormControl,
    FormControlLabel,
    Switch,
} from '@material-ui/core/';
import {
    Email,
    Today as ScheduleIcon
} from '@material-ui/icons';
import {DateTimeInput} from 'react-admin-date-inputs';
import SimpleFormIterator from '../common/Form/SimpleFormIterator';
import {useTranslation} from 'react-i18next';

const CampaignEditSend = ({campaign, change}) => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <FormControl fullWidth>
                <FormControlLabel
                    control={
                        <Switch
                            color={'primary'}
                            onChange={(e) => change('notify', e.target.checked)}
                            checked={campaign.notify}
                            checkedIcon={
                                <Email/>
                            }
                        />
                    }
                    label={t('Send a notification email when the campaign has finished sending')}
                />
            </FormControl>

            {campaign.notify &&
            <SimpleFormIterator
                campaign={campaign}
                change={change}
                field={'notifications'}
            />}

            <FormControl fullWidth>
                <FormControlLabel
                    control={
                        <Switch
                            color={'primary'}
                            onChange={(e) => change('schedule', e.target.checked)}
                            checked={campaign.schedule}
                            checkedIcon={
                                <ScheduleIcon/>
                            }
                        />
                    }
                    label={t('Schedule')}
                />
            </FormControl>

            {campaign.schedule && <DateTimeInput
                fullWidth
                record={{
                    scheduledTimestamp: campaign.scheduledOn.timestamp,
                }}
                source={'scheduledTimestampUtc'}
                input={{
                    onBlur: () => {},
                    onChange: (date) => {
                        change('scheduledTimestampUtc', date);
                        change('scheduledTimestamp', ParseDateUtcToLocalTimezone(date));
                    },
                    value: campaign.scheduledOn.timestamp,
                }}
                options={{
                    format: 'yyyy-MM-dd HH:mm',
                    ampm: false
                }}
                label={t('Schedule on')}
                isRequired={false}
            />}
        </React.Fragment>
    );
}

const ParseDateUtcToLocalTimezone = (date) => {
    const formatter = new Intl.DateTimeFormat('be-NL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const dateParts = formatter.formatToParts(date);

    return dateParts[4].value + '-' +
        dateParts[2].value + '-' +
        dateParts[0].value + ' ' +
        dateParts[6].value + ':' +
        dateParts[8].value;
};

export default CampaignEditSend;
