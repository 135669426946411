import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'
import {useTranslation} from 'react-i18next';

const CampaignContactCounter = props => {
    const {t} = useTranslation();
    const {
        hasFilters,
        'loading-unique-contacts': loading,
        'unique-contacts': contacts,
    } = props;

    return (
        <React.Fragment>
            <Typography variant={'body2'}>
                {loading && <CircularProgress size={14} style={{marginRight: '4px'}}/>}
                {!loading && contacts}
                <span style={{paddingLeft: '4px'}}>{hasFilters ? t('unique recipients before filters') : t('unique recipients')}</span>
            </Typography>
        </React.Fragment>
    );
};

CampaignContactCounter.propTypes = {
    hasFilters: PropTypes.bool.isRequired,
    'unique-contacts': PropTypes.number.isRequired,
    'loading-unique-contacts': PropTypes.bool.isRequired,
};

export default CampaignContactCounter;
