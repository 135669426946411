import React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    TextField,
} from 'react-admin';
import CardActions from '../common/CardActions';
import AttributeFilter from './AttributeFilter';
import DateField from '../common/Field/DateField';
import {useTranslation} from "react-i18next";

const AttributeList = (props) => {
    const {t} = useTranslation();

    return (
        <List raised={0} elevation={0} title={t('Contact Attributes')} {...props} filters={<AttributeFilter/>} sort={{field: 'name.value', order: 'ASC'}} actions={<CardActions/>}>
            <Datagrid>
                <TextField autoFocus source={'name'} label={t('Name')} sortBy={'name.value'}/>
                <DateField label={t('Created')} source={'createdAt'}/>
                {props.hasEdit && <EditButton/>}
            </Datagrid>
        </List>
    );
}

export default AttributeList;
