import React from 'react';
import PropTypes from 'prop-types';
import CampaignScheduleDialog from "./CampaignScheduleDialog";

class CampaignScheduleDialogContainer extends React.Component {
    state = {
        open: true,
        recipients: null,
        scheduling: false,
        scheduleDisabled: true
    };

    constructor(props) {
        super(props);

        // initialize state
        this.state.open = props.open;
        this.state.recipients = props.recipients;
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.recipients !== nextProps.recipients) {
            this.setState({recipients: nextProps.recipients});
        }
    }

    // Modal, display functions
    display = (open) => (
        this.setState({open})
    );

    close = () => {
        this.props.hideScheduleDialog();
        this.display(false);
        this.setState({
            scheduling: false
        })
    };

    validateRecipients(recipients) {
        this.setState({
            scheduleDisabled: (this.state.recipients !== Number(recipients))
        });
    }

    scheduleHandler() {
        this.setState({scheduling: true});
        this.props.schedule();
    }

    render() {
        const {recipients, title} = this.props;
        const {scheduling, scheduleDisabled} = this.state;

        const open = (!this.props.open) ? this.state.open : true;

        return <CampaignScheduleDialog isScheduling={scheduling}
                                       scheduleDisabled={scheduleDisabled}
                                       onClose={this.close}
                                       onSchedule={() => this.scheduleHandler()}
                                       open={open}
                                       validateRecipients={(recipients) => this.validateRecipients(recipients)}
                                       recipients={recipients}
                                       title={title}/>
    };
}

CampaignScheduleDialogContainer.propTypes = {
    hideScheduleDialog: PropTypes.func,
    open: PropTypes.bool,
    recipients: PropTypes.number,
    schedule: PropTypes.func,
    title: PropTypes.string,
};

export default CampaignScheduleDialogContainer;