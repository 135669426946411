export const styles = (theme) => ({
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    toolbarDeviceSwitchRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        backgroundColor: theme.palette.secondary.light,
        paddingTop: '42px',
    },
    iconNotSelected: {
        color: theme.palette.primary.dark,
    },
    iconSelected: {
        color: theme.palette.secondary.light,
    },
    previewRoot: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
    },
    previewComputer: {
        backgroundColor: '#ffffff',
        height: '100%',
        width: '100%',
    },
    previewPhone: {
        backgroundColor: '#ffffff',
        height: '100%',
        width: '720px',
    }
});
