import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, showNotification} from 'react-admin';
import VerifyIcon from '@material-ui/icons/VerifiedUser';
import CircularProgress from '@material-ui/core/CircularProgress'
import {push} from 'react-router-redux';
import {entrypoint} from '../../config/config';
import {httpClient} from '../httpClient';
import {withTranslation} from 'react-i18next';

class VerifyButton extends Component {
    state = {
        loading: false,
        id: Math.random(0, 1000)
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        this.setState({loading: true});

        const {push, record, showNotification, t} = this.props;

        httpClient(`${entrypoint}/campaign/senders/${record.id}/verify`, {
            method: 'POST'
        })
            .then(() => {
                this.setState({loading: false});
                showNotification(t('A verification email has been sent to the sender\'s email address'));
                push('/campaign/senders');
            })
            .catch((e) => {
                this.setState({loading: false});
                showNotification('Error: could not verify', 'warning');
            });
    };

    render() {
        const {t} = this.props;

        return <Button label={t('Verify')} onClick={this.handleClick} disabled={'verified' === this.props.record.state}>
            <React.Fragment>
                {this.state.loading ? <CircularProgress size={20}/> : <VerifyIcon/>}
            </React.Fragment>
        </Button>;
    }
}

export default connect(null, {
    showNotification,
    push,
})(withTranslation()(VerifyButton));