import React from 'react';
import {connect} from 'react-redux';
import {submit, isSubmitting} from 'redux-form';
import {SimpleForm, FileField, FileInput} from 'react-admin';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import ContactImportToolbar from './ContactImportToolbar';
import {withTranslation} from 'react-i18next';

class ContactImportDialogStep1 extends React.Component {
    handleSaveClick = () => {
        const {submit} = this.props;

        submit('import-upload-form');
    };

    render() {
        const {t} = this.props;

        return <div>
            <DialogContentText>{t('Select a file to import.')}</DialogContentText>

            <SimpleForm toolbar={<ContactImportToolbar label={t('Upload')} handleSaveClick={this.handleSaveClick}/>}
                        form='import-upload-form' onSubmit={this.props.handleFileUpload}>
                <FileInput source='file' label={t('Supported file types: csv/xls/xlsx')} multiple={false}>
                    <FileField source='src' title='title'/>
                </FileInput>
            </SimpleForm>
        </div>;
    }
}

ContactImportDialogStep1.propTypes = {
    handleFileUpload: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('post-quick-create')(state)
});

const mapDispatchToProps = {
    submit
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactImportDialogStep1));