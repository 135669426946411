import React from 'react';
import {BulkDeleteButton} from 'react-admin';
import BulkArchiveButton from "../common/Button/BulkArchiveButton";
import BulkRestoreButton from "../common/Button/BulkRestoreButton";

const CampaignListBulkActionButtons = (props) => (
    <React.Fragment>
        {!props.filterValues['inArchive'] && <BulkArchiveButton {...props} />}
        {props.filterValues['inArchive'] && <BulkRestoreButton {...props} />}
        <BulkDeleteButton {...props}/>
    </React.Fragment>);

CampaignListBulkActionButtons.propTypes = {};

export default CampaignListBulkActionButtons;