export const campaignSelectCampaign = state => state.campaign.campaign;
export const campaignSelectWizard = state => state.campaign.wizard;
export const campaignSelector = state => state.form['record-form'].values.id;
export const campaignReportListId = state => state.campaign.reportListMeta.id;
export const campaignReportListEvent = state => state.campaign.reportListMeta.event;
export const campaignReportListPage = state => state.campaign.reportListMeta.page;
export const campaignReportListPerPage = state => state.campaign.reportListMeta.rowsPerPage;
export const selectCampaignWizardLinksFirstInQueue = state => state.campaign.wizard.links.queue.length > 0 ? state.campaign.wizard.links.queue[0] : null;

export const campaignSelectWizardLoading = state => {
    const {campaign: {wizard}} = state;

    return wizard.groups.loaded
        && wizard.messages.loaded
        && wizard.senders.loaded;
};
