import {
    DASHBOARD_CAMPAIGNS_SCHEDULED_SET,
    DASHBOARD_CAMPAIGNS_SENT_SET,
    DASHBOARD_MESSAGES_SET
} from './actionTypes';

export const dashboardMessagesSet = (payload) => ({
    type: DASHBOARD_MESSAGES_SET,
    payload: payload
});

export const dashboardCampaignsScheduledSet = (payload) => ({
    type: DASHBOARD_CAMPAIGNS_SCHEDULED_SET,
    payload: payload
});

export const dashboardCampaignsSentSet = (payload) => ({
    type: DASHBOARD_CAMPAIGNS_SENT_SET,
    payload: payload
});