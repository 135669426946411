export const selectMessage = state => state.message.data;
export const selectMessageId = state => state.message.meta.id;
export const selectMessageForm = state => state.form;
export const selectMessageLinks = state => state.message.links;
export const selectMessageType = state => state.message.meta.builder;
export const selectMessageValues = state => state.message;
export const selectMessageFormValues = state => (state.form && state.form['record-form'] && state.form['record-form'].values) ? state.form['record-form'].values : [];
export const selectMessageSaveAction = state => state.message.meta.action;
export const selectMessagePreviewOpen = state => state.message.meta.preview.open;
export const selectMessageSendPreviewTo = state => state.message.meta.sendPreviewTo;
export const selectIsMessageSaving = state => state.message.meta.saving;