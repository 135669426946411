import React from 'react';
import PropTypes from "prop-types";
import {Create, SimpleForm, FileField, FileInput} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withTranslation} from 'react-i18next';

class BlacklistImportDialog extends React.Component {
    render() {
        let createProps = {
            resource: 'blacklist/contacts/import',
            basePath: '/blacklist/contacts',
            location: {pathname: "/", search: "", hash: "", state: undefined},
            match: {path: "/", url: "/", isExact: true, params: {}},
        };

        const {showDialog, onClose, t} = this.props;
        const {resource, basePath, location, match } = createProps;
        return <Dialog form="import-blacklist-form" open={showDialog} aria-labelledby="form-dialog-title" onClose={onClose}>
            <DialogTitle id="form-dialog-title">{t('Import a file to the blacklist')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('Use a CSV or Excel file format with a single column containing the email address to blacklist. Enter one email address per row in the file.')}</DialogContentText>
                <Create resource={resource} basePath={basePath} location={location} match={match}>
                    <SimpleForm>
                        <FileInput source="file" label={t('Selected file')} multiple={false}>
                            <FileField source="src" title="title"/>
                        </FileInput>
                    </SimpleForm>
                </Create>
            </DialogContent>
        </Dialog>;
    }
}

BlacklistImportDialog.propTypes = {
    showDialog: PropTypes.bool,
};

export default withTranslation()(BlacklistImportDialog);