import React from 'react';

const SenderStateField = ({record: {state}, translate: t}) => {
    const translator = {
        'failed': t('failed'),
        'not-verified': t('not-verified'),
        'verified': t('verified'),
    };

    return <span>{translator[state]}</span>;
}

export default SenderStateField;
