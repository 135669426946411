import {contactNormalizer} from './contactNormalizer';
import {subscriptionNormalizer} from './subscriptionNormalizer';

/**
 * Register normalizers in this function
 * 
 * @param response
 * @param type
 * @param resource
 * @param params
 * @returns {*}
 */
export const responseNormalizer = (response, type, resource, params) => {
    response = contactNormalizer(response, type, resource, params);
    response = subscriptionNormalizer(response, type, resource, params);

    return response;
};
