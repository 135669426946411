import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    smtpStatisticsDayNext,
    smtpStatisticsDayPrevious,
    smtpStatisticsFetchStart,
    smtpStatisticsMonthNext,
    smtpStatisticsMonthPrevious,
} from '../../actions/smtpActions';
import {
    smtpStatisticsIsLoaded,
    smtpStatisticsIsLoading,
    smtpStatisticsSelectData,
    smtpStatisticsSelectDay,
    smtpStatisticsSelectMonth,
    smtpStatisticsSelectYear,
} from '../../selectors/smtpSelectors';
import {Statistics} from './Statistics';

const StatisticsContainer = ({
                                 data,
                                 day,
                                 fetch,
                                 isLoaded,
                                 month,
                                 nextDay,
                                 nextMonth,
                                 previousDay,
                                 previousMonth,
                                 year
                             }) => {

    // load initial data
    useEffect(() => {
        fetch();
    }, []);

    if (!isLoaded)
        return (<div>loading</div>);

    const {bounced, read, sent, visited} = data;
    const monthTotalBounced = bounced[0];
    const monthTotalRead = read[0];
    const monthTotalSent = sent[0];
    const monthTotalVisited = visited[0];
    const dayBounced = bounced[day];
    const dayRead = read[day];
    const daySent = sent[day];
    const dayVisited = visited[day];

    return <Statistics
        data={data}
        day={day}
        dayBounced={dayBounced}
        dayRead={dayRead}
        daySent={daySent}
        dayVisited={dayVisited}
        month={month}
        monthTotalBounced={monthTotalBounced}
        monthTotalRead={monthTotalRead}
        monthTotalSent={monthTotalSent}
        monthTotalVisited={monthTotalVisited}
        nextDay={day === (sent.length - 1) ? null : nextDay}
        nextMonth={nextMonth}
        previousDay={day === 1 ? null : previousDay}
        previousMonth={previousMonth}
        year={year}
    />;
};

StatisticsContainer.propTypes = {
    data: PropTypes.object,
    day: PropTypes.number.isRequired,
    fetch: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    month: PropTypes.number.isRequired,
    nextDay: PropTypes.func.isRequired,
    nextMonth: PropTypes.func.isRequired,
    previousDay: PropTypes.func.isRequired,
    previousMonth: PropTypes.func.isRequired,
    year: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    data: smtpStatisticsSelectData(state),
    day: smtpStatisticsSelectDay(state),
    isLoaded: smtpStatisticsIsLoaded(state),
    isLoading: smtpStatisticsIsLoading(state),
    month: smtpStatisticsSelectMonth(state),
    year: smtpStatisticsSelectYear(state),
})

const mapDispatchToProps = dispatch => ({
    fetch: () => dispatch(smtpStatisticsFetchStart()),
    nextDay: () => dispatch(smtpStatisticsDayNext()),
    nextMonth: () => dispatch(smtpStatisticsMonthNext()),
    previousDay: () => dispatch(smtpStatisticsDayPrevious()),
    previousMonth: () => dispatch(smtpStatisticsMonthPrevious()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsContainer);