import { GET_ONE } from 'react-admin';

/**
 * @param response
 * @param type
 * @param resource
 * @param params
 * @returns {*}
 */
export const contactNormalizer = (response, type, resource, params) => {
    if (resource !== 'contacts') {
        return response;
    }

    if (type === GET_ONE) {
        response = normalizeAttributes(response);
    }

    return response;
};

/**
 * Refactor attributes array to basic string inputs
 *
 * @param response
 * @returns {*}
 */
const normalizeAttributes = (response) => {
    response.json.attributes.forEach((a) => response.json[`attribute_${a.attributeId}`] = a.value);

    return response;
};