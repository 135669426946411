import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useTranslation} from 'react-i18next';

const CampaignFilterType = ({value, onChange}) => {
    const {t} = useTranslation();
    const TYPE_ATTRIBUTE = 'attribute';
    const TYPE_EVENT = 'event';

    return (
        <div style={{marginRight: '20px'}}>
            <FormControl>
                <InputLabel htmlFor='type'>{t('Type')}</InputLabel>
                <Select
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    inputProps={{
                        name: 'type',
                        id: 'type',
                    }}
                    style={{
                        minWidth: '150px'
                    }}
                >
                    <MenuItem value={TYPE_ATTRIBUTE}>{t('Contact attribute')}</MenuItem>
                    <MenuItem value={TYPE_EVENT}>{t('Event')}</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

CampaignFilterType.propTypes = {
    value: PropTypes.oneOf(['attribute', 'event']).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CampaignFilterType;
