import {
    ACCOUNT_GET_INFO,
    ACCOUNT_GET_STATS,
    ACCOUNT_RESET_PASSWORD,
    ACCOUNT_SET_ATTRIBUTES
} from './actionTypes';

export const accountGetInfo = (info) => ({
    type: ACCOUNT_GET_INFO,
    payload: { info }
});

export const accountGetStats = (stats) =>({
    type: ACCOUNT_GET_STATS,
    payload: { stats }
});

export const accountSetAttributes = (attributes) => ({
    type: ACCOUNT_SET_ATTRIBUTES,
    attributes: attributes,
});

export const accountResetPassword = () => ({
    type: ACCOUNT_RESET_PASSWORD
});
