import React from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ContactImportDialogStep1 from './ContactImportStep1';
import ContactImportDialogStep2 from './ContactImportStep2';
import ContactImportDialogStep3 from './ContactImportStep3';
import {withTranslation} from 'react-i18next';

import {
    handleUploadedFile,
    submitMapHandler
} from '../../actions/contactImportActions';

class ContactImportDialog extends React.Component {
    render() {
        const {dialog, onClose, fileUploadHandler, submitMapHandler, data, attributes, t} = this.props;
        return <Dialog form='import-blacklist-form' open={dialog.display} aria-labelledby='form-dialog-title'
                       onClose={onClose} fullWidth maxWidth='md'>
            <DialogTitle id='form-dialog-title'>{t('Import contacts')}</DialogTitle>
            <DialogContent>
                {dialog.step === 1 && <ContactImportDialogStep1 handleFileUpload={fileUploadHandler}/>}
                {dialog.step === 2 && <ContactImportDialogStep2 handleSubmitMap={submitMapHandler} headers={data[0]}
                                                                attributes={attributes}/>}
                {dialog.step === 3 && <ContactImportDialogStep3/>}
            </DialogContent>
        </Dialog>;
    }
}

const mapStateToProps = state => ({
    dialog: state.contactImport.ui,
    data: state.contactImport.data,
    attributes: state.contactImport.attributes
});

const mapDispatchToProps = (dispatch) => {
    return {
        fileUploadHandler: (values) => handleUploadedFile(dispatch, values),
        submitMapHandler: (values) => submitMapHandler(dispatch, values),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactImportDialog));