import React from 'react';
import {useTranslation} from 'react-i18next';

const GroupTitle = ({record}) => {
    const {t} = useTranslation();

    return (
        <span>{t('Group # %{name}', {name: record.name})}</span>
    );
}

export default GroupTitle;