import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormControl, Typography} from '@material-ui/core/';
import {campaignSelectCampaign} from '../../selectors/campaignSelector';
import CampaignInput from "./CampaignInput";

const CampaignField = ({campaign, field, label}) => {
    return (
        <CampaignInput>
            <FormControl>
                <Typography variant={'caption'} component={'div'}>{label}</Typography>
                <Typography variant={'body2'} component={'div'} style={{marginTop: '8px'}}>{campaign[field]}</Typography>
            </FormControl>
        </CampaignInput>
    );
};

CampaignField.propTypes = {
    campaign: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    campaign: campaignSelectCampaign(state)
});

export default connect(mapStateToProps, null)(CampaignField);