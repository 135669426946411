import React from 'react';
import {
    BooleanInput,
    Filter,
    SelectInput,
    TextInput,
} from 'react-admin';
import {useTranslation} from 'react-i18next';

const CampaignFilter = (props) => {
    const {t} = useTranslation();

    return (
        <Filter {...props}>
            <TextInput autoFocus label={t('Name')} source={'name.value'} />
            <BooleanInput label={t('In archive')} source={'inArchive'} alwaysOn />
            <SelectInput source={'state.value'} choices={[
                {id: 'new', name: t('New')},
                {id: 'scheduled', name: t('Scheduled')},
                {id: 'sending', name: t('Sending')},
                {id: 'done', name: t('Sent')},
            ]} label={t('State')}/>
        </Filter>
    );
}

export default CampaignFilter;