import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    email,
    maxLength,
    minLength,
    required
} from 'react-admin'
import {useTranslation} from 'react-i18next';

const validateEmail = [
    required(),
    email(),
    minLength(5),
    maxLength(150)
];

const BlacklistCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props}>
            <SimpleForm redirect={'/blacklist/contacts'}>
                <TextInput autoFocus label={t('Email')} source={'email'} validate={validateEmail} fullWidth/>
            </SimpleForm>
        </Create>
    );
}

export default BlacklistCreate;