import React from 'react';
import {connect} from 'react-redux';
import {
    Datagrid,
    List,
    TextField,
} from 'react-admin';
import CardActions from '../common/CardActions';
import SenderFilter from './SenderFilter';
import VerifyButton from './VerifyButton';
import DateField from '../common/Field/DateField';
import SmtpCredentials from './SmtpCredentialsButton';
import {withTranslation} from 'react-i18next';
import SenderStateField from "./SenderStateField";

const SenderList = ({modules, dispatch, t, ...props}) => (
    <List title={t('Senders')} {...props} filters={<SenderFilter/>} sort={{field: 'name', order: 'ASC'}} actions={<CardActions/>}>
        <Datagrid>
            <TextField source={'name'} sortBy={'person.name'} label={t('Name')}/>
            <TextField source={'email'} sortBy={'person.email'} label={t('Email')}/>
            <SenderStateField label={t('State')} sortable={false} translate={t}/>
            <DateField source={'createdAt'} label={t('Created')}/>
            <VerifyButton {...props} />
            {modules.includes('SMTP') && <SmtpCredentials {...props} />}
        </Datagrid>
    </List>
);

const mapStateToProps = (state) => {
    return {
        modules: state.account.info.modules,
    }
};
export default connect(mapStateToProps)(withTranslation()(SenderList));
