import React from 'react';
import {Create} from 'react-admin';
import TagForm from './TagForm';

const TagCreate = props => (
    <Create {...props}>
        <TagForm/>
    </Create>
);

export default TagCreate;