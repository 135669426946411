import React from 'react';
import PropTypes from 'prop-types';
import SmtpChart from './SmtpChart';
import {Grid, IconButton, Typography} from '@material-ui/core/';
import {SkipNext, SkipPrevious} from '@material-ui/icons/';
import MetricBox from './MetricBox';
import {useTranslation} from 'react-i18next';

const months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
};

export const Statistics = ({
                               data,
                               day,
                               dayBounced,
                               dayRead,
                               daySent,
                               dayVisited,
                               month,
                               monthTotalBounced,
                               monthTotalRead,
                               monthTotalSent,
                               monthTotalVisited,
                               nextDay,
                               nextMonth,
                               previousDay,
                               previousMonth,
                               year,
                           }) => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Grid container spacing={24}>
                <Grid item xs={12} style={{
                    marginBottom: '24px',
                }}>
                    <Typography variant={'title'}>{t('Daily totals')}</Typography>

                    <Grid item xs={12} style={{
                        marginBottom: '12px',
                        marginTop: '24px',
                    }}>
                        <IconButton color={'primary'} onClick={() => previousDay ? previousDay() : null} disabled={!previousDay}>
                            <SkipPrevious/>
                        </IconButton>
                        <Typography style={{
                            display: 'inline',
                            paddingLeft: '6px',
                            paddingRight: '6px',
                        }}>{`${day}/${month}/${year}`}</Typography>
                        <IconButton color={'primary'} onClick={() => nextDay ? nextDay() : null} disabled={!nextDay}>
                            <SkipNext/>
                        </IconButton>
                    </Grid>

                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            <MetricBox label={t('Sent')} type={'sent'} value={daySent}/>
                        </Grid>
                        <Grid item xs={3}>
                            <MetricBox id={2} label={t('Opened')} type={'opened'} value={dayRead}/>
                        </Grid>
                        <Grid item xs={3}>
                            <MetricBox id={3} label={t('Clicked')} type={'clicked'} value={dayVisited}/>
                        </Grid>
                        <Grid item xs={3}>
                            <MetricBox id={4} label={t('Bounced')} type={'bounced'} value={dayBounced}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{
                    marginBottom: '48px',
                }}>
                    <Typography variant={'title'}>{t('Monthly totals')}</Typography>

                    <Grid item xs={12} style={{
                        marginBottom: '12px',
                        marginTop: '24px',
                    }}>
                        <IconButton color={'primary'} onClick={() => previousMonth()}>
                            <SkipPrevious/>
                        </IconButton>
                        <Typography style={{
                            display: 'inline',
                            paddingLeft: '6px',
                            paddingRight: '6px',
                        }}>{`${months[month]} ${year}`}</Typography>
                        <IconButton color={'primary'} onClick={() => nextMonth()}>
                            <SkipNext/>
                        </IconButton>
                    </Grid>

                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            <MetricBox label={t('Sent')} type={'sent'} value={monthTotalSent}/>
                        </Grid>
                        <Grid item xs={3}>
                            <MetricBox id={2} label={t('Opened')} type={'opened'} value={monthTotalRead}/>
                        </Grid>
                        <Grid item xs={3}>
                            <MetricBox id={3} label={t('Clicked')} type={'clicked'} value={monthTotalVisited}/>
                        </Grid>
                        <Grid item xs={3}>
                            <MetricBox id={4} label={t('Bounced')} type={'bounced'} value={monthTotalBounced}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <SmtpChart data={data}/>
                </Grid>
            </Grid>
        </React.Fragment>
);
}

Statistics.propTypes = {
    data: PropTypes.object.isRequired,
    day: PropTypes.number.isRequired,
    dayBounced: PropTypes.number.isRequired,
    dayRead: PropTypes.number.isRequired,
    daySent: PropTypes.number.isRequired,
    dayVisited: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    monthTotalRead: PropTypes.number.isRequired,
    monthTotalSent: PropTypes.number.isRequired,
    monthTotalVisited: PropTypes.number.isRequired,
    nextDay: PropTypes.func,
    nextMonth: PropTypes.func,
    previousDay: PropTypes.func,
    previousMonth: PropTypes.func,
    year: PropTypes.number.isRequired,
}
