import i18next from "i18next";

const validation = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = [i18next.t('%{field} is required', {field: i18next.t('Name')})];
    }

    if (!values.email) {
        errors.email = [i18next.t('%{field} is required', {field: i18next.t('Email')})];
    }

    return errors;
};

export default validation;