import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {
    messagePreviewHide,
    messagePreviewShow,
    messageSave,
    messageSendPreviewTo,
    messageTopolSaving
} from '../../actions/messageActions';
import {
    selectIsMessageSaving,
    selectMessage,
    selectMessagePreviewOpen,
    selectMessageType
} from '../../selectors/messageSelectors';
import PreviewButton from './PreviewButton';

class PreviewButtonContainer extends React.Component {
    handlePreview = () => {
        const {saveHtmlMessage, saveStructuredMessage, type} = this.props;

        if (type === 'structured') {
            saveStructuredMessage('preview');
        } else {
            saveHtmlMessage('preview');
        }
    };

    render() {
        const {disabled, hideDialog, open, saving, setSendTo, showDialog} = this.props;
        return (
            <PreviewButton
                disabled={disabled || saving}
                onCancel={hideDialog}
                onChangeEmail={setSendTo}
                onOpen={showDialog}
                onSend={this.handlePreview}
                open={open}
            />
        );
    }
}

PreviewButtonContainer.propTypes = {
    disabled: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    disabled: null === selectMessage(state).id,
    open: selectMessagePreviewOpen(state),
    saving: selectIsMessageSaving(state),
    type: selectMessageType(state),
});

const mapDispatchToProps = (dispatch) => ({
    hideDialog: () => dispatch(messagePreviewHide()),
    saveHtmlMessage: (redirect) => dispatch(messageSave(redirect)),
    saveStructuredMessage: (redirect) => dispatch(messageTopolSaving(redirect)),
    setSendTo: (to) => dispatch(messageSendPreviewTo(to)),
    showDialog: () => dispatch(messagePreviewShow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewButtonContainer);