import React from 'react';
import {CardActions as BaseCardActions, CreateButton} from 'react-admin';
import ImportButton from "./BlacklistImportButton";

const BlacklistCardActions = ({
                                  bulkActions,
                                  basePath,
                                  displayedFilters,
                                  filters,
                                  filterValues,
                                  onUnselectItems,
                                  resource,
                                  selectedIds,
                                  showFilter,
                              }) => (
    <BaseCardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath}/>
        <ImportButton/>
    </BaseCardActions>
);

export default BlacklistCardActions;