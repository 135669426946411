import React from 'react';
import PropTypes from 'prop-types';
import CampaignFilterType from './CampaignFilterType';
import CampaignFilterAttributeAttributes from './CampaignFilterAttributeAttributes';
import CampaignFilterEventAttributes from './CampaignFilterEventAttributes';
import { Button } from 'react-admin';
import {RemoveCircleOutline} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';

const CampaignFilterRow = ({filter, index, onRemove, onUpdate}) => {
    const {t} = useTranslation();

    const changeTypeHandler = (value) => {
        onUpdate(index, {type: value });
    }

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            marginBottom: '30px',
            marginTop: '30px',
        }}>
            <CampaignFilterType value={filter.type} onChange={changeTypeHandler}/>
            {filter.type === 'attribute' && <CampaignFilterAttributeAttributes index={index} filter={filter} onUpdate={onUpdate}/>}
            {filter.type ==='event' && <CampaignFilterEventAttributes index={index} filter={filter} onUpdate={onUpdate}/>}
            <Button label={t('REMOVE')} onClick={() => onRemove(index)}>
                <RemoveCircleOutline/>
            </Button>
        </div>
    );
};

CampaignFilterRow.propType = {
    filter: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
}

export default CampaignFilterRow;
