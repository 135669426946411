import React from 'react';
import {Create, email, maxLength, minLength, required, SimpleForm, TextInput} from 'react-admin'
import {connect} from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import {GroupHelper} from '../../util/GroupHelper';
import {useTranslation} from 'react-i18next';

const validateEmail = [required(), email(), minLength(5), maxLength(150)];

const ContactCreate = ({attributes, resource, basePath, location, match}) => {
    const {t} = useTranslation();

    if (null === attributes) {
        return <LinearProgress/>;
    }

    return <Create resource={resource} basePath={basePath} location={location} match={match} title={t('Create Contact')}>
        <SimpleForm redirect={`/contacts?group=${GroupHelper.getContactListGroupId()}`}>
            <TextInput autoFocus label={t('Email')} source={'email'} validate={validateEmail} fullWidth/>
            {attributes && attributes.map((attribute) =>
                <TextInput fullWidth={true}
                           key={attribute.id}
                           label={attribute.name}
                           source={`attribute_${attribute.id}`}/>
            )}
        </SimpleForm>
    </Create>
};

const mapStateToProps = (state) => ({
    attributes: state.account.data.attributes,
});

export default connect(mapStateToProps)(ContactCreate);