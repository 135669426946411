import produce from 'immer';
import { CONTACT_LIST_SET_GROUP } from '../actions/actionTypes';
import { GroupHelper } from '../util/GroupHelper';

const initialState = {
    group: {
        id: null,
    }
};

const contactListReducer = (state = initialState, action) => {
    return produce(state, draft => {
        const {payload, type} = action;

        switch (type) {
            case CONTACT_LIST_SET_GROUP:
                GroupHelper.setContactListGroupId(payload.group.id);

                draft.group.id = payload.group.id;
                break;

            // no default
        }
    })
};

export default contactListReducer;