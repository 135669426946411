import React from 'react';
import {Button} from 'react-admin';
import PropTypes from "prop-types";
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/Assignment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';


const ContactImportReport = (props) => {
    const {open, close, download, downloading, filename, group, contactsCreated, contactsUpdated} = props;

    return <Dialog open={open}>
        <DialogTitle>A group import has been completed</DialogTitle>
        <DialogContent>
            <DialogContentText>{`Your file "${filename}" has completed importing into group "${group}".`}</DialogContentText>
            <DialogContentText>{`${contactsCreated} contact have been added to the group`}</DialogContentText>
            <DialogContentText>{`${contactsUpdated} contact have been updated`}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button label={'Download report'} onClick={download} disabled={downloading}>
                <DownloadIcon/>
            </Button>
            <Button label={'close'} onClick={close}>
                <CloseIcon/>
            </Button>
        </DialogActions>
        {downloading && <LinearProgress/>}
    </Dialog>;
};

ContactImportReport.propTypes = {
    close: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    downloading: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    filename: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    contactsCreated: PropTypes.number.isRequired,
    contactsUpdated: PropTypes.number.isRequired,
};

export default ContactImportReport;