import {
    MESSAGES_DESTROY,
    MESSAGE_DUPLICATE,
    MESSAGE_LINK_TOGGLE_ENABLED,
    MESSAGE_PREVIEW_HIDE,
    MESSAGE_PREVIEW_SHOW,
    MESSAGE_SAVE,
    MESSAGE_SAVED,
    MESSAGE_SET_LINKS,
    MESSAGE_TOPOL_SAVED,
    MESSAGE_TOPOL_SAVING,
    MESSAGE_SHOW_IN_BROWSER, MESSAGE_CHANGE, MESSAGE_SEND_PREVIEW_TO,
} from './actionTypes';
import oust from 'oust';
import * as linkify from 'linkifyjs';

export const messageChange = (field, value) => ({
    type: MESSAGE_CHANGE,
    meta: {
        field,
    },
    payload: value,
});
export const messageDestroy = () => ({type: MESSAGES_DESTROY});
export const messageToggleLinkEnabled = (url) => ({ type: MESSAGE_LINK_TOGGLE_ENABLED, url: url });
export const messagePreviewShow = () => ({type: MESSAGE_PREVIEW_SHOW});
export const messagePreviewHide = () => ({type: MESSAGE_PREVIEW_HIDE});
export const messageSave = (action) => ({type: MESSAGE_SAVE, payload: {action}});
export const messageSaved = () => ({type: MESSAGE_SAVED});
export const messageSendPreviewTo = (to) => ({
    type: MESSAGE_SEND_PREVIEW_TO,
    payload: {
        to
    }
});
export const messageShowInBrowser = () => ({type: MESSAGE_SHOW_IN_BROWSER});
export const messageSetLinks = (links) => ({
    type: MESSAGE_SET_LINKS,
    payload: {
        links
    }
});

export const messageDuplicate = (id, name) => ({
    type: MESSAGE_DUPLICATE,
    payload: {
        id,
        name,
    }
});

/**
 * @param action [null, close, preview]
 * @returns {{payload: {values: *, action: *}, type: *}}
 */
export const messageTopolSaving = (action) => ({
    type: MESSAGE_TOPOL_SAVING,
    payload: {
        action,
    }
});
export const messageTopolSaved = (payload) => ({type: MESSAGE_TOPOL_SAVED, payload: payload});

export const extractLinksFromMessage = (content, previousLinks) => {
    let links = [];

    // html
    if (content.html && content.html !== '') {
        oust(content.html, 'links').forEach((link) => {
            if (isSystemLink(link)) {
                return;
            }
            if (links.find(l => l.url === link)) {
                return;
            }
            let previous = previousLinks.find(l => l.url === link);

            if (!previous) {
                links.push(createLink(link));
            } else {
                links.push(previous);
            }
        });
    }
    // text
    if (!content.text || content.text === '') {
        return links;
    }
    linkify.find(content.text).forEach((link) => {
        if (isSystemLink(link.href)) {
            return;
        }
        links = links.map(l => {
            if (l.url === link.href) {
                l.inContent = ['HTML', 'Text'];
                return l;
            } else {
                return l;
            }
        });
        if (!links.find(l => l.url === link.href)) {
            let previous = previousLinks.find(l => l.url === link.href);
            if (!previous) {
                links.push(createLink(link.href, 'Text'));
            } else {
                links.push(previous);
            }
        }
    });
    return links;
};

const createLink = (link, inContent, enabled) => {
    return {
        url: link,
        in: (inContent) ? (Array.isArray(inContent) ? inContent : [inContent]) : ['HTML'],
        enabled: (enabled) ? enabled : true
    };
};

const isSystemLink = (link) => (link.startsWith('*|') && link.endsWith('|*'));