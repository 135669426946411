import React from 'react';
import {connect} from 'react-redux';
import {Button, DialogActions} from '@material-ui/core';
import {signupHideRequested, signupSave} from '../../actions/signupActions';
import {signupSelectUiIsLoading, signupSelectUiIsSaving} from '../../selectors/signupSelector';
import {useTranslation} from 'react-i18next';

const SignupDialogActions = ({loading, onCancel, onSave, saving}) => {
    const {t} = useTranslation();

    return (
        <DialogActions>
            <Button disabled={loading || saving} variant={'contained'} onClick={() => onSave()}>
                {t('Save')}
            </Button>
            <Button disabled={loading || saving} onClick={() => onCancel()}>{t('Cancel')}</Button>
        </DialogActions>
    );
}

const mapStateToProps = (state) => ({
    loading: signupSelectUiIsLoading(state),
    saving: signupSelectUiIsSaving(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSave: () => dispatch(signupSave(false)),
    onCancel: () => dispatch(signupHideRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupDialogActions);