import {CREATE, GET_LIST, showNotification, UPDATE} from 'react-admin';
import {REFRESH_VIEW, refreshView} from 'react-admin/'
import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {LOCATION_CHANGE} from 'react-router-redux';
import {
    ARCHIVE_RECORD,
    RESTORE_RECORD,
    CAMPAIGN_DISPATCHED,
    CAMPAIGN_REPORT_LIST_CHANGE_PAGE,
    CAMPAIGN_REPORT_LIST_SET_ROWS_PER_PAGE,
    CAMPAIGN_WIZARD_INITIALIZE,
    CAMPAIGN_CHANGE,
    CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECT,
    CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECTED,
    CAMPAIGN_WIZARD_SAVE,
    TAG_SAVE_SUCCESS,
} from "../actions/actionTypes";
import {CHANGE, INITIALIZE} from 'redux-form/es/actionTypes';
import {
    campaignUniqueContactsCount,
    campaignUniqueContactsCountFetch,
    campaignSetSendMethod,
    campaignReportFetched,
    campaignReportFetching,
    campaignReportListFetched,
    campaignReportListFetching,
    campaignReportListSetEvent,
    campaignReportListSetCampaignId,
    campaignWizardFetchSenders,
    campaignWizardSetSenders,
    campaignWizardFetchMessages,
    campaignWizardSetMessages,
    campaignWizardFetchGroups,
    campaignWizardSetGroups,
    campaignWizardSaveSuccess,
    campaignWizardSaveError,
    campaignWizardFetchTags,
    campaignWizardSetTags,
    campaignWizardSetAttributes,
    campaignWizardSetCampaigns,
    campaignWizardCampaignLinksFetch,
    campaignWizardCampaignLinksFetched,
    campaignWizardFilterCampaignSelected,
} from '../actions/campaignActions';
import * as CampaignSelector from "../selectors/campaignSelector";
import API from '../api.js';
import {getLocation} from '../selectors/routerSelector';
import ldjsonServer from "../components/ldjsonServer";
import {entrypoint} from '../config/config';
import {httpClient} from '../components/httpClient';
import {
    campaignSelectCampaign,
    selectCampaignWizardLinksFirstInQueue
} from "../selectors/campaignSelector";
import i18n from 'i18next';

const dataProvider = ldjsonServer(entrypoint, httpClient);

function* dispatchCampaign() {
    try {
        const campaign = yield select(campaignSelectCampaign);
        yield call(API.campaignDispatch, campaign.id);
        yield put(push('/campaigns'));
        yield put(showNotification(i18n.t('Campaign is sending')));
    } catch (HttpError) {
        yield put(showNotification(i18n.t('Error: Campaign could not be sent'), 'warning'));
    }
}

function* countUniqueContactsOnChange({meta: {field}}) {
    if (field !== 'groups' && field !== 'excludedGroups') {
        return;
    }

    yield countUniqueContact();
}

function* countUniqueContact() {
    const campaign = yield select(campaignSelectCampaign);
    yield put(campaignUniqueContactsCountFetch());
    const data = {
        'groups': campaign.groups,
        'excludedGroups': campaign.excludedGroups,
    };
    const response = yield call(API.campaignCountUniqueContacts, data);
    yield put(campaignUniqueContactsCount(response.json.contacts));
}

function* initCampaignState(action) {
    if (action.payload['@type'] !== 'Campaign') {
        return;
    }
    if (!action.payload.uniqueRecipients) {
        return;
    }
    yield put(campaignUniqueContactsCount(action.payload.uniqueRecipients));
    yield put(campaignSetSendMethod((action.payload.isScheduled) ? 'scheduled' : 'direct'));
}

function* updateSendMethod(action) {
    if (action.meta.field !== 'isScheduled') {
        return;
    }
    const state = (action.payload ? 'scheduled' : 'direct');
    yield put(campaignSetSendMethod(state));
}

function* fetchCampaignReport() {
    // return;
    const location = yield select(getLocation);
    const path = location.pathname;
    if (!path.startsWith('/campaigns/') || !path.endsWith('/report')) {
        return;
    }
    const id = path.split('/')[2];
    yield put(campaignReportFetching());

    let response = yield call(API.getGroups);
    if (response.status !== 200) {
        return;
    }
    const groups = response.json['hydra:member'];

    response = yield call(API.campaignFetch, id);
    if (response.status !== 200) {
        return;
    }
    const campaign = response.json;

    let responseGroups;
    if (campaign.recipients.groups.hasOwnProperty('hydra:member')) {
        console.log('@deprecated');
        responseGroups = campaign.recipients.groups['hydra:member'];
    } else {
        responseGroups = campaign.recipients.groups;
    }

    campaign.recipients.groups = responseGroups.map((id) => {
        const g = groups.find((group) => group.id === id.split('/').pop());
        if (g) {
            return g.name;
        }
        return 'n/a';
    });

    yield put(campaignReportFetched(campaign));
}

function* watchForCampaignReportList(action) {
    const regex = new RegExp('/campaigns/([a-z0-9-]*)/report/list/([a-z]*)$');
    const path = action.payload.pathname;
    if (!regex.test(path)) {
        return;
    }
    const [, id, event] = regex.exec(path);
    yield put(campaignReportListSetCampaignId(id));
    yield put(campaignReportListSetEvent(event));
    yield fetchCampaignReportList(action);
}

function* watchForCampaignReportListClicks(action) {
    const regex = new RegExp('/campaigns/([a-z0-9-]*)/report/list/clicked/([a-z0-9-]*)$');
    const path = action.payload.pathname;
    if (!regex.test(path)) {
        return;
    }
    const [, id, link] = regex.exec(path);

    yield put(campaignReportListSetCampaignId(id));
    yield put(campaignReportListSetEvent('clicked'));
    yield put(campaignReportListFetching());
    const page = yield select(CampaignSelector.campaignReportListPage);
    const perPage = yield select(CampaignSelector.campaignReportListPerPage);
    const response = yield call(API.campaignFetchListClicks, link, page, perPage);

    if (response.status !== 200) {
        return;
    }
    const list = response.json;
    // @todo fix at the source!
    if (!list['hydra:members'] || (list['hydra:members'].length === 0 && list['hydra:member'].length > 0)) {
        list['hydra:members'] = list['hydra:member'];
    }
    yield put(campaignReportListFetched(list));
}

export function* fetchCampaignReportList() {
    yield put(campaignReportListFetching());
    const id = yield select(CampaignSelector.campaignReportListId);
    const event = yield select(CampaignSelector.campaignReportListEvent);
    const page = yield select(CampaignSelector.campaignReportListPage);
    const perPage = yield select(CampaignSelector.campaignReportListPerPage);
    const response = yield call(API.campaignFetchList, id, event, page, perPage);
    if (response.status !== 200) {
        return;
    }
    const list = response.json;
    if (!list['hydra:members']) {
        list['hydra:members'] = [];
    }
    yield put(campaignReportListFetched(list));
}

export function* campaignAddToArchive(action) {
    if ('campaigns' !== action.payload.resource) {
        return;
    }
    yield call(API.campaignAddToArchive, action.payload.id);
    yield put(refreshView());
}

export function* campaignRestoreFromArchive(action) {
    if ('campaigns' !== action.payload.resource) {
        return;
    }
    yield call(API.campaignRestoreFromArchive, action.payload.id);
    yield put(refreshView());
}

export function* campaignWizardInitialize() {
    yield fetchSenders();
    yield fetchMessages();
    yield fetchGroups();
    yield countUniqueContact();
    yield fetchTags();
    yield fetchAttributes();
    yield fetchCampaigns();
}

export function* fetchAttributes() {
    try {
        const response = yield call(dataProvider, GET_LIST, 'attributes', {
            filter: {
                'state.value': 'sent',
            },
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        });

        yield put(campaignWizardSetAttributes(response.data));
    } catch (exception) {
        yield put(showNotification(i18n.t('Attributes exception: %{message}', {message: exception.message})));
    }
}

export function* fetchCampaigns() {
    try {
        const response = yield call(dataProvider, GET_LIST, 'campaigns', {
            filter: {
                'state.value': 'done',
            },
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        });

        yield put(campaignWizardSetCampaigns(response.data));
    } catch (exception) {
        yield put(showNotification(i18n.t('Campaigns exception: %{message}', {message: exception.message})));
    }
}

function* dispatchSelected() {
    yield put(campaignWizardFilterCampaignSelected())
}

function* fetchCampaignLinks() {
    try {
        let campaign = yield select(selectCampaignWizardLinksFirstInQueue);

        if (campaign === null) {
            return;
        }

        yield put(campaignWizardCampaignLinksFetch(campaign));

        const response = yield call(API.campaignFetchLinks, campaign);
        if (response.status !== 200) {
            return;
        }

        yield put(campaignWizardCampaignLinksFetched(response.json.links));
        yield put(campaignWizardFilterCampaignSelected())
    } catch (exception) {
        yield put(showNotification(i18n.t('Campaigns exception: %{message}', {message: exception.message})));
    }
}


export function* fetchSenders() {
    try {
        yield put(campaignWizardFetchSenders());
        const response = yield call(dataProvider, GET_LIST, 'campaign/senders', {
            filter: {
                'state.value': 'verified',
            },
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        });

        yield put(campaignWizardSetSenders(response.data));
    } catch (exception) {
        yield put(showNotification(i18n.t('Senders exception: %{message}', {message: exception.message})));
    }
}

export function* fetchTags() {
    try {
        yield put(campaignWizardFetchTags());

        const response = yield call(dataProvider, GET_LIST, 'tags', {
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: 'label.value',
                order: 'ASC'
            }
        });

        yield put(campaignWizardSetTags(response.data));
    } catch (exception) {
        yield put(showNotification(i18n.t('Tags exception: %{message}', {message: exception.message})));
    }
}

export function* fetchMessages() {
    try {
        yield put(campaignWizardFetchMessages());
        const response = yield call(dataProvider, GET_LIST, 'messages', {
            filter: {
                'publishState.state': 'published',
                'inArchive': false,
                'privacy': {
                    'value': 'private',
                }
            },
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        });

        yield put(campaignWizardSetMessages(response.data));
    } catch (exception) {
        yield put(showNotification(i18n.t('Senders exception: %{message}', {message: exception.message})));
    }
}

export function* fetchGroups() {
    try {
        yield put(campaignWizardFetchGroups());

        const response = yield call(dataProvider, GET_LIST, 'groups', {
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        });

        yield put(campaignWizardSetGroups(response.data));
    } catch (exception) {
        yield put(showNotification(i18n.t('Senders exception: %{message}', {message: exception.message})));
    }
}

// validate the campaign wizard
function validate(campaign) {
    const errors = {};
    if (campaign.name === '' || campaign.name.length > 150) {
        errors['name'] = 'Invalid name value';
    }
    if (campaign.subjectLine === '' || campaign.subjectLine.length > 150) {
        errors['subjectLine'] = 'Invalid subject line value';
    }
    if (campaign.sender === null) {
        errors['sender'] = 'No sender selected';
    }
    if (campaign.message === null) {
        errors['message'] = 'No message selected';
    }
    if (campaign.recipients.groups.length === 0) {
        errors['groups'] = 'No recipients selected';
    }

    campaign.notifications.forEach((email) => {
        if (!email.match(/[a-zA-Z0-9]+@[a-zA-Z0-9]+/)) {
            errors['notifications'] = 'Not all notification email addresses are valid';
        }
    });

    return errors;
}

export function* campaignWizardSave(action) {
    const campaign = yield select(campaignSelectCampaign);
    const data = {
        recipients: {
            groups: campaign.groups,
            excludedGroups: campaign.excludedGroups,
            filters: campaign.enableFilters ? campaign.filters : [],
        }
    };

    ['id', 'message', 'name', 'notifications', 'scheduledOn', 'sender', 'subjectLine', 'tags'].forEach(
        prop => data[prop] = campaign[prop]
    );

    const {schedule, scheduledOn} = campaign;

    // check if un-scheduling
    if (schedule === false && scheduledOn) {
        data.scheduledOn = {
            timestamp: null,
        };
    }

    const {meta: {recipientsVerified}} = action;
    if (data.scheduledOn.timestamp === null || (schedule && !recipientsVerified)) {
        delete data.scheduledOn;
    }

    // validate
    const errors = validate(data);

    if (Object.keys(errors).length > 0) {
        yield put(campaignWizardSaveError(errors));

        let message = '';
        Object.keys(errors).map((key) => message = message + errors[key] + '. ');
        yield put(showNotification(message, 'warning'));
        return;
    }

    let response = {};
    try {
        if (data.id) {
            console.log(data);
            response = yield call(dataProvider, UPDATE, 'campaigns', {
                data: data,
                id: data.id,
            })
        } else {
            response = yield call(dataProvider, CREATE, 'campaigns', {data})
        }

        yield put(campaignWizardSaveSuccess());

        const {meta: {redirect}} = action;

        if (typeof redirect === 'string') {
            if (redirect === 'redirect-to-edit') {
                return yield put(push(`/campaigns/${response.data.id}/1`));
            } else if (redirect === 'send') {
                return yield put(push(`/campaigns/${response.data.id}/2`));
            } else {
                return yield put(push(redirect));
            }
        }

        if (typeof redirect === 'function') {
            return yield call(redirect, response.data.id);
        }

        return yield put(push(`/campaigns/${response.data.id}/1`))
    } catch (exception) {
        yield put(campaignWizardSaveError());
        yield put(showNotification(i18n.t('Something went wrong: %{message}', {message: exception.message}), 'warning'));
    }
}

function* refreshWizard() {
    const location = yield select(getLocation);
    const path = location.pathname;

    if (!path.startsWith('/campaigns/') || path.includes('/report')) {
        return;
    }

    yield campaignWizardInitialize();
}

export function* campaignSagas() {
    yield takeEvery(CAMPAIGN_DISPATCHED, dispatchCampaign);
    yield takeLatest(CAMPAIGN_CHANGE, countUniqueContactsOnChange);
    yield takeLatest(CHANGE, updateSendMethod);
    yield takeLatest(INITIALIZE, initCampaignState);
    yield takeLatest(LOCATION_CHANGE, fetchCampaignReport);
    yield takeLatest(LOCATION_CHANGE, watchForCampaignReportList);
    yield takeLatest(LOCATION_CHANGE, watchForCampaignReportListClicks);
    yield takeLatest(CAMPAIGN_REPORT_LIST_CHANGE_PAGE, fetchCampaignReportList);
    yield takeLatest(CAMPAIGN_REPORT_LIST_SET_ROWS_PER_PAGE, fetchCampaignReportList);
    yield takeEvery(ARCHIVE_RECORD, campaignAddToArchive);
    yield takeEvery(RESTORE_RECORD, campaignRestoreFromArchive);
    yield takeLatest(REFRESH_VIEW, fetchCampaignReport);
    yield takeLatest(REFRESH_VIEW, refreshWizard);
    yield takeLatest(CAMPAIGN_WIZARD_INITIALIZE, campaignWizardInitialize);
    yield takeLatest(CAMPAIGN_WIZARD_SAVE, campaignWizardSave);
    yield takeLatest(TAG_SAVE_SUCCESS, fetchTags);
    yield takeLatest(CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECT, dispatchSelected);
    yield takeLatest(CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECTED, fetchCampaignLinks);
}
