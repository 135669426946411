import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {AppBar, IconButton, Toolbar, withStyles} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {signupHideRequested} from '../../actions/signupActions';
import {signupSelectGroup, signupSelectUiIsLoading, signupSelectUiIsSaving} from '../../selectors/signupSelector'
import {useTranslation} from 'react-i18next';

const styles = () => ({
    appBarRoot: {

    },
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
});

const SignupDialogTitle = ({classes, group: {name}, handleCloseClick, loading, saving}) => {
    const {t} = useTranslation();

    return (
        <AppBar style={{position:'relative'}}>
            <Toolbar>
                <div className={classes.toolbarRoot}>
                    <div className={classes.toolbarDeviceSwitchRoot}>
                        {!name && t('Signup form')}
                        {name && t('Signup form # %{name}', {name})}
                    </div>
                    <div>
                        <IconButton disabled={loading || saving} aria-label={t('Close')} onClick={() => handleCloseClick()}>
                            <Close/>
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
}

SignupDialogTitle.propTypes = {
    group: PropTypes.object.isRequired,
    handleCloseClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loading: signupSelectUiIsLoading(state),
    saving: signupSelectUiIsSaving(state),
    group: signupSelectGroup(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleCloseClick: () => dispatch(signupHideRequested()),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(SignupDialogTitle);