import React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel, Switch, withStyles} from '@material-ui/core/';
import CampaignContactCounter from './CampaignContactCounter';
import SanitizedDiv from '../common/Sanitized/SanitizedDiv';
import CampaignTextField from './CampaignTextField';
import CampaignSelect from './CampaignSelect';
import CampaignSelectChips from './CampaignSelectChips';
import MessagePreviewContainer from '../message/MessagePreviewContainer';
import CreateTagDialog from '../tags/CreateTagDialog';
import {useTranslation} from 'react-i18next';
import CampaignFilters from "./CampaignFilters";

const styles = () => ({
    messageRoot: {
        display: 'flex',
        width: '100%',
    },
    messageField: {
        width: '100%'
    },
    messageButton: {
        alignSelf: 'flex-end',
        paddingBottom: '22px',
    }
});

const CampaignCreateMetaData = ({campaign, change, classes, groupOptionRenderer, loadingUniqueContacts, uniqueContacts, wizard}) => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <CampaignTextField
                autoFocus={true}
                error={wizard.errors.hasOwnProperty('name')}
                field={'name'}
                label={t('Name')}
                required={true}
                validate={(value) => value !== '' && value.length < 150}
            />
            <CampaignTextField
                field={'subjectLine'}
                error={wizard.errors.hasOwnProperty('subjectLine')}
                label={t('Subject line')}
                required={true}
                validate={(value) => value !== '' && value.length < 150}
            />
            <CampaignSelect
                error={wizard.errors.hasOwnProperty('sender')}
                field={'sender'}
                label={t('Sender')}
                options={wizard.senders}
                optionText={'headerValue'}
            />
            <SanitizedDiv className={classes.messageRoot}>
                <SanitizedDiv className={classes.messageField}>
                    <CampaignSelect
                        error={wizard.errors.hasOwnProperty('message')}
                        field={'message'}
                        label={t('Message')}
                        options={wizard.messages}/>
                </SanitizedDiv>
                <SanitizedDiv className={classes.messageButton}>
                    <MessagePreviewContainer
                        button={true}
                        messageSaving={false}
                        saveBeforePreview={false}
                        id={campaign && campaign.message && campaign.message.split('/').pop()}
                    />
                </SanitizedDiv>
            </SanitizedDiv>
            <CampaignSelectChips
                error={wizard.errors.hasOwnProperty('groups')}
                field={'groups'}
                label={t('Recipients')}
                options={wizard.groups}
                optionRenderer={groupOptionRenderer}
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={campaign ? campaign.excludeGroups : false}
                        color={'primary'}
                        onChange={(e) => change('excludeGroups', e.target.checked)}
                    />
                }
                label={t('Exclude contacts')}/>
            {campaign && campaign.excludeGroups &&
            <CampaignSelectChips
                field={'excludedGroups'}
                label={t('Excluded Contacts')}
                options={wizard.groups}
                optionRenderer={groupOptionRenderer}
            />}

            <FormControlLabel
                control={
                    <Switch
                        checked={campaign ? campaign.enableFilters : false}
                        color={'primary'}
                        onChange={(e) => change('enableFilters', e.target.checked)}
                    />
                }
                label={t('Filter contacts')}/>

            {campaign && campaign.enableFilters &&
                <CampaignFilters/>
            }

            <CampaignContactCounter
                hasFilters={campaign ? campaign.enableFilters : false}
                unique-contacts={uniqueContacts}
                loading-unique-contacts={loadingUniqueContacts}
            />

            <SanitizedDiv className={classes.messageRoot}>
                <SanitizedDiv className={classes.messageField}>
                    <CampaignSelectChips
                        field={'tags'}
                        label={t('Tags')}
                        options={wizard.tags}
                        optionRenderer={(tag) => tag.label}
                    />
                </SanitizedDiv>
                <SanitizedDiv className={classes.messageButton}>
                    <CreateTagDialog loading={!wizard.tags.loaded}/>
                </SanitizedDiv>
            </SanitizedDiv>
        </React.Fragment>
    );
}

CampaignCreateMetaData.propTypes = {
    campaign: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    groupOptionRenderer: PropTypes.func.isRequired,
    loadingUniqueContacts: PropTypes.bool.isRequired,
    uniqueContacts: PropTypes.number.isRequired,
    wizard: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampaignCreateMetaData);
