import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Chip, FormControl, InputLabel, LinearProgress, MenuItem, Select, Typography, withStyles} from '@material-ui/core/';
import {campaignSelectCampaign} from '../../selectors/campaignSelector';
import {campaignChange} from '../../actions/campaignActions';
import CampaignInput from "./CampaignInput";
import {useTranslation} from 'react-i18next';

const styles = theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
});

const renderText = (options, option, optionText, optionRenderer, t) => {
    if (!option) {
        return t('No longer available');
    }

    if (!optionRenderer) {
        return option[optionText];
    }

    return optionRenderer(option);
};

const CampaignSelectChips = ({campaign, change, classes, error = false, field, label, options, optionText = 'name', optionRenderer = null}) => {
    const {t} = useTranslation();

    if (options.loaded === false) {
        return (
            <CampaignInput>
                <FormControl>
                    <Typography variant={'caption'}>{label}</Typography>
                    <LinearProgress style={{marginTop: '8px', width: '250px'}}/>
                </FormControl>
            </CampaignInput>
        );
    }

    return (
        <CampaignInput>
            <FormControl error={error} fullWidth>
                <InputLabel htmlFor={field}>{label}</InputLabel>
                <Select
                    fullWidth
                    multiple
                    value={campaign && campaign[field]}
                    onChange={(e) => change(field, e.target.value)}
                    inputProps={{
                        name: field,
                        id: field,
                    }}
                    renderValue={selected => (
                        <div className={classes.chips}>
                            {selected.map(value => (
                                <Chip key={value}
                                      label={renderText(options, options.data.find((o) => o['@id'] === value), optionText, optionRenderer, t)}
                                      className={classes.chip}/>
                            ))}
                        </div>
                    )}
                >
                    {options.data.map((o) => (
                        <MenuItem key={o['@id']}
                                  value={o['@id']}>{renderText(options, o, optionText, optionRenderer)}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </CampaignInput>
    );
};

CampaignSelectChips.propTypes = {
    campaign: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    campaign: campaignSelectCampaign(state)
});

const mapDispatchToProps = dispatch => ({
    change: (field, value) => dispatch(campaignChange(field, value)),
});

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(CampaignSelectChips));
