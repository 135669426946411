export class GroupHelper
{
    /**
     * @param id
     */
    static setContactListGroupId = (id) => {
        const {app = {}} = window;

        window.app = {...app, contactList:{ group: id }};
    };

    /**
     * @returns string
     */
    static getContactListGroupId = () => {
        const {app: {contactList: {group = ''} = {}} = {}} = window;

        return group;
    }
}