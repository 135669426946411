import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from 'react-admin';
import {Cancel, ContentCopy, Save} from '@material-ui/icons';
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import {messageDuplicate} from '../../actions/messageActions';
import {useTranslation} from 'react-i18next';

const MessageDuplicateButton = ({record: {name: sourceName, id}, save}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState('');
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Button label={t('Duplicate')} onClick={() => setDialogOpen(true)}>
                <ContentCopy/>
            </Button>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>{t('Duplicate message: %{name}', {name: sourceName})}</DialogTitle>
                <DialogContent>
                    <TextField autoFocus={true}
                               fullWidth={true}
                               id={'name'}
                               label={t('Name')}
                               onChange={(event) => setName(event.target.value)}
                               required={true}
                               value={name} />
                </DialogContent>
                <DialogActions>
                    <Button label={t('Create')} variant={'contained'} onClick={() => save(id, name)}>
                        <Save/>
                    </Button>
                    <Button label={t('Cancel')} onClick={() => setDialogOpen(false)}>
                        <Cancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

MessageDuplicateButton.propTypes = {
    record: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    save: (id, name) => dispatch(messageDuplicate(id, name)),
});

export default connect(null, mapDispatchToProps)(MessageDuplicateButton);