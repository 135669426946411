import React from "react";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from 'react-admin';
import {messageSave} from '../../actions/messageActions';
import {selectIsMessageSaving} from '../../selectors/messageSelectors';
import SaveIcon from '@material-ui/icons/Save';

const HtmlMessageSaveButton = ({action, label, save, saving, style = {}}) => (
    <Button
        label={label}
        onClick={() => save(action)}
        disabled={saving}
        style={style}
        variant={'raised'}
    >
        <SaveIcon/>
    </Button>
);

HtmlMessageSaveButton.propTypes = {
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    save: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    style: PropTypes.object,
};

const mapStateToProps = (state) => ({
    saving: selectIsMessageSaving(state),
});

const mapDispatchToProps = (dispatch) => ({
    save: (redirect) => dispatch(messageSave(redirect)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HtmlMessageSaveButton);
