import React from 'react';
import {connect} from 'react-redux';
import {Button, Dialog, DialogTitle, DialogActions, DialogContent} from '@material-ui/core';
import {signupHide, signupSave} from '../../actions/signupActions';
import {useTranslation} from 'react-i18next';

const SignupConfirmCancelDialog = ({onCancel, onSave}) => {
    const {t} = useTranslation();

    return (
        <Dialog open={true}>
            <DialogTitle>{t('Unsaved changes!')}</DialogTitle>
            <DialogContent>
                {t('You have unsaved changes, do you want to save before closing?')}
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={() => onSave()}>{t('Save changes')}</Button>
                <Button onClick={() => onCancel()}>{t('Close anyway')}</Button>
            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onSave: () => dispatch(signupSave(true)),
    onCancel: () => dispatch(signupHide()),
});

export default connect(null, mapDispatchToProps)(SignupConfirmCancelDialog);


