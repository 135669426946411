import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CampaignReportMetrics from './CampaignReportMetrics';
import CampaignReportLinks from './CampaignReportLinks';
import {useTranslation} from 'react-i18next';

const CampaignReport = ({campaign, reportLoading}) => {
    const [tab, setTab] = React.useState('metrics');
    const {t} = useTranslation();

    const tabChanged = (event, value) => setTab(value);
    return (
        <React.Fragment>
            {!reportLoading && <Card>
                <CardHeader title={t('Report: %{name}', {name: campaign.name})}/>
                <Tabs value={tab} onChange={tabChanged} style={{marginLeft: '24px'}}>
                    <Tab style={{padding: '0px'}} label={t('Metrics')} value={'metrics'}/>
                    <Tab label={t('Links')} value={'links'}/>
                </Tabs>
                <Paper style={{padding: '24px'}}>
                    {tab === 'metrics' && <CampaignReportMetrics campaign={campaign}/>}
                    {tab === 'links' && <CampaignReportLinks id={campaign.id} links={campaign.report.links}/>}
                </Paper>
            </Card>}

            {reportLoading && <Card>
                <CardHeader title={t('Report')} subheader={t('Loading')}/>
                <LinearProgress/>
            </Card>}
        </React.Fragment>
    )
};

CampaignReport.propTypes = {
    campaign: PropTypes.object,
    reportLoading: PropTypes.bool.isRequired
};

export default CampaignReport;