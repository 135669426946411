import React from 'react';
import {
    CardActions as BaseCardActions,
    CreateButton,
    Datagrid,
    EditButton,
    List,
    ShowButton,
    TextField,
} from 'react-admin';
import ContactsButton from './ContactsButton';
import {BlacklistButtonContainer} from '../index';
import DateField from '../common/Field/DateField';
import {useTranslation} from 'react-i18next';

const GroupCardActions = ({
                              bulkActions,
                              basePath,
                              displayedFilters,
                              filters,
                              filterValues,
                              onUnselectItems,
                              resource,
                              selectedIds,
                              showFilter,
                          }) => (
    <BaseCardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <BlacklistButtonContainer/>
        <CreateButton basePath={basePath}/>
    </BaseCardActions>
);

export const GroupList = (props) => {
    const {t} = useTranslation();

    return (
        <List {...props} sort={{field: 'createdAt', order: 'DESC'}} actions={<GroupCardActions/>} title={t('Contacts')}>
            <Datagrid>
                <TextField source={'name'} label={t('Name')} sortBy={'name.value'}/>
                <TextField source={'numberOfContacts'} label={'#'}/>
                <DateField label={t('Created')} source={'createdAt'}/>
                {props.hasEdit && <EditButton/>}
                {props.hasShow && <ShowButton/>}
                <ContactsButton/>
            </Datagrid>
        </List>
    );
}

export default GroupList;
