import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Dialog, DialogTitle, DialogContent, TextField} from '@material-ui/core/';
import {tagDialogToggleOpen, tagLabelChange, tagSave} from '../../actions/tagActions';
import {getTagDialogOpen, getTagDialogSaving, getTagLabel} from '../../selectors/tagSelector';
import {withTranslation} from 'react-i18next';

const CreateTagDialog = ({label, loading, onChange, onClose, onOpen, onSave, open, saving, t}) => (
    <React.Fragment>
        <Button
            color={'primary'}
            disabled={saving || loading}
            label={'Add'}
            onClick={() => onOpen()}
        >
            {t('ADD')}
        </Button>
        <Dialog
            open={open}
            onClose={() => onClose()}
        >
            <DialogTitle>{t('Add Tag')}</DialogTitle>
            <DialogContent>
                <div style={{
                    marginBottom: '24px',
                    marginTop: '8px',
                }}>
                    <TextField
                        autoFocus={true}
                        fullWidth={true}
                        id={'label'}
                        label={t('Label')}
                        onChange={(e) => onChange(e.target.value)}
                        required={true}
                    />
                </div>
                <Button
                    color={'primary'}
                    disabled={saving}
                    onClick={() => onSave(label)}
                    variant={'contained'}
                >
                    {t('Create')}
                </Button>
            </DialogContent>
        </Dialog>
    </React.Fragment>
);

CreateTagDialog.propTypes = {
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    label: getTagLabel(state),
    open: getTagDialogOpen(state),
    saving: getTagDialogSaving(state),
});

const mapDispatchToProps = (dispatch) => ({
    onChange: (label) => dispatch(tagLabelChange(label)),
    onClose: () => dispatch(tagDialogToggleOpen(false)),
    onOpen: () => dispatch(tagDialogToggleOpen(true)),
    onSave: (label) => dispatch(tagSave(label)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateTagDialog));