// in src/customRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import * as Components from './components/index';

export default [
    <Route exact path='/set-credentials/:id' component={Components.CredentialsFormContainer} noLayout/>,
    <Route exact path="/home" component={Components.DashboardContainer} />,
    <Route exact path="/campaigns/:id/report" component={Components.CampaignReportContainer} />,
    <Route exact path="/campaigns/:id/report/list/:event" component={Components.CampaignReportListContainer} />,
    <Route exact path="/campaigns/:id/report/list/clicked/:link" component={Components.CampaignReportListContainer} />,
];
