import React from 'react';
import {connect} from 'react-redux';
import Topol from './Topol';

const TopolContainer = (props) => (
    <Topol {...props} />
);

const mapStateToProps = (state) => {
    return {
        accountId: state.account.info.id,
        html: state.message.data.html,
        messageId: state.message.meta.id,
        json: state.message.data.structured,
        attributes: state.account.data.attributes,
    }
};

export default connect(mapStateToProps)(TopolContainer);