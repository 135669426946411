import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    link: {
        color: '#b24d43',
        textDecoration: 'none',
    },
    paper: {
        backgroundColor: 'rgb(238, 240, 251)',
        paddingBottom: '24px',
        paddingTop: '24px',
    }
}));

const MetricBox = (props) => {
    const {id, label, type, value, ...rest} = props;
    const classes = useStyles();

    return (
        <Paper square elevation={2} className={classes.paper} {...rest}>
            <Typography align={'center'} variant={'title'} color={'primary'}>{value}</Typography>
            <Typography align={'center'} variant={'caption'}>{label}</Typography>
        </Paper>
    );
};

MetricBox.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['sent', 'clicked', 'opened', 'bounced']).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
};

export default MetricBox;