import React from 'react';
import {TextInput} from 'react-admin'
import {
    companyValidation,
    emailValidation,
    firstNameValidation,
    lastNameValidation,
} from './userValidation';

const UserFormBasic = () => (
    <>
        <TextInput autoFocus source={'company'} label={'Company'} validate={companyValidation} fullWidth/>
        <TextInput source={'email'} label={'Email'} validate={emailValidation} fullWidth/>
        <TextInput source={'firstName'} label={'First Name'} validate={firstNameValidation} fullWidth/>
        <TextInput source={'lastName'} label={'Last Name'} validate={lastNameValidation} fullWidth/>
    </>
);

export default UserFormBasic;