import {GET_LIST, CREATE} from 'react-admin';
import {getUrlParams} from '../../../util/string';

/**
 * Decorator for admin/users related requests
 *
 * @param requestHandler
 * @returns {function(...[*]=)}
 */
export const adminUserSubscriptionsDecorator = requestHandler => (type, resource, params) => {
    if (resource !== 'admin/user/subscriptions') {
        return requestHandler(type, resource, params);
    }

    if (type === GET_LIST) {
        params = addOwnerFilter(params);
    }

    if (type === CREATE) {
        params = parseProductIdToIri(params);
    }

    return requestHandler(type, resource, params);
};

/**
 * @param params
 * @returns {*}
 */
const addOwnerFilter = (params) => {
    const query = getUrlParams(window.location.href);

    if (query.hasOwnProperty('owner')) {
        params.filter['owner'] = '/admin/users/' + query.owner
    }

    return params;
}

/**
 * @param params
 * @returns {{data}|*}
 */
const parseProductIdToIri = (params) => {
    if (!params.hasOwnProperty('data') || !params.data.hasOwnProperty('product')) {
        return params;
    }

    params.data.product = `/admin/products/${params.data.product}`;

    return params;
}