import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import compose from 'recompose/compose';
import {LoginForm, userLogin} from 'react-admin';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import * as Component from '../index';
import {accountResetPassword} from "../../actions/accountActions";
import {useTranslation} from 'react-i18next';
import i18n from "i18next";

const styles = (theme) => {
    return ({
        avatar: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: theme.spacing.unit,
        },
        background: {
            backgroundImage: 'url(assets/mynuma_background_login.png)',
            backgroundColor: '#fafafa',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            width: '100vw',
        },
        card: {
            height: '350px',
            marginTop: '100px',
            width: '350px',
        },
        cardContent: {
            padding: '0',
            paddingTop: theme.spacing.unit,
        },
        container: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center'
        },
        link: {
            color: '#720400',
            textDecoration: 'none',
        },
        linkContainer: {
            marginBottom: 3 * theme.spacing.unit,
            paddingTop: 2 * theme.spacing.unit,
            textAlign: 'center',
            with: '100%',
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing.unit,
        },
    });
};

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
                         meta: {touched, error} = {touched: false, error: ''}, // eslint-disable-line react/prop-types
                         input: {...inputProps}, // eslint-disable-line react/prop-types
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginPage = ({classes, theme}) => {
    const [form, setForm] = useState('login');

    const handleFormClick = (event, form) => {
        event.preventDefault();
        setForm(form);
    };

    const {t} = useTranslation();

    return (
        <React.Fragment>
            <MuiThemeProvider theme={theme}>
                <div className={classes.background}>
                    <div className={classes.container}>
                        {form === 'login' &&
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Avatar className={classes.avatar} style={{
                                    backgroundColor: '#eee',
                                }}>
                                    <img alt={'M-logo'} src={'assets/mynuma_logo_m_50.png'} style={{margin: 10, width: '24px'}}/>
                                </Avatar>
                                <LoginForm/>
                                <div className={classes.linkContainer}>
                                    <Typography paragraph variant={'caption'}>
                                        <a className={classes.link} href={'#/'} onClick={(e) => handleFormClick(e, 'reset-password')}>
                                            {t('Reset password')}
                                        </a>
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>}

                        {form === 'reset-password' &&
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Avatar className={classes.avatar} style={{
                                    backgroundColor: '#eee',
                                }}>
                                    <img alt={'M-logo'} src={'assets/mynuma_logo_m_50.png'} style={{margin: 10, width: '24px'}}/>
                                </Avatar>
                                <ResetPasswordFormContainer/>
                                <div className={classes.linkContainer}>
                                    <Typography paragraph variant={'caption'}>
                                        <a className={classes.link} href={'#/'} onClick={(e) => handleFormClick(e, 'login')}>
                                            {t('Login')}
                                        </a>
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                        }
                    </div>
                </div>
            </MuiThemeProvider>
            <Component.Notifications/>
        </React.Fragment>
    );
};

const ResetPasswordForm = ({accountResetPassword, classes, isLoading = false}) => {
    const {t} = useTranslation();

    return (<form onSubmit={(e) => {e.preventDefault(); accountResetPassword()}}>
        <div className={classes.form}>
            <div className={classes.input}>
                <Field
                    autoFocus
                    id="email"
                    name="email"
                    component={renderInput}
                    label={t('Email')}
                    disabled={isLoading}
                />
            </div>
        </div>
        <CardActions>
            <Button
                variant="raised"
                type="submit"
                color="primary"
                disabled={isLoading}
                className={classes.button}
            >
                {isLoading && (
                    <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                    />
                )}
                {t('Reset password')}
            </Button>
        </CardActions>
    </form>);
};

const mapStateToProps = (state) => ({
    isLoading: state.admin.loading > 0,
});

const mapDispatchToProps = (dispatch) => ({
    accountResetPassword: () => dispatch(accountResetPassword()),
});

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'recoverPassword',
        validate: (values) => {
            const errors = {email: ''};

            if (!values.email) {
                errors.email = i18n.t('Required');
            }
            return errors;
        },
    })
);

const ResetPasswordFormContainer = enhance(ResetPasswordForm);


export default connect(undefined, {userLogin})(withStyles(styles)(LoginPage));
