import {CREATE, UPDATE} from 'react-admin';

/**
 * Decorator for webhooks related requests
 *
 * @param requestHandler
 * @returns {function(...[*]=)}
 */
export const webhookDecorator = requestHandler => (type, resource, params) => {
    if (resource !== 'webhooks') {
        return requestHandler(type, resource, params);
    }

    if (type === CREATE || type === UPDATE) {
        params = flattenParameters(params);
    }

    return requestHandler(type, resource, params);
};

const flattenParameters = (params) => {
    if (!params.data.hasOwnProperty('state')) {
        params.data.state = 'disabled';
    }

    return params;
}
