export const attributesSelector = state => state.account.data.attributes;
export const pathSelector = state => state.router.location.pathname;
export const accountIdSelector = state => state.account.info.id;
export const isAccountOverContactLimit = state => state.account.info.subscription.isOverContactLimit;
export const getResetPasswordEmail = (state) => {
    return state
    && state.form
    && state.form['recoverPassword']
    && state.form['recoverPassword'].values
    && state.form['recoverPassword'].values.email ?
        state.form['recoverPassword'].values.email : null;
};
