import produce from 'immer';
import {
    TAG_DIALOG_TOGGLE_OPEN,
    TAG_LABEL_CHANGE,
    TAG_SAVE,
    TAG_SAVE_ERROR,
    TAG_SAVE_SUCCESS
} from '../actions/actionTypes';

const initialState = {
    data: {
        label: '',
    },
    dialog: {
        open: false,
        saving: false,
    },
};

const TagReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case TAG_DIALOG_TOGGLE_OPEN:
                draft.dialog.open = action.payload.open;
                break;

            case TAG_LABEL_CHANGE:
                draft.data.label = action.payload.label;
                break;

            case TAG_SAVE:
                draft.dialog.saving = true;
                break;

            case TAG_SAVE_ERROR:
                draft.dialog.saving = false;
                break;

            case TAG_SAVE_SUCCESS:
                draft.dialog.open = false;
                draft.dialog.saving = false;
                draft.data.label = '';

            // no default

        }
    });
};

export default TagReducer;