import React from 'react';
import {connect} from 'react-redux';
import {getContactListListName} from '../../selectors/UiSelector';
import {useTranslation} from 'react-i18next';

const ContactListTitle = ({name}) => {
    const {t} = useTranslation();

    return (
        <span>{t('Contact List # %{name}', {name})}</span>
    );
}

const mapStateToProps = (state) => ({
    name: getContactListListName(state),
});

export default connect(mapStateToProps)(ContactListTitle);