import React from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-admin';
import ImportIcon from '@material-ui/icons/CloudUpload';
import BlacklistImportDialog from './BlacklistImportDialog';
import {uiBlacklistImportDialogHide, uiBlacklistImportDialogShow} from '../../actions/UiActions';
import {getBlacklistImportDialog} from '../../selectors/UiSelector';
import {useTranslation} from 'react-i18next';

const ImportButton = ({display, onCloseHandler, onClickHandler}) => {
    const {t} = useTranslation();

    return (
        <div>
            <Button label={t('Import')} onClick={onClickHandler}>
                <ImportIcon/>
            </Button>
            <BlacklistImportDialog showDialog={display} onClose={onCloseHandler}/>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onCloseHandler: () => dispatch(uiBlacklistImportDialogHide()),
    onClickHandler: () => dispatch(uiBlacklistImportDialogShow()),
});

const mapStateToProps = (state) => ({
    display: getBlacklistImportDialog(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportButton);