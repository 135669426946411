import {
    CONTACT_IMPORT_TRANSACTION_COMPLETED,
    CONTACT_IMPORT_TRANSACTION_CREATE,
    CONTACT_IMPORT_TRANSACTION_CREATED,
    CONTACT_IMPORT_TRANSACTION_IMPORTED,
    CONTACT_IMPORT_TRANSACTION_IMPORTING,
    CONTACT_IMPORT_TRANSACTION_RESET_STATE,
    CONTACT_IMPORT_TRANSACTION_SET_ATTRIBUTES,
    CONTACT_IMPORT_TRANSACTION_SET_DATA,
    CONTACT_IMPORT_TRANSACTION_SET_FILENAME,
    CONTACT_IMPORT_TRANSACTION_SET_MAP,
    CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_MESSAGE,
    CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_VALUE,
    CONTACT_IMPORT_TRANSACTION_SET_STEP,
    CONTACT_IMPORT_TRANSACTION_TOGGLE_UI
} from "./actionTypes";
import XLSX from "xlsx";
import {showNotification} from 'react-admin';

export function contactImportTransactionToggleDialog(visible)
{
    return { type: CONTACT_IMPORT_TRANSACTION_TOGGLE_UI, visible: visible }
}

export function contactImportTransactionCreate()
{
    return { type: CONTACT_IMPORT_TRANSACTION_CREATE }
}

export function contactImportTransactionCreated(id)
{
    return { type: CONTACT_IMPORT_TRANSACTION_CREATED, id: id }
}

export function contactImportTransactionSetAttributes(attributes)
{
    return { type: CONTACT_IMPORT_TRANSACTION_SET_ATTRIBUTES, attributes: attributes }
}

export function contactImportTransactionSetData(data)
{
    return { type: CONTACT_IMPORT_TRANSACTION_SET_DATA, data: data }
}

export function contactImportTransactionSetFilename(filename)
{
    return { type: CONTACT_IMPORT_TRANSACTION_SET_FILENAME, filename: filename }
}

export function contactImportTransactionSetMap(map)
{
    return { type: CONTACT_IMPORT_TRANSACTION_SET_MAP, map: map }
}

export function contactImportTransactionSetProgressMessage(message)
{
    return { type: CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_MESSAGE, message: message }
}

export function contactImportTransactionSetProgressValue(value)
{
    return { type: CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_VALUE, value: value }
}

export function contactImportTransactionSetStep(step)
{
    return { type: CONTACT_IMPORT_TRANSACTION_SET_STEP, step: step }
}

export function contactImportTransactionCompleted(data)
{
    return { type: CONTACT_IMPORT_TRANSACTION_COMPLETED, data: data}
}

export function contactImportTransactionResetState()
{
    return { type: CONTACT_IMPORT_TRANSACTION_RESET_STATE }
}

export function contactImportTransactionImported()
{
    return { type: CONTACT_IMPORT_TRANSACTION_IMPORTED }
}

export function contactImportTransactionImporting()
{
    return { type: CONTACT_IMPORT_TRANSACTION_IMPORTING }
}

export function handleUploadedFile (dispatch, values)
{
    if (!values.file) {
        return false;
    }

    dispatch(contactImportTransactionSetFilename(values.file.title));
    readFileAsBinary(values.file)
        .then(reader => {
            const workbook = XLSX.read(new Uint8Array(reader.result), {type: 'array'});

            let sheet = null;
            Object.keys(workbook.Sheets).forEach(key => {
                if (null === sheet) {
                    sheet = key;
                }
            });

            const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {header: 1});

            if (0 === data.length) {
                // @todo show notification
                return false;
            }

            dispatch(contactImportTransactionSetData(data));
            dispatch(contactImportTransactionSetStep(2));
        })
        .catch(function (error) {
            console.log(error.message);
        });
}

export function submitMapHandler(dispatch, values) {
    const map = [];

    Object.entries(values).forEach((item) => {
        if (item[0] === 'duplicateAction') {
            return;
        }

        map.push({
            column: item[0].substr(7),
            field: item[1]
        });
    });

    dispatch(contactImportTransactionSetMap(map));
    dispatch(contactImportTransactionSetStep(3));

    processTransaction(dispatch);
}

export function processTransaction(dispatch) {
    dispatch(contactImportTransactionCreate());
}

export function processPusherMessage(dispatch, message) {
    console.log(message);
    dispatch(showNotification(message.message));
    if ('completed' === message.status) {
        dispatch(contactImportTransactionSetProgressValue(100));
        dispatch(contactImportTransactionSetProgressMessage('Done'));
    } else {
        dispatch(contactImportTransactionSetProgressMessage('Something went wrong'));
    }
}

// @todo move to utils
function readFileAsBinary (file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file.rawFile);

        reader.onload = () => resolve(reader);
        reader.onerror = reject;
    });
}