import React from 'react';
import {
    Datagrid,
    List,
    TextField,
} from 'react-admin';
import CampaignFilter from './CampaignFilter';
import CampaignIcons from './CampaignIcons';
import CardActions from '../common/CardActions';
import CampaignEditButton from './CampaignEditButton';
import CampaignListBulkActionButtons from './CampaignListBulkActionButtons';
import DateField from '../common/Field/DateField';
import {useTranslation} from 'react-i18next';

export const CampaignList = (props) => {
    const {t} = useTranslation();

    return (
        <List {...props} filters={<CampaignFilter/>} sort={{field: 'createdAt', order: 'DESC'}} actions={<CardActions/>}
              bulkActionButtons={<CampaignListBulkActionButtons/>} title={t('Campaigns')}>
            <Datagrid>
                <CampaignIcons source={'state'} label={''}/>
                <TextField source={'name'} label={t('Name')} sortBy={'name.value'}/>
                <DateField source={'createdAt'} label={t('Created')} sortBy={'createdAt'}/>
                {props.hasEdit && <CampaignEditButton/>}
            </Datagrid>
        </List>
    );
}
