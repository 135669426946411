import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin'
import {validateName} from './validateGroup';
import {useTranslation} from 'react-i18next';

export const GroupCreate = props => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create Group')}>
            <SimpleForm redirect={'/groups'}>
                <TextInput autoFocus source={'name'} label={t('Contact list name')} placeholder={t('i.e. Customers')} validate={validateName} fullWidth/>
            </SimpleForm>
        </Create>
    );
}

export default GroupCreate;