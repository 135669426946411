import React from 'react';
import { Button } from 'react-admin';
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import LaunchIcon from '@material-ui/icons/Send';
import {useTranslation} from 'react-i18next';

const CampaignScheduleDialog = ({
                                    scheduleDisabled,
                                    validateRecipients,
                                    open,
                                    onClose,
                                    onSchedule,
                                    isScheduling,
                                    title,
                                    recipients
                                }) => {
    const {t} = useTranslation();

    return <Dialog open={open}>
        <DialogTitle>{t('Confirmation required')}</DialogTitle>
        <DialogContent>
            <DialogContentText>{t('Your are about to schedule campaign "%{title}" to "%{recipients}" recipients.', {recipients, title})}</DialogContentText>
            <DialogContentText>{t('To make sure this is what you intend, you are required to type the number of recipients in the textbox below.')}</DialogContentText>
            <TextField autoFocus disabled={isScheduling} label={'Number of recipients'} placeholder={`${recipients}`} onChange={(e) => validateRecipients(e.target.value)} />
        </DialogContent>
        <DialogActions>
            <Button label={t('Schedule')} disabled={scheduleDisabled || isScheduling} onClick={onSchedule}>
                <LaunchIcon />
            </Button>
            <Button label={t('close')} onClick={onClose} />
        </DialogActions>
        {isScheduling && <LinearProgress />}
    </Dialog>;
};

CampaignScheduleDialog.propTypes = {
    onClose: PropTypes.func,
    scheduleDisabled: PropTypes.bool,
    open: PropTypes.bool,
    onSchedule: PropTypes.func,
    isScheduling: PropTypes.bool,
    recipients: PropTypes.number,
    title: PropTypes.string,
    validateRecipients: PropTypes.func,
};

export default CampaignScheduleDialog;
