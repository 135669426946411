import React from 'react';
import { connect } from 'react-redux';
import {MenuItemLink} from 'react-admin';
import { Divider } from '@material-ui/core/';
import HomeIcon from '@material-ui/icons/Home';
import {DeviceHub, Label} from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import SendersIcon from '@material-ui/icons/VerifiedUser';
import CampaignsIcon from '@material-ui/icons/Games';
import MessagesIcon from '@material-ui/icons/ArtTrack';
import AttributesIcon from '@material-ui/icons/Dvr';
import MailIcon from '@material-ui/icons/Mail';
import InboxIcon from '@material-ui/icons/Inbox';
import ContactsIcon from '@material-ui/icons/People';
import {Group} from '@material-ui/icons';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import Pusher from 'react-pusher';
import { pusherMessageReceived } from '../../../actions/pusherActions';
import AuthDialog from '../../auth/AuthDialog';
import {withTranslation} from 'react-i18next';

class Menu extends React.Component {
    state = {
        collapseSettings: true
    };

    constructor(props) {
        super(props);

        if (props.pathname.startsWith('/attributes') || props.pathname.startsWith('/campaign/senders')) {
            this.state.collapseSettings = false;
        }

        this.toggleSettingsSubMenu = this.toggleSettingsSubMenu.bind(this);
        this.closeSettingsSubMenu = this.closeSettingsSubMenu.bind(this);
        this.isActive = this.isActive.bind(this);
    }

    toggleSettingsSubMenu = (e) => {
        e.preventDefault();
        this.setState({collapseSettings: !this.state.collapseSettings});
    };

    closeSettingsSubMenu = () => {
        this.setState({collapseSettings: true});
    };

    isActive = (path, match, location) => {
        return path === location.pathname;
    };

    hasModule = (module) => {
        const {modules} = this.props;

        return modules.includes(module);
    }

    render() {
        const {t} = this.props;

        return <div>
            <List>
                <MenuItemLink isActive={this.isActive.bind(this, '/home')} to={'/home'} primaryText={t('Home')} leftIcon={<HomeIcon />} onClick={this.closeSettingsSubMenu} />
                {this.hasModule('BULK') &&
                    <React.Fragment>
                        <MenuItemLink to='#' primaryText={t('Settings')} leftIcon={<SettingsIcon/>} onClick={this.toggleSettingsSubMenu} />
                        <Collapse in={!this.state.collapseSettings} timeout='auto' unmountOnExit>
                            <List>
                                <MenuItemLink to='/attributes' primaryText={t('Contact Attributes')} leftIcon={<AttributesIcon/>} />
                                <MenuItemLink to='/campaign/senders' primaryText={t('Senders')} leftIcon={<SendersIcon/>} />
                                <MenuItemLink to={'/tags'} primaryText={t('Tags')} leftIcon={<Label/>}/>
                                <MenuItemLink to={'/webhooks'} primaryText={t('Webhooks')} leftIcon={<DeviceHub/>}/>
                                <Divider />
                            </List>
                        </Collapse>
                        <MenuItemLink to='/groups' primaryText={t('Contacts')} leftIcon={<ContactsIcon />} onClick={this.closeSettingsSubMenu} />
                        <MenuItemLink to='/messages' primaryText={t('Messages')} leftIcon={<MessagesIcon />} onClick={this.closeSettingsSubMenu} />
                        <MenuItemLink to='/campaigns' primaryText={t('Campaigns')} leftIcon={<CampaignsIcon />} onClick={this.closeSettingsSubMenu} />
                    </React.Fragment>}
                {this.hasModule('SMTP') &&
                     <React.Fragment>
                         <MenuItemLink to='/campaign/senders' primaryText={t('Senders')} leftIcon={<SendersIcon/>} />
                         <MenuItemLink to='/webhooks' primaryText={t('Webhooks')} leftIcon={<DeviceHub/>}/>
                         <MenuItemLink to='/smtp/messages'
                                       primaryText={t('Messages')}
                                       leftIcon={<MailIcon/>}
                                       onClick={this.closeSettingsSubMenu}/>
                     </React.Fragment>
                }
                {this.hasModule('INBOUND') &&
                    <React.Fragment>
                        <MenuItemLink to='/receivers' primaryText={t('Receivers')} leftIcon={<InboxIcon/>} />
                    </React.Fragment>
                }
                {this.hasModule('ADMIN') &&
                    <React.Fragment>
                        <MenuItemLink to={'/admin/users'} primaryText={t('Users')} leftIcon={<Group/>}/>
                    </React.Fragment>
                }
            </List>
            {this.props.accountId && <Pusher channel={`user-${this.props.accountId}`} event={'global'} onUpdate={this.props.update} />}
            <AuthDialog/>
        </div>;
    };
}

const mapStateToProps = (state) => {
    return {
        resources: state.admin.resources,
        pathname: state.router.location.pathname,
        accountId: state.account.info.id,
        modules: state.account.info.modules,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        update: (message) => dispatch(pusherMessageReceived(message))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Menu));
