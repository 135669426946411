import React from 'react';
import './i18n';
import {Admin, Resource, resolveBrowserLocale} from 'react-admin';
import authProvider from './components/authProvider';
import Menu from './components/common/Menu/Menu';
import AttributeList from './components/attribute/AttributeList';
import AttributeCreate from './components/attribute/AttributeCreate';
import AttributeEdit from './components/attribute/AttributeEdit';
import BlacklistList from './components/blacklist/BlacklistList';
import BlacklistCreate from './components/blacklist/BlacklistCreate';
import {CampaignList} from './components/campaign/CampaignList';
import CampaignCreate from './components/campaign/CampaignCreate';
import CampaignEdit from './components/campaign/CampaignEdit';
import ContactCreate from './components/contact/ContactCreate';
import ContactEdit from './components/contact/ContactEdit';
import MessageCreate from './components/message/MessageCreate';
import MessageEditContainer from './components/message/MessageEditContainer';
import MessageList from './components/message/MessageList';
import SenderList from './components/sender/SenderList';
import SenderCreate from './components/sender/SenderCreate';
import contactImportReducer from './reducers/contactImportReducers';
import * as sagas from './sagas';
import importToArray from 'import-to-array';
import * as ReactPusher from 'react-pusher';
import Pusher from 'pusher-js';
import accountReducer from './reducers/accountReducers';
import dashboardReducer from './reducers/dashboardReducer';
import messageReducer from './reducers/messageReducers';
import campaignReducers from './reducers/campaignReducers';
import contactImportReportReducers from './reducers/contactImportReportReducers';
import * as Components from './components';
import customRoutes from './routes';
import signupReducer from './reducers/signupReducer';
import {dataProvider} from './components/http/dataProvider';
import UiReducer from './reducers/UiReducer';
import TagReducer from './reducers/tagReducer';
import contactListReducer from './reducers/contactListReducer';
import smtpReducer from './reducers/smtpReducer';
import adminReducer from "./reducers/adminReducers";
import * as AdminComponents from './components/admin/index';
import theme from "./config/theme";
import dutchMessages from "ra-language-dutch";
import englishMessages from "ra-language-english";

const messages = {
    nl: dutchMessages,
    en: englishMessages,
};

const i18nProvider = locale => {
    if (messages.hasOwnProperty(locale)) {
        return messages[locale];
    }

    return messages['en'];
}

const pusherClient = new Pusher('04018b1b0a6e0cb52893', {
    cluster: 'eu',
    forceTLS: true
});

ReactPusher.setPusherClient(pusherClient);

const App = () => (
    <React.Fragment>
        <Admin appLayout={Components.SenderLayout}
               theme={theme}
               authProvider={authProvider}
               customReducers={{
                   mynuma_admin: adminReducer,
                   contactImport: contactImportReducer,
                   contactList: contactListReducer,
                   dashboard: dashboardReducer,
                   account: accountReducer,
                   message: messageReducer,
                   campaign: campaignReducers,
                   importReport: contactImportReportReducers,
                   signup: signupReducer,
                   smtp: smtpReducer,
                   ui: UiReducer,
                   tag: TagReducer,
               }}
               customRoutes={customRoutes}
               customSagas={importToArray(sagas)}
               dataProvider={dataProvider}
               i18nProvider={i18nProvider}
               locale={resolveBrowserLocale()}
               loginPage={Components.LoginPage}
               menu={Menu}>

            <Resource name='home'/>
            <Resource name='attributes' list={AttributeList} edit={AttributeEdit} create={AttributeCreate}/>
            <Resource name='campaign/senders' list={SenderList} create={SenderCreate}/>
            <Resource name='contacts' list={Components.ContactList} edit={ContactEdit} create={ContactCreate}/>
            <Resource name='campaigns' list={CampaignList} edit={CampaignEdit} create={CampaignCreate}/>
            <Resource name='groups' list={Components.GroupList} create={Components.GroupCreate}
                      edit={Components.GroupEdit}/>
            <Resource name='messages' list={MessageList} create={MessageCreate} edit={MessageEditContainer}/>
            <Resource name='blacklist/contacts' list={BlacklistList} create={BlacklistCreate}/>
            <Resource name={'tags'} list={Components.TagList} edit={Components.TagEdit} create={Components.TagCreate}/>
            <Resource name={'smtp/messages'} list={Components.SmtpMessageList}/>
            <Resource name={'admin/users'} list={AdminComponents.UserList} create={AdminComponents.UserCreate} edit={AdminComponents.UserEdit}/>
            <Resource name={'admin/user/subscriptions'} list={AdminComponents.SubscriptionList} create={AdminComponents.SubscriptionCreate}/>
            <Resource name={'admin/products'}/>
            <Resource name={'webhooks'} list={Components.WebhookList} create={Components.WebhookCreate} edit={Components.WebhookEdit}/>
            <Resource name={'receivers'} list={Components.ReceiverList} create={Components.ReceiverCreate} edit={Components.ReceiverEdit}/>
            <Components.ContactImportReportContainer/>
        </Admin>
    </React.Fragment>
);

export default App;
