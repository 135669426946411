import React from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    TextField,
} from 'react-admin';
import DateField from '../common/Field/DateField';
import MessageFilter from "./MessageFilter";
import CardActions from "../common/CardActions";
import MessageDetailsContainer from './MessageDetailsContainer';
import {useTranslation} from 'react-i18next';

export const MessageList = (props) => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <List {...props} actions={<CardActions showCreateButton={false}/>} sort={{field: 'sentOn', order: 'DESC'}} filters={<MessageFilter/>} title={t('Messages')}>
                <Datagrid>
                    <DateField label={t('Sent')} source={'sentOn'} sortBy={'sentOn'}/>
                    <TextField label={t('Sender')} source={'from'} sortable={false}/>
                    <TextField label={t('To')} source={'to'} sortable={false}/>
                    <TextField label={t('Subject')} source={'subject'} sortable={false}/>
                    <TextField label={t('Sent State')} source={'state'}  sortable={false}/>
                    <BooleanField label={t('Is bounced')} source={'isBounced'} sortable={false}/>
                    <BooleanField label={t('Is read')} source={'isRead'} sortable={false}/>
                    <BooleanField label={t('Is visited')} source={'isVisited'} sortable={false}/>
                    <MessageDetailsContainer/>
                </Datagrid>
            </List>
        </React.Fragment>
    );
}

export default MessageList;
