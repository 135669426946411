import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormControlLabel, Switch} from '@material-ui/core/';
import {selectMessage} from '../../selectors/messageSelectors';
import {messageChange} from '../../actions/messageActions';

const MessageSwitch = (props) => {
    const {change, field, format, icon, label, parse, message} = props;

    if (!message.hasOwnProperty(field)) {
        return <span>Invalid message field</span>
    }

    const checked = parse ? parse(message[field]) : message[field];
    const formatter = (value) => format ? format(value) : value;

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    color={'primary'}
                    onChange={(e) => change(field, formatter(e.target.checked))}
                    checkedIcon={icon ? icon : null}
                />
            }
            label={label}/>
    );
};

MessageSwitch.propTypes = {
    change: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    format: PropTypes.func,
    icon: PropTypes.element,
    label: PropTypes.string.isRequired,
    message: PropTypes.object.isRequired,
    parse: PropTypes.func,
};

const mapStateToProps = state => ({
    message: selectMessage(state)
});

const mapDispatchToProps = dispatch => ({
    change: (field, value) => dispatch(messageChange(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageSwitch);