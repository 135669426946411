import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Chip, FormControl, Typography} from '@material-ui/core/';
import {campaignSelectCampaign} from '../../selectors/campaignSelector';
import CampaignInput from "./CampaignInput";

const CampaignReferenceArrayField = ({campaign, field, label, options, optionText = 'name'}) => {
    if (!campaign.hasOwnProperty(field)) {
        return (<span/>);
    }

    if (!options.hasOwnProperty('loaded') || !options.loaded) {
        return (<span/>);
    }

    return (
        <CampaignInput>
            <FormControl>
                <Typography variant={'caption'} component={'div'}>{label}</Typography>
                <div style={{
                    display: 'flex',
                }}>
                {campaign[field].map((o, key) => {
                    const option = options.data.find((op) => op['@id'] === o);

                    if (!option || !option.hasOwnProperty(optionText)) {
                        return (<span/>);
                    }

                    return (<Chip key={key} label={option[optionText]} style={{
                        margin: '8px',
                    }}/>);
                })}
                </div>
            </FormControl>
        </CampaignInput>
    );
};

CampaignReferenceArrayField.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.object,
    optionText: PropTypes.string,
};

const mapStateToProps = state => ({
    campaign: campaignSelectCampaign(state)
});

export default connect(mapStateToProps, null)(CampaignReferenceArrayField);