import React from 'react';
import {connect} from 'react-redux';
import CampaignReportList from "./CampaignReportList";
import {campaignReportListChangePage, campaignReportListSetRowsPerPage} from "../../actions/campaignActions";
import {useTranslation} from 'react-i18next';

const CampaignReportListContainer = (props) => {
    const {t} = useTranslation();
    const title = t('Report action list: %{action}', {
            action: t(props.action).toLowerCase(),
        });

    return (<CampaignReportList {...props} subtitle={t('Return to campaign report')} link={`/campaigns/${props.id}/report`} title={title}/>);
};

CampaignReportListContainer.propTypes = {};

const mapStateToProps = (state) => {
    return {
        count: state.campaign.reportList['hydra:totalItems'],
        data: state.campaign.reportList['hydra:members'],
        id: state.campaign.reportListMeta.id,
        page: state.campaign.reportListMeta.page,
        loading: state.campaign.reportListLoading,
        rowsPerPage: state.campaign.reportListMeta.rowsPerPage,
        action: state.campaign.reportListMeta.event,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangePage: (event, page) => dispatch(campaignReportListChangePage(page)),
        onChangeRowsPerPage: (event) => dispatch(campaignReportListSetRowsPerPage(event.target.value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignReportListContainer);
