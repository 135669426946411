import React, {cloneElement} from 'react';
import {connect} from 'react-redux';


const mapStateToProps = (state) => {
    return {
        modules: state.account.info.modules,
    }
};

const WithModules = connect(mapStateToProps)(({children, modules}) => {
    return React.Children.map(
        children,
        child => cloneElement(child, {
            hasModule: (module) => modules.includes(module),
            modules: modules,
        })
    );
});

export const withModules = (WrappedComponent) => {
    return class extends React.Component {
        render() {
            return (
                <WithModules>
                    <WrappedComponent {...this.props}/>
                </WithModules>
            )
        }
    }
}

