import React from 'react';
import {connect} from 'react-redux';
import {change} from 'redux-form';
import {
    Create,
    FormTab,
    TabbedForm,
} from 'react-admin';
import PropTypes from 'prop-types';
import CampaignCreateMetaData from './CampaignEditMetaData';
import {campaignWizardInitialize, campaignWizardSave} from '../../actions/campaignActions';
import {
    campaignSelectCampaign,
    campaignSelectWizard,
    campaignSelectWizardLoading
} from '../../selectors/campaignSelector';
import CampaignToolbarContainer from './CampaignToolbarContainer';
import {withTranslation} from 'react-i18next';

class CampaignCreate extends React.Component {
    componentDidMount() {
        const {wizard, wizardInitialize} = this.props;

        if (!wizard.initialized) {
            wizardInitialize();
        }
    }

    render() {
        const {campaign, change, dispatch, loadingUniqueContacts, save, uniqueContacts, wizard, wizardInitialize, wizardLoading, t, tReady, ...rest} = this.props;
        const groupOptionRenderer = choice => t('%{name} (%{numberOfContacts} contacts)', {
            name: choice.name,
            numberOfContacts: choice.numberOfContacts
        });

        return (
            <Create {...rest}>
                <TabbedForm toolbar={<CampaignToolbarContainer save={save} wizardSaving={wizard.saving || wizardLoading} tab={1}/>}>
                    <FormTab label={t('Metadata')}>
                        <CampaignCreateMetaData
                            campaign={campaign}
                            change={change}
                            groupOptionRenderer={groupOptionRenderer}
                            loadingUniqueContacts={!wizard.contactCounter.loaded || wizard.contactCounter.loading}
                            uniqueContacts={wizard.contactCounter.contacts}
                            wizard={wizard}/>
                    </FormTab>
                    <FormTab label={t('Overview')} disabled/>
                    <FormTab label={t('Send')} disabled/>
                </TabbedForm>
            </Create>
        );
    }
}

CampaignCreate.propTypes = {
    campaign: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    wizard: PropTypes.object.isRequired,
    wizardInitialize: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        campaign: campaignSelectCampaign(state),
        wizard: campaignSelectWizard(state),
        wizardLoading: !campaignSelectWizardLoading(state),
    }
};

const mapDispatchToProps = dispatch => {
    return {
        change: (field, value) => dispatch(change('record-form', field, value)),
        save: () => dispatch(campaignWizardSave('redirect-to-edit', false)),
        wizardInitialize: () => dispatch(campaignWizardInitialize()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CampaignCreate));
