import React from "react";
import { SimpleForm, TextInput } from 'react-admin'
import validateForm from './validation';
import {withTranslation} from 'react-i18next';

const TagForm = ({t, ...props}) => (
    <SimpleForm {...props} redirect={'/tags'} validate={validateForm}>
        <TextInput label={t('Label')} source={'label'} autoFocus fullWidth />
    </SimpleForm>
);

export default withTranslation()(TagForm);
