export default {
    translation: {
        'Welcome %{name}': 'Welkom %{name}',
        'Home': 'Start',
        'Settings': 'Instellingen',
        'Contact Attributes': 'Contactvelden',
        'Sender': 'Verzendadres',
        'Senders': 'Verzendadressen',
        'Tags': 'Labels',
        'Contacts': 'Contacten',
        'Users': 'Gebruikers',
        'Subscription': 'Abonnement',
        'Your account has used more than the allowed amount of messages.': 'Je hebt meer berichten verzonden dat toegelaten',
        'Ratios': 'Ratio\'s',
        '%{totalContactsSent} contacts sent in %{totalCampaignsSent} campaigns': '%{totalContactsSent} berichten verstuurd in %{totalCampaignsSent} campagnes',
        '%{count} contacts': '%{count} contacten',
        'Read: %{openRatio}%': 'Gelezen: %{openRatio}%',
        'Click: %{clickRatio}%': 'Geklikt: %{clickRatio}%',
        'Unsubscribe: %{unsubscribeRatio}%': 'Uitgeschreven: %{unsubscribeRatio}%',
        'Campaign': 'Campagne',
        'Campaigns': 'Campagnes',
        'Sent on': 'Verzonden op',
        'Report': 'Rapport',
        'Most recent campaigns': 'Meest recente campagnes',
        'Last modified messages': 'Recent gewijzigde berichten',
        'Message': 'Bericht',
        'Messages': 'Berichten',
        'Scheduled campaigns': 'Geplande campagnes',
        'Scheduled on': 'Verzenden op',
        'Edit': 'Wijzigen',
        'Created': 'Aangemaakt op',
        'Name': 'Naam',
        'name': 'Naam',
        'Attribute # %{name}': 'Contactveld # %{name}',
        'Email': 'E-mailadres',
        'State': 'Status',
        'Contact attribute': 'Contactveld',
        'Create attribute': 'Contactveld aanmaken',
        'Create sender': 'Verzendadres aanmaken',
        'SMTP Credentials': 'SMTP-inloggegevens',
        'Authentication matches username, password AND from address.': 'Authenticatie gebeurt op basis van gebruikersnaam, wachtwoord EN verzendadres',
        'Server': 'Server',
        'port': 'poort',
        'From address': 'Verzendadres',
        'SMTP Username': 'SMTP Gebruikersnaam',
        'SMTP Password': 'SMTP Wachtwoord',
        'Show password': 'Wachtwoord tonen',
        '%{field} is required': '%{field} is een verplicht veld',
        'A verification email has been sent to the sender\'s email address': 'Er is een verificatie-e-mail verzonden naar het verzendadres',
        'Verify': 'Verificatie',
        'ADD': 'TOEVOEGEN',
        'REMOVE': 'VERWIJDEREN',
        'Add Tag': 'Label toevoegen',
        'Label': 'Label',
        'label': 'Label',
        'Create': 'Aanmaken',
        'Tag # %{tag}': 'Label # %{tag}',
        'Import': 'Importeren',
        'Import a file to the blacklist': 'Een bestand naar de blocklist importeren',
        'Use a CSV or Excel file format with a single column containing the email address to blacklist. Enter one email address per row in the file.': 'Gebruik een CSV- of Excel-bestand met een enkele kolom met het e-mailadres dat aan de lijst moet worden toegevoegd. Voer één e-mailadres per rij in het bestand in.',
        'Selected file': 'Gekozen bestand',
        'Reason': 'Oorsprong',
        'Spam': 'Spam',
        'Manual': 'Handmatig toegevoegd',
        'Unsubscribed': 'Uitgeschreven',
        'Contact': 'Contact',
        'Contact list name': 'Naam van de contacten lijst',
        'i.e. Customers': 'vb: Klantenlijst',
        'Recipients': 'Ontvangers',
        'Group # %{name}': 'Contactenlijst # %{name}',
        'Contact List # %{name}': 'Contactenlijst # %{name}',
        'Create Group': 'Contactactenlijst aanmaken',
        'Blacklist': 'Bloklijst',
        'Active': 'Actief',
        'Blacklisted': 'Toegevoegd aan bloklijst',
        'Bounced out': 'Uitgebounced',
        'Signed up not confirmed': 'Inschrijving nog niet bevestigd',
        'Create Contact': 'Contact aanmaken',
        'Contact # %{email}': 'Contact # %{email}',
        'Import contacts': 'Contacten importeren',
        'Select a file to import.': 'Selecteer het bestand om te importeren.',
        'Supported file types: csv/xls/xlsx': 'Ondersteunde bestandstypes: csv/xls/xlsx',
        'Upload': 'Opladen',
        'Match your columns to your contact attributes.': 'Duid aan welke kolommen overeenkomen met welke contactvelden',
        'Column: %{header}': 'Kolom: %{header}',
        'What do we need to do with contacts that are already a member of the group?': 'Wat moeten we doen met contacten die al lid zijn van de contactlijst?',
        'Duplicate action': 'Reeds bestaande contacten',
        'Ignore': 'Negeren',
        'Update - ONLY mapped attributes are updated': 'Aanpassen - ENKEL de aangeduide contactvelden worden aangepast',
        'Overwrite - ALL attributes are reset before update': 'Overschrijven: ALLE contactvelden worden leeggemaakt voor de update',
        'Your contact data will now be uploaded to our servers and be processed. It is safe to close this dialog.': 'Je contactdata wordt momenteel doorgestuurd naar de server, daarna zal de server de data verwerken. Je moet hier niet op wachten, je mag het dialoogvenster sluiten',
        'Signup form': 'Inschrijvingsformulier',
        'Signup form # %{name}': 'Inschrijvingsformulier # %{name}',
        'Unsaved changes!': 'Niet-opgeslagen wijzigingen!',
        'You have unsaved changes, do you want to save before closing?': 'Je hebt niet-opgeslagen wijzigingen, wil je deze opslaan voor je afsluit?',
        'Save changes': 'Wijzigingen opslaan',
        'Close anyway': 'Toch sluiten',
        'Confirmation mail settings': 'Instellingen bevestigingsbericht',
        'Subject': 'Onderwerp',
        'Title': 'Titel',
        'Text': 'Tekst',
        'Form': 'Formulier',
        'Click the button below to generate form source code and preview': 'Klik op onderstaande knop om broncode en een voorbeeld te genereren.',
        'Generate': 'Genereren',
        'Copy/Paste the source code on your website': 'Kopieer de broncode naar je website',
        'Test you form below.': 'Test het formulier',
        'Form submitted page': 'Pagina na ingave van het formulier',
        'On success': 'Bij succesvolle ingave',
        'When the contact is already subscribed': 'Als het contact reeds lid is van de lijst',
        'Confirmation page': 'Pagina bij bevestiging van de inschrijving',
        'On confirmation': 'Bij succesvolle bevestiging',
        'When the contact has already confirmed': 'Als het contact reeds eerder heeft bevestigd.',
        'Save': 'Opslaan',
        'Save and Close': 'Opslaan en sluiten',
        'Cancel': 'Annuleren',
        'Close': 'Sluiten',
        'I want to edit the HTML myself': 'Ik wil zelf de HTML broncode bewerken',
        'Duplicate': 'Dupliceren',
        'Duplicate message: %{name}': 'Bericht "%{name}" dupliceren',
        'This message has a text variant': 'Dit bericht beschikt over een tekst variant',
        'Message is ready for sending in campaigns': 'Dit bericht is klaar om te verzenden in een campagne',
        'Links': 'Links',
        'Disabled': 'Niet actief',
        'Enabled': 'Actief',
        'Link': 'Link',
        'Location': 'Locatie',
        'No links in message': 'Dit bericht bevat geen links',
        'In archive': 'Archief',
        'Published': 'Gepubliceerd',
        'Preview': 'Voorbeeld',
        'Message # %{name}': 'Bericht # %{name}',
        'Send preview': 'Voorbeeld versturen',
        'Send message preview': 'Verstuur een voorbeeldbericht',
        'A message preview will be sent to the following email address': 'Vestuur een voorbeeldbericht naar onderstaand e-mailadres',
        'Send': 'Versturen',
        'Contact attribute: %{field}': 'Contactveld: %{field}',
        'Merge tags': 'Contactvelden',
        'Special links': 'Speciale links',
        'Unsubscribe link': 'Uitschrijflink',
        'Web version': 'Browser weergave',
        'unique recipients': 'unieke ontvangers',
        'unique recipients before filters': 'unieke ontvangers voor filtering',
        'Metadata': 'Metadata',
        'Overview': 'Overzicht',
        '%{name} (%{numberOfContacts} contacts)': '%{name} (%{numberOfContacts} contacten)',
        'Subject line': 'Onderwerp',
        'Exclude contacts': 'Contacten uitsluiten',
        'Excluded Contacts': 'Uitgesloten contacten',
        'Send a notification email when the campaign has finished sending': 'Verstuur een melding wanneeer de campagne verstuurd is',
        'Schedule': 'Inplannen',
        'Schedule on': 'Versturen op',
        'New': 'Nieuw',
        'Scheduled': 'Ingepland',
        'Sending': 'Aan het verzenden',
        'Sent': 'Verzonden',
        'Confirmation required': 'Verzendbevestiging',
        'You are about to launch this campaign "%{title}" to a maximum of %{recipients} recipients.': 'Je staat op het punt om de campagne "%{title}" te verzenden naar maximaal %{recipients} ontvangers',
        'To make sure this is what you intend, you are required to type the number of recipients in the textbox below.': 'Om er zeker van te zijn dat dit is wat je wil doen, vragen we je om het aantal ontvangers hieronder in te geven.',
        'Number of recipients': 'Aantal ontvangers',
        'Launch': 'Verzenden',
        'Report: %{name}': 'Rapport: %{name}',
        'Metrics': 'Samenvatting',
        'Loading': 'Bezig met laden',
        'Unique': 'Uniek',
        'Total': 'Totaal',
        'ID': 'ID',
        'Timestamp': 'Tijdstip',
        'No records to display': 'Er zijn geen gegevens om weer te geven',
        'Return to campaign report': 'Keer terug naar het campagne rapport',
        'No contacts bounced. Delivery rate: 100%': 'Er zijn geen bounces. Afleveringspercentage: 100%',
        'contacts bounced. Delivery rate: %{deliveryRate}%': 'contact zijn gebounced, Afleveringspercentage: %{deliveryRate}',
        'Started sending on': 'Verzending gestart op',
        'Finished sending on': 'Verzending afgrond op',
        'opened': 'gelezen',
        'Opened': 'Gelezen',
        'clicked': 'geklikt',
        'Clicked': 'Geklikt',
        'bounced': 'teruggekeerd',
        'Bounced': 'Teruggekeerd',
        'Unique contacts': 'Unieke contacten',
        'Total actions': 'Aantal acties',
        'Last occurrence': 'Tijdstip laatste actie',
        'Your are about to schedule campaign "%{title}" to "%{recipients}" recipients.': 'Je staat op het punt om de campagne "%{title}" in te plannen om te versturen naar %{recipients} ontvangers',
        'No longer available': 'Niet meer beschikbaar',
        'Campaign # %{name}': 'Campagne # %{name}',
        'Next': 'Volgende',
        'Send now': 'Nu versturen',
        'Could not fetch message details': 'Berichtdetails kunnen niet worden opgehaald',
        'Subject: %{subject}': 'Onderwerp: %{subject}',
        'To: %{to}': 'Aan: %{to}',
        'From: %{from} sent on %{sentOn}': 'Van: %{from} verzonden op: %{sentOn}',
        'Event': 'Activiteit',
        'Events': 'Activiteiten',
        '%{timestamp} Read (%{ip})': '%{timestamp} Gelezen (%{ip})',
        '%{timestamp} Click on %{url} (%{ip})': '%{timestamp} Geklikt op %{url} (%{ip})',
        '%{timestamp} Bounced with code: %{code}': '%{timestamp} Teruggekeerd met code: %{code}',
        'HTML': 'HTML',
        'To': 'Aan',
        'Sent State': 'Verzendstatus',
        'Is bounced': 'Teruggekeerd',
        'Is read': 'Gelezen',
        'Is visited': 'Geklikt',
        'Read': 'Gelezen',
        'Daily totals': 'Dagcijfers',
        'Monthly totals': 'Maandcijfers',
        'failed': 'Mislukt',
        'verified': 'Geverifieerd',
        'not-verified': 'Niet geverifieerd',
        'active': 'Actief',
        'blacklisted': 'Op de bloklijst',
        'deleted': 'Verwijderd',
        'unsubscribed': 'Uitgeschreven',
        'bounced-out': 'Teruggekeerd',
        'signed-up-not-confirmed': 'Wachtend op bevestiging',
        'Open rate': 'Gelezen',
        'Click rate': 'Geklikt',
        'Report action list: %{action}': 'Rapport actie lijst: %{action}',
        'Sending preview...': 'Voorbeeldbericht wordt verzonden ...',
        'Preview sent': 'Voorbeeldbericht is verzonden',
        'Form exception: %{message}': 'Formulier uitzondering: %{message}',
        'Campaign is sending': 'Campagne is aan het verzenden',
        'Error: Campaign could not be sent': 'Uitzondering: De campagne kan niet verzonden worden',
        'Senders exception: %{message}': 'Verzendadres uitzondering: %{message}',
        'Something went wrong: %{message}': 'Er ging iets mis: %{message}',
        'Tags exception: %{message}': 'Label uitzondering: %{message}',
        'Group exception: %{message}': 'Contactenlijst uitzondering: %{message}',
        'You need to define a sender first': 'Je moet eerst een verzendadres instellen',
        'Join our mailing list': 'Schrijf je in op onze nieuwsbrief',
        'Subscribe': 'Schrijf me in!',
        'you@example.com': 'jij@voorbeeld.com',
        'Mailing list sign up': 'Inschrijvingsformulier',
        'Sign up re-confirmed': 'Inschrijving herbevestigd',
        'You where already confirmed! Thanks for being awesome!': 'Je had je inschrijving al bevestigd! Jij bent geweldig!',
        'Whoops': 'Whoops',
        'You are already a member of out mailing list! You are awesome!': 'Je bent al ingeschreven op onze nieuwsbrief! Jij bent geweldig!',
        'Sign up confirmed': 'Inschrijving bevestigd',
        'You have successfully subscribed to our mailing list. Welcome!': 'Je bent succesvol ingeschreven voor onze nieuwsbrief, welkom!',
        'Signed up!': 'Ingeschreven!',
        'You have successfully signed up to our mailing list. You will receive a confirmation mail to confirm your subscription!':
            'Je bent succesvol ingeschreven voor onze nieuwsbrief. Je gaat nog een bericht ontvangen om je inschrijving te bevestigen!',
        'Welcome to our mailing list': 'Welkom bij onze nieuwsbrief',
        'Welcome to our mailing list!': 'Welkom bij onze nieuwsbrief!',
        'Please click the link below to confirm your subscription?!': 'Klik op onderstaande link om je inschrijving te bevestigen!',
        'Landing page': 'Landingspagina',
        'Landing pages': 'Landingspagina\'s',
        'Use this message as a landing page': 'Gebruik dit bericht als een landingspagina',
        'Publish landing page': 'Landingspagina publiceren',
        'Landing page URL:': 'URL van de landingspagina:',
        'Landing page title': 'Titel van de landingspagina',
        'Reset passwod': 'Wachtwoord vergeten',
        'Login': 'Aanmelden',
        'Required': 'Verplicht',
        'Reset password': 'Wachtwoord opnieuw instellen',
        'Email is required': 'E-mailadres is verplicht',
        'Password reset has been submitted, you will receive an email shortly': 'Je zal zo een e-mail van ons ontvangen om een nieuw wachtwoord in te stellen',
        'Your session has been expired': 'Je sessie is verlopen',
        'Password too short': 'Wachtwoord moet minimaal 10 karakters bevatten',
        'Password requires at least one lower case character': 'Wachtwoord moet minimaal 1 kleine letter bevatten',
        'Password requires at least one upper case character': 'Wachtwoord moet minimaal 1 hoofdletter bevatten',
        'Password requires at least on numeric character': 'Wachtwoord moet minimaal 1 cijfer bevatten',
        'Password require at least on special character': 'Wachtwoord moet minimaal 1 speciaal teken bevatten',
        'Passwords do not match': 'Wachtwoorden komen niet overeen',
        'Password': 'Wachtwoord',
        'Confirm password': 'Wachtwoord bevestigen',
        'Password set, redirecting to the application': 'Wachtwoord ingesteld, je wordt doorverwezen naar de applicatie',
        'Token not found': 'Token niet gevonden',
        'Invalid password': 'Ongeldig wachtwoord',
        'Something went wrong, please contact support': 'Er is iets fout gegaan, gelieve support te contacteren',
        'Webhooks': 'Webhooks',
        'Url': 'Url',
        '%{field} is not a valid url': '%{field} is geen geldige url',
        'Description': 'Omschrijving',
        'Type': 'Type',
        'Target URL': 'Doel-URL',
        'Webhook secret': 'Webhook sleutel',
        'The POST request has a header x-signature containing a Hash HMAC SHA256 of the body keyed with the secret':
            'De POST aanvraag bevat een x-signature header, deze bevat een Hash HMAC SHA256 sleutel van de inhoud van de aanvraag versleutel met de geheime steutel',
        'Secret key': 'Geheime sleutel',
        'Webhook details': 'Webhook details',
        'Bounce': 'Bounce',
        'Create webhook': 'Webhook aanmaken',
        'Equals': 'Gelijk aan',
        'Not equals': 'Niet gelijk aan',
        'Contains': 'Bevat',
        'Value': 'Waarde',
        'Filter': 'Filter',
        'Campaign read': 'Campagne gelezen',
        'Campaign not read': 'Campagne niet gelezen',
        'Uri visited': 'Link bezocht',
        'Uri not visited': 'Link niet bezocht',
    }
};
