import i18n from 'i18next';

const httpErrorHandler = (status, body) => {
    if (401 === status) {
        localStorage.removeItem('token');
        window.location = '/#/login';
    } else if (400 <= status && 500 > status) {
        return Promise.reject({
            message: labelTranslator(body['hydra:description']),
        });
    }
};

const labelTranslator = (message) => {
    if  (!isTranslatable(message)) {
        return message;
    }

    const indexOfColon = message.indexOf(':');
    const label = message.substr(0, indexOfColon);

    return `${i18n.t(label)}: ${message.substring(indexOfColon + 1)}`;
}

const isTranslatable = (message) => {
    const pattern = '[a-zA-Z]*:.*';
    const regex = new RegExp(pattern);

    return regex.test(message);
}

export default httpErrorHandler;
