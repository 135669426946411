import i18next from "i18next";

const validation = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = [i18next.t('%{field} is required', {field: i18next.t('Name')})];
    }

    if (!values.destination) {
        errors.destination = [i18next.t('%{field} is required', {field: i18next.t('Url')})];
    } else if (!values.destination.startsWith('http://') && !values.destination.startsWith('https://')) {
        errors.destination = [i18next.t('%{field} is not a valid url', {field: i18next.t('Url')})];
    }

    return errors;
};

export default validation;
