import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import CampaignField from './CampaignField';
import CampaignReferenceField from './CampaignReferenceField';
import CampaignReferenceArrayField from './CampaignReferenceArrayField';
import MessagePreviewContainer from '../message/MessagePreviewContainer';
import {withTranslation} from 'react-i18next';

class CampaignCreateOverview extends React.Component {
    render() {
        const {campaign, wizard, wizardLoading, t} = this.props;

        if (wizardLoading) {
            return <LinearProgress style={{marginTop: '8px'}}/>;
        }

        return (
            <React.Fragment>
                <CampaignField
                    field={'name'}
                    label={t('Name')}
                />
                <CampaignField
                    field={'subjectLine'}
                    label={t('Subject line')}
                />
                <CampaignReferenceField
                    label={t('Sender')}
                    field={'sender'}
                    options={wizard.senders}
                    optionText={'headerValue'}
                />
                {campaign.hasOwnProperty('message') && wizard.messages.loaded &&
                <div style={{
                    display: 'flex',
                    width: '100%',
                }}>
                    <div style={{marginRight: '8px'}}>
                        <CampaignReferenceField
                            label={t('Message')}
                            field={'message'}
                            options={wizard.messages}
                        />
                    </div>
                    <div style={{paddingTop: '38px'}}>
                        {campaign && campaign.message && <MessagePreviewContainer
                            id={campaign.message.split('/').pop()}
                            button={false}
                            saveBeforePreview={false}
                            messageSaving={false}
                        />}
                    </div>
                </div>}
                <CampaignReferenceArrayField
                    label={t('Recipients')}
                    field={'groups'}
                    options={wizard.groups}
                />
                {campaign.excludedGroups.length > 0 &&
                <CampaignReferenceArrayField
                    label={t('Excluded groups')}
                    field={'excludedGroups'}
                    options={wizard.groups}
                />}
                {campaign.tags.length > 0 &&
                <CampaignReferenceArrayField
                    label={t('Tags')}
                    field={'tags'}
                    options={wizard.tags}
                    optionText={'label'}
                />}
            </React.Fragment>
        );
    }
}

export default withTranslation()(CampaignCreateOverview);