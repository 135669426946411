import React from 'react';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from "jodit-react";
import {entrypoint} from '../../config/config';
import {getLocale} from "../../util/i18n";

class JoditEditorInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: props.input.value,
        }
    }

    /**
     * @property Jodit jodit instance of native Jodit
     */
    jodit;
    setRef = jodit => this.jodit = jodit;

    config = {
        autofocus: true,
        buttons: 'source,|,bold,strikethrough,underline,italic,|,superscript,subscript,|,ul,ol,|,align,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,table,link,|,undo,redo,\n,cut,hr,eraser,copyformat,|,symbol,fullsize,selectall',
        filebrowser: {
            ajax: {
                async: true,
                cache: true,
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                crossDomain: true,
                data: {},
                dataType: 'json',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                process: function (resp) {
                    return {
                        baseurl: resp.baseurl,
                        data: resp.data,
                        error: resp.error,
                        files: resp.files || [],
                        msg: resp.msg,
                        path: resp.path
                    };
                },
                processData: true,
                method: 'POST',
                url: entrypoint + '/im/execute',
                withCredentials: false
            },
            create: {
                data: {action: 'folderCreate'}
            },
            folder: {
                data: {action: 'folders'}
            },
            items: {
                data: {action: 'files'}
            }
        },
        language: getLocale(),
        spellcheck: false,
        uploader: {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            url: entrypoint + '/im/execute?action=fileUpload',
            withCredentials: false
        },
    };

    render() {
        const {onChange, value} = this.props.input;

        return <JoditEditor
            editorRef={this.setRef}
            value={value}
            config={this.config}
            onChange={(content) => onChange(content)}
        />
    }
}

export default JoditEditorInput;
