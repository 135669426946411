import React, {Fragment} from "react";
import {connect} from 'react-redux';
import {LinearProgress} from 'react-admin';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';

const ContactImportDialogStep3 = ({message, value}) => {
    const {t} = useTranslation();

    return <Fragment>
        <DialogContentText>
            {t('Your contact data will now be uploaded to our servers and be processed. It is safe to close this dialog.')}
        </DialogContentText>
        <LinearProgress variant="determinate" value={value}/>
        {message && <Typography variant="body1">{message}</Typography>}
    </Fragment>
};

const mapStateToProps = state => ({
    message: state.contactImport.ui.progress.message,
    value: state.contactImport.ui.progress.value
});

export default connect(mapStateToProps)(ContactImportDialogStep3);