import React, {useState} from 'react';
import {IconButton} from '@material-ui/core';
import {Info} from '@material-ui/icons';
import {MessageDetails} from "./MessageDetails";

const MessageDetailsContainer = ({record: {id}}) => {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <IconButton onClick={() => setOpen(true)}>
                <Info/>
            </IconButton>
            {open && <MessageDetails id={id}  onClose={() => setOpen(false)}/>}
        </React.Fragment>
    );
};

export default MessageDetailsContainer;
