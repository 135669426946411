import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    link: {
        color: '#b24d43',
        textDecoration: 'none',
    },
    paper: {
        backgroundColor: 'rgb(238, 240, 251)',
        paddingBottom: '24px',
        paddingTop: '24px',
    }
}));

const CampaignReportMetricBox = (props) => {
    const {id, label, type, value, ...rest} = props;
    const classes = useStyles();

    return (
        <Paper square elevation={2} className={classes.paper} {...rest}>
            <Typography align={'center'} variant={'title'} color={'primary'}>
                {value === 0 && `0`}
                {value > 0 && <Link to={`/campaigns/${id}/report/list/${type}`} className={classes.link}>{value}</Link>}
            </Typography>
            <Typography align={'center'} variant={'caption'}>{label}</Typography>
        </Paper>
    );
};

CampaignReportMetricBox.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['opened', 'bounced', 'clicked', 'unsubscribed']).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
};

export default CampaignReportMetricBox;