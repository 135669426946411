import React from 'react';
import {CheckboxGroupInput, Edit, SimpleForm} from 'react-admin'
import UserFormBasic from './UserFormBasic';

const modules = [
    {id: 'ADMIN', name: 'Admin'},
    {id: 'BULK', name: 'Interface'},
    {id: 'SMTP', name: 'SMTP'},
];

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <UserFormBasic/>
            <CheckboxGroupInput source={'modules'} choices={modules}/>
        </SimpleForm>
    </Edit>
);

export default UserEdit;
