import {CREATE, GET_LIST, UPDATE} from 'react-admin';
import {GroupHelper} from '../../../util/GroupHelper';

/**
 * Decorator for contact related requests
 *
 * @param requestHandler
 * @returns {function(...[*]=)}
 */
export const contactsDecorator = requestHandler => (type, resource, params) => {
    if (resource !== 'contacts') {
        return requestHandler(type, resource, params);
    }

    if (type === GET_LIST) {
        params = applyGroupFilter(params);
    }

    if (type === CREATE || type === UPDATE) {
        params = denormalizeAttributes(params);
        params = denormalizeGroup(params);
    }

    return requestHandler(type, resource, params);
};

/**
 * Make sure the group filter is always set
 *
 * @param params
 * @returns {*}
 */
const applyGroupFilter = (params) => {
    params.filter['group'] = GroupHelper.getContactListGroupId();

    return params;
};

/**
 * Denormalize contact attributes
 *
 * @param params
 * @returns {*}
 */
const denormalizeAttributes = (params) => {
    let attributes = [];
    Object.entries(params.data).forEach(([key, value]) => {
        if (!key.startsWith('attribute_')) {
            return;
        }

        attributes.push({
            'attributeId': key.split('_').pop(),
            'value': value,
        });

        delete params.data[key];
    });

    params.data.attributes = attributes;

    return params;
};

const denormalizeGroup = (params) => {
    params.data.group = `/groups/${GroupHelper.getContactListGroupId()}`;

    return params;
};