import { call, put, select, takeEvery } from 'redux-saga/effects';
import API from '../api';
import { CONTACT_IMPORT_REPORT_DOWNLOAD } from '../actions/actionTypes';
import { contactImportReportDownloadDone } from "../actions/contactImportReportActions";
import { transactionSelector } from '../selectors/contactImportReportSelectors';
import base64Download from '../util/base64Download'

export function* downloadReport(action) {
    const transaction = yield select(transactionSelector);
    const response = yield API.contactImportTransactionReport(transaction);

    const {status, json } = response;
    if (status !== 200) {
        new Error('Could not retrieve attributes');
    }

    yield call(base64Download, json.filename, json.data,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    yield put(contactImportReportDownloadDone());
}

export function* contactImportReportSagas() {
    yield takeEvery(CONTACT_IMPORT_REPORT_DOWNLOAD, downloadReport);
}