import React from 'react';
import {
    Datagrid,
    DeleteButton,
    EditButton,
    List,
    TextField,
} from 'react-admin';
import CardActions from '../common/CardActions';
import TagFilter from './TagFilter';
import {withTranslation} from 'react-i18next';

const TagList = ({t, ...props}) => (
    <List title={t('Tags')} {...props} filters={<TagFilter/>} sort={{field: 'label.value', order: 'ASC'}} actions={<CardActions/>}>
        <Datagrid>
            <TextField source={'label'} sortBy={'label.value'} label={t('Label')}/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export default withTranslation()(TagList);
