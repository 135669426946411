import {
    ACCOUNT_GET_INFO,
    ACCOUNT_GET_STATS,
    ACCOUNT_SET_ATTRIBUTES
} from '../actions/actionTypes';

const initialState = {
    data: {
        attributes: null,
    },
    info: {
        id: null,
        modules: [],
        name: '',
        roles: [],
        subscription: {
            type: 'loading',
            contactLimit: 0,
            contactCount: 0,
            isOverContactLimit: false,
            messageLimit: 0,
            messageCount: 0,
            isOverMessageLimit: false,
        }
    },
    infoLoading: true,
    stats: {
        campaignsSent: 0,
        contactsSent: 0,
        contactClickActions: 0,
        contactOpenActions: 0,
        contactUnsubscribeActions: 0,
        ratioOpen: 0,
        ratioClick: 0,
        ratioUnsubscribe: 0,
    },
    statsLoading: true,
};

export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case ACCOUNT_GET_INFO:
            return Object.assign({}, state, {
                info: action.payload.info,
                infoLoading: false,
            });

        case ACCOUNT_GET_STATS:
            return Object.assign({}, state, {
                stats: action.payload.stats,
                statsLoading: false,
            });

        case ACCOUNT_SET_ATTRIBUTES:
            return Object.assign({}, state, {data: Object.assign({}, state.data, {attributes: action.attributes})});

        default:
            return state;
    }
}
