import React, {Component} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {translate} from 'ra-core';
import classNames from 'classnames';
import {TextField} from '@material-ui/core/';

const styles = theme => ({
    root: {
        padding: 0,
        marginBottom: 0,
        '& > li:last-child': {
            borderBottom: 'none',
        },
    },
    line: {
        display: 'flex',
        listStyleType: 'none',
        borderBottom: `solid 1px ${theme.palette.divider}`,
        [theme.breakpoints.down('xs')]: {display: 'block'},
        '&.fade-enter': {
            opacity: 0.01,
            transform: 'translateX(100vw)',
        },
        '&.fade-enter-active': {
            opacity: 1,
            transform: 'translateX(0)',
            transition: 'all 500ms ease-in',
        },
        '&.fade-exit': {
            opacity: 1,
            transform: 'translateX(0)',
        },
        '&.fade-exit-active': {
            opacity: 0.01,
            transform: 'translateX(100vw)',
            transition: 'all 500ms ease-in',
        },
    },
    index: {
        width: '3em',
        paddingTop: '1em',
        [theme.breakpoints.down('sm')]: {display: 'none'},
    },
    form: {flex: 2},
    action: {
        paddingTop: '0.5em',
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
});

export class SimpleFormIterator extends Component {
    state = {
        values: []
    };

    constructor(props) {
        super(props);

        const {campaign, field} = props;

        let values = campaign[field].map((item) => ({
            value: item
        }));

        if (values.length === 0) {
            values.push({
                value: ''
            });
        }

        this.state.values = values;
    }

    removeField = index => () => {
        const {values} = this.state;

        values.splice(index, 1);

        this.setState(values);
        this.pushChanges();
    };

    addField = () => {
        const {values} = this.state;

        values.push({
            value: ''
        });

        this.setState(values);
        this.pushChanges();
    };

    handleChange = (index, value) => {
        const {values} = this.state;

        values[index].value = value;

        this.setState(values);
        this.pushChanges();
    };

    pushChanges = () => {
        const {change} = this.props;
        const {values} = this.state;

        change('notifications', values.map((v) => v.value));
    };


    render() {
        const {
            classes = {},
            field,
            translate,
        } = this.props;
        const {values} = this.state;

        return values ? (
            <ul className={classes.root}>
                <TransitionGroup>
                    {values.map((member, index) => (
                        <CSSTransition
                            key={index}
                            timeout={500}
                            classNames="fade"
                        >
                            <li className={classes.line}>
                                <Typography
                                    variant="body1"
                                    className={classes.index}
                                >
                                    {index + 1}
                                </Typography>

                                <section className={classes.form}>

                                    <React.Fragment>

                                        <TextField
                                            error={member.value !== '' && !member.value.match(/[a-zA-Z0-9]+@[a-zA-Z0-9]+/)}
                                            fullWidth
                                            onChange={(event) => this.handleChange(index, event.target.value)}
                                            value={member.value}
                                        />
                                    </React.Fragment>
                                </section>
                                <span className={classes.action}>
                                    <Button
                                        className={classNames(
                                            'button-remove',
                                            `button-remove-${field}-${index}`
                                        )}
                                        size="small"
                                        onClick={this.removeField(index)}
                                    >
                                        <CloseIcon
                                            className={classes.leftIcon}
                                        />
                                        {translate('ra.action.remove')}
                                    </Button>
                                </span>
                            </li>
                        </CSSTransition>
                    ))}
                </TransitionGroup>

                <li className={classes.line}>
                    <span className={classes.action}>
                        <Button
                            className={classNames(
                                'button-add',
                                `button-add-${field}`
                            )}
                            size="small"
                            onClick={this.addField}
                        >
                            <AddIcon className={classes.leftIcon}/>
                            {translate('ra.action.add')}
                        </Button>
                    </span>
                </li>
            </ul>
        ) : null;
    }
}

SimpleFormIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};

SimpleFormIterator.propTypes = {
    defaultValue: PropTypes.any,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    fields: PropTypes.array,
    meta: PropTypes.object,
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.bool,
};

export default compose(
    translate,
    withStyles(styles)
)(SimpleFormIterator);
