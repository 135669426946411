import {
    CONTACT_IMPORT_TRANSACTION_CREATED,
    CONTACT_IMPORT_TRANSACTION_IMPORTED,
    CONTACT_IMPORT_TRANSACTION_IMPORTING,
    CONTACT_IMPORT_TRANSACTION_RESET_STATE,
    CONTACT_IMPORT_TRANSACTION_SET_ATTRIBUTES,
    CONTACT_IMPORT_TRANSACTION_SET_DATA,
    CONTACT_IMPORT_TRANSACTION_SET_FILENAME,
    CONTACT_IMPORT_TRANSACTION_SET_MAP,
    CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_MESSAGE,
    CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_VALUE,
    CONTACT_IMPORT_TRANSACTION_SET_STEP,
    CONTACT_IMPORT_TRANSACTION_TOGGLE_UI,
    GROUP_CONTACTS_BUTTON_CLICKED
} from "../actions/actionTypes";
import {CHANGE} from 'redux-form/es/actionTypes';
import {LOCATION_CHANGE} from 'react-router-redux';

import {
    CRUD_GET_LIST_SUCCESS
} from 'react-admin';
import {getUrlParams} from "../util/string";

const initialState = {
    ui: {
        display: false,
        step: 1,
        progress: {
            message : '',
            value: 0
        }
    },
    attributes: null,
    data: null,
    duplicateAction: 'ignore',
    group: null,
    id: null,
    map: [],
    importing: false,
    filename: ''
};

export default function contactImportReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE:
            const {meta: {field} = {}, payload} = action;

            if (!field || field !== 'duplicateAction') {
                return state;
            }

            return Object.assign({}, state, {duplicateAction: payload});

        case CRUD_GET_LIST_SUCCESS:
            if ('contacts' === action.meta.resource) {
                return Object.assign({}, state, {group: action.requestPayload.filter.group});
            }
            return state;

        case CONTACT_IMPORT_TRANSACTION_TOGGLE_UI:
            return Object.assign({}, state, {ui: Object.assign({}, state.ui, {display: action.visible})});

        case CONTACT_IMPORT_TRANSACTION_RESET_STATE:
            return Object.assign({}, initialState, {group: state.group});

        case CONTACT_IMPORT_TRANSACTION_SET_STEP:
            return Object.assign({}, state, {ui: Object.assign({}, state.ui, {step: action.step})});

        case CONTACT_IMPORT_TRANSACTION_SET_MAP:
            return Object.assign({}, state, {map: action.map});

        case CONTACT_IMPORT_TRANSACTION_SET_DATA:
            return Object.assign({}, state, {data: action.data});

        case CONTACT_IMPORT_TRANSACTION_SET_FILENAME:
            return Object.assign({}, state, {filename: action.filename});

        case CONTACT_IMPORT_TRANSACTION_SET_ATTRIBUTES:
            return Object.assign({}, state, {attributes: action.attributes});

        case CONTACT_IMPORT_TRANSACTION_CREATED:
            return Object.assign({}, state, {id: action.id});

        case CONTACT_IMPORT_TRANSACTION_IMPORTING:
            return Object.assign({}, state, {importing: true});

        case CONTACT_IMPORT_TRANSACTION_IMPORTED:
            return Object.assign({}, state, {importing: false});

        case CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_MESSAGE:
            return Object.assign({}, state, {ui:
                     Object.assign({}, state.ui, {progress:
                       Object.assign({}, state.ui.progress, {message: action.message})
                     })
            });

        case CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_VALUE:
            return Object.assign({}, state, {ui:
                     Object.assign({}, state.ui, {progress:
                       Object.assign({}, state.ui.progress, {value: action.value})
                     })
            });

        case GROUP_CONTACTS_BUTTON_CLICKED:
            return Object.assign({}, state, {group: action.group});

        case LOCATION_CHANGE:
            const {payload: {pathname, search} = {}} = action;
            const {group} = getUrlParams(search);

            if (pathname !== '/contacts' || !group) {
                return state;

            }

            return Object.assign({}, state, {group: group});

        default:
            return state;
    }
}
