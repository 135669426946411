import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CampaignsIcon from '@material-ui/icons/DateRange';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from "@material-ui/styles";
import purple from '@material-ui/core/colors/purple';
import {withTranslation} from 'react-i18next';
import CardWithBackground from "../common/CardWithBackground";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: purple[500],
    },
    link: {
        color: '#b24d43',
        textDecoration: 'none'
    },
}));

const DashboardScheduledCampaigns = (props) => {
    const classes = useStyles();
    const {campaigns, campaignsLoading, t} = props;
    return (
        <CardWithBackground>
            <CardHeader title={t('Scheduled campaigns')} avatar={
                <Avatar className={classes.avatar}>
                    <CampaignsIcon/>
                </Avatar>
            }/>
            <CardContent>
                {!campaignsLoading &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Campaign')}</TableCell>
                            <TableCell>{t('Scheduled on')}</TableCell>
                            <TableCell>{t('Edit')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaigns.map(campaign => (
                            <TableRow hover key={campaign.id}>
                                <TableCell>
                                    {campaign.name}
                                </TableCell>
                                <TableCell>
                                    {campaign.scheduledOn.timestamp.substr(0, 16)}
                                </TableCell>
                                <TableCell>
                                    <Link to={`/campaigns/${campaign.id}`} className={classes.link}><CampaignsIcon /></Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                }
                {campaignsLoading && <LinearProgress/>}
            </CardContent>
        </CardWithBackground>
    );
};

DashboardScheduledCampaigns.propTypes = {
    campaigns: PropTypes.array.isRequired,
    campaignsLoading: PropTypes.bool.isRequired,
};

export default withTranslation()(DashboardScheduledCampaigns);
