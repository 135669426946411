import {CREATE, UPDATE} from 'react-admin';

/**
 * Decorator for admin/users related requests
 *
 * @param requestHandler
 * @returns {function(...[*]=)}
 */
export const adminUserDecorator = requestHandler => (type, resource, params) => {
    if (resource !== 'admin/users') {
        return requestHandler(type, resource, params);
    }

    if (type === CREATE || type === UPDATE) {
        params.data.receiveMynumaInformation = true;
    }

    return requestHandler(type, resource, params);
};