import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TextField} from '@material-ui/core/';
import {selectMessage} from '../../selectors/messageSelectors';
import {messageChange} from '../../actions/messageActions';
import MessageInput from "./MessageInput";

const MessageTextField = (
    {
        autoFocus = false,
        change,
        error = false,
        field,
        message,
        multiline = false,
        label,
        required = false,
        rows = 1,
        validate = null
    }
) => {
    const [dirty, setDirty] = useState(false);

    return (
        <MessageInput>
            <TextField
                autoFocus={autoFocus}
                fullWidth
                error={error || (validate && dirty && !validate(message[field]))}
                id={field}
                label={label}
                multiline={multiline}
                onChange={(e) => {
                    setDirty(true);
                    change(field, e.target.value);
                }}
                required={required}
                rows={rows}
                value={message ? message[field] : ''}
            />
        </MessageInput>
    );
};

MessageTextField.propTypes = {
    autoFocus: PropTypes.bool,
    message: PropTypes.object.isRequired,
    multiline: PropTypes.bool,
    change: PropTypes.func.isRequired,
    error: PropTypes.bool,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    rows: PropTypes.number,
};

const mapStateToProps = state => ({
    message: selectMessage(state)
});

const mapDispatchToProps = dispatch => ({
    change: (field, value) => dispatch(messageChange(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageTextField);