import {CREATE, showNotification} from 'react-admin';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {TAG_SAVE} from '../actions/actionTypes';
import {getTagLabel} from '../selectors/tagSelector';
import {dataProvider} from '../components/http/dataProvider';
import {tagSaveError, tagSaveSuccess} from '../actions/tagActions';
import i18n from "i18next";

export function* tagSave() {
    const label = yield select(getTagLabel);
    const data = {
        label,
    };

    try {
        const response = yield call(dataProvider, CREATE, 'tags', {data});
        const tag = {
            id: response.data.id,
            label: response.data.label
        };

        tag['@id'] = `/tags/${response.data.id}`;

        yield put(tagSaveSuccess(tag));
    } catch (exception) {
        yield put(showNotification(i18n.t('Something went wrong: %{message}', {exception: exception.message}), 'warning'));
        yield put(tagSaveError());
    }

}

export function* tagSagas() {
    yield takeLatest(TAG_SAVE, tagSave);
}
