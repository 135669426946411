import {put, takeEvery} from 'redux-saga/effects';
import {LOCATION_CHANGE} from 'react-router-redux';
import API from "../api";
import {dashboardCampaignsScheduledSet, dashboardCampaignsSentSet, dashboardMessagesSet} from "../actions/dashboardActions";

function* dashboardGetCampaignsScheduled(action) {
    if (action.payload.pathname !== '/home') {
        return;
    }
    const campaigns = yield API.dashboardGetScheduledCampaigns();
    if (undefined === campaigns) {
        return;
    }
    yield put(dashboardCampaignsScheduledSet({
        campaigns: campaigns.json['hydra:member']
    }));
}


function* dashboardGetCampaignsSent(action) {
    if (action.payload.pathname !== '/home') {
        return;
    }
    const campaigns = yield API.dashboardGetSentCampaigns();
    if (undefined === campaigns) {
        return;
    }
    yield put(dashboardCampaignsSentSet({
        campaigns: campaigns.json['hydra:member']
    }));
}

function* dashboardGetMessages(action) {
    if (action.payload.pathname !== '/home') {
        return;
    }
    const messages = yield API.dashboardGetMessages();
    if (undefined === messages) {
        return;
    }
    yield put(dashboardMessagesSet({
        messages: messages.json['hydra:member']
    }));
}

export function* dashboardSagas() {
    yield takeEvery(LOCATION_CHANGE, dashboardGetCampaignsScheduled);
    yield takeEvery(LOCATION_CHANGE, dashboardGetCampaignsSent);
    yield takeEvery(LOCATION_CHANGE, dashboardGetMessages);
}