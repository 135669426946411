/**
 * @returns {string}
 */
export const getEntryPoint = () => {
    const location = window.location.hostname;

    switch (location) {
        case 'client.mynuma.io':
            return 'https://api.mynuma.io';

        case 'staging.client.mynuma.io':
            return 'https://staging.api.mynuma.io';

        default:
            return 'http://localhost';
    }
};

/**
 * @returns {string}
 */
export const getResponseUrl = () => {
    const location = window.location.hostname;

    switch (location) {
        case 'client.mynuma.io':
            return 'https://r.mynuma.io';

        case 'staging.client.mynuma.io':
            return 'https://staging.api.mynuma.io';

        default:
            return 'http://localhost';
    }
};