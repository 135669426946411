import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {submit, isSubmitting} from 'redux-form';
import {SelectInput, SimpleForm} from 'react-admin';
import DialogContentText from '@material-ui/core/DialogContentText';
import ContactImportToolbar from './ContactImportToolbar';
import PropTypes from 'prop-types';
import SanitizedDiv from '../common/Sanitized/SanitizedDiv';
import {withTranslation} from 'react-i18next';

class ContactImportDialogStep2 extends Component {
    handleSaveClick = () => {
        const {submit} = this.props;

        submit('import-header-map');
    };

    populateChoice = (attributes) => {
        return [
            {id: 'ignore', name: 'Ignore'},
            {id: 'email', name: 'Email'}
        ].concat(attributes);
    };

    render() {
        const {attributes, handleSubmitMap, headers, t} = this.props;
        const choices = this.populateChoice(attributes);
        const duplicateChoices = [
            {
                id: 'ignore',
                name: t('Ignore'),
            },
            {
                id: 'update',
                name: t('Update - ONLY mapped attributes are updated'),
            },
            {
                id: 'overwrite',
                name: t('Overwrite - ALL attributes are reset before update'),
            }
        ];

        return <Fragment>
            <DialogContentText>{t('Match your columns to your contact attributes.')}</DialogContentText>
            <SimpleForm toolbar={<ContactImportToolbar label={t('Import')} handleSaveClick={this.handleSaveClick}/>}
                        form="import-header-map" onSubmit={handleSubmitMap}>
                {headers.map((header, index) => {
                    const label = t('Column: %{header}', {header});
                    const source = `column_${index}`;
                    return <SelectInput defaultValue="ignore" key={index} label={label} choices={choices}
                                        source={source} optionText="name" fullWidth/>;
                })}
                <SanitizedDiv style={{
                    marginLeft: '-24px',
                    marginTop: '25px',
                    width: '100%'
                }}>
                    <SanitizedDiv>
                        <DialogContentText>{t('What do we need to do with contacts that are already a member of the group?')}</DialogContentText>
                    </SanitizedDiv>
                    <SanitizedDiv style={{
                        paddingLeft: '24px',
                    }}>
                        <SelectInput label={t('Duplicate action')}
                                     defaultValue={'ignore'}
                                     choices={duplicateChoices}
                                     source={'duplicateAction'}
                                     fullWidth={true}
                        />
                    </SanitizedDiv>
                </SanitizedDiv>
            </SimpleForm>
        </Fragment>;
    }
}

ContactImportDialogStep2.propTypes = {
    attributes: PropTypes.array.isRequired,
    handleSubmitMap: PropTypes.func.isRequired,
    headers: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    form: state.form,
    isSubmitting: isSubmitting('post-quick-create')(state)
});

const mapDispatchToProps = {
    submit
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactImportDialogStep2));