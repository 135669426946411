import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    DialogContent,
    Divider,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import {
    signupSelectForm,
    signupSelectGroup,
    signupSelectSenders,
    signupSelectUiCancelConfirmationIsVisible,
    signupSelectUiIsLoading,
} from '../../selectors/signupSelector';
import SignupDialogContentLoading from './SingupDialogContentLoading';
import {signupFormChange, signupSave} from '../../actions/signupActions';
import SignupConfirmCancelDialog from './SignupConfirmCancelDialog';
import {responseUrl} from '../../config/config';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

const styles = () => ({
    card: {
        marginRight: '24px',
        marginBottom: '24px',
    },
    divider: {
        margin: '32px 0',
    },
    subheading: {
        marginBottom: '12px',
        marginTop: '32px',
    }
});

const generateSignUpForm = (id) => {
    return `<!-- Begin mynuma.io sign up form-->
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
<h4 class="mb-3">${i18n.t('Join our mailing list')}</h4>
<div style="width: 400px">
    <form method="post" target="_blank" action="${responseUrl}/sign-up/${id}">
        <input name="email" type="email" placeholder="${i18n.t('you@example.com')}" style="width:250px;">
        <button type="submit">${i18n.t('Subscribe')}</button>                
    </form>
</div>
<!-- End mynuma.io sign up form-->`;
};

const generateSignupTestForm = (id) => {
    return `
<html lang="en">
    <head>
        <title>${i18n.t('Mailing list sign up')}</title>
    </head>
    <body>
        ${generateSignUpForm(id)}   
    </body>
</html>    
    `
};


const SignupDialogContent = ({classes, form, isCancelConfirmationVisible, loading, onGenerate, senders, update}) => {
    const {t} = useTranslation();

    if (loading) {
        return (<SignupDialogContentLoading/>);
    }

    return (
        <DialogContent style={{
            paddingTop: '16px'
        }}>
            <Grid container>
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardHeader title={t('Confirmation mail settings')} />
                        <CardContent>
                            <FormControl style={{display: 'flex', minWidth: '240px'}}>
                                <InputLabel shrink={true} htmlFor={'sender-label-placeholder'}>
                                    {t('Sender')}
                                </InputLabel>
                                <Select fullWidth={true}
                                        input={<Input name={'sender'} id={'sender-label-placeholder'}
                                                      value={form.sender}/>}
                                        onChange={(e) => update(null, 'sender', e.target.value)}>
                                    <MenuItem value={'none'}>Select</MenuItem>
                                    {senders.map((sender) => (
                                        <MenuItem key={sender['@id']}
                                                  value={sender['@id']}>{sender.headerValue}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField fullWidth label={t('Subject')} value={form.confirmationMail.subject}
                                       onChange={(e) => update('confirmationMail', 'subject', e.currentTarget.value)}/>
                            <TextField fullWidth label={t('Title')} value={form.confirmationMail.title}
                                       onChange={(e) => update('confirmationMail', 'title', e.currentTarget.value)}/>
                            <TextField fullWidth multiline label={t('Text')} rows={3} value={form.confirmationMail.text}
                                       onChange={(e) => update('confirmationMail', 'text', e.currentTarget.value)}/>
                        </CardContent>
                    </Card>

                    <Card className={classes.card}>
                        <CardHeader title={t('Form')} />
                        <CardContent>
                            {!form.id && <React.Fragment>
                                <Typography variant={'subheading'}>{t('Click the button below to generate form source code and preview')}</Typography>
                                <Button variant={'contained'} onClick={() => onGenerate()}>{t('Generate')}</Button>
                            </React.Fragment>}
                            {form.id && <React.Fragment>
                                <Typography paragraph variant={'subheading'}>{t('Copy/Paste the source code on your website')}</Typography>

                                <div style={{
                                    border: '1px solid #ddd',
                                    overflow: 'hidden',
                                }}><pre>
                            {generateSignUpForm(form.id)}
                            </pre>
                                </div>

                                <Divider className={classes.divider}/>

                                <Typography paragraph variant={'subheading'}>{t('Test you form below.')}</Typography>

                                <iframe src={'data:text/html,' + encodeURIComponent(generateSignupTestForm(form.id))}
                                        style={{height: '200px', width: '450px'}}
                                        title={'sign-up-form'} frameBorder={0}/>
                            </React.Fragment>}
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardHeader title={t('Form submitted page')}/>
                        <CardContent>
                            <Typography paragraph variant={'subheading'} style={{fontWeight: 'bold'}}>{t('On success')}</Typography>
                            <TextField fullWidth label={t('Title')} value={form.signUpPage.title}
                                       onChange={(e) => update('signUpPage', 'title', e.currentTarget.value)}/>
                            <TextField fullWidth multiline label={t('Text')} rows={3} value={form.signUpPage.text}
                                       onChange={(e) => update('signUpPage', 'text', e.currentTarget.value)}/>
                            <Divider className={classes.divider}/>
                            <Typography className={classes.subheading} paragraph variant={'subheading'} style={{fontWeight: 'bold'}}>{t('When the contact is already subscribed')}</Typography>
                            <TextField fullWidth label={t('Title')} value={form.alreadySignedUpPage.title}
                                       onChange={(e) => update('alreadySignedUpPage', 'title', e.currentTarget.value)}/>
                            <TextField fullWidth multiline label={t('Text')} rows={3} value={form.alreadySignedUpPage.text}
                                       onChange={(e) => update('alreadySignedUpPage', 'text', e.currentTarget.value)}/>
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardHeader title={t('Confirmation page')}/>
                        <CardContent>
                            <Typography paragraph variant={'subheading'} style={{fontWeight: 'bold'}}>{t('On confirmation')}</Typography>

                            <TextField fullWidth label={t('Title')} value={form.confirmationPage.title}
                                       onChange={(e) => update('confirmationPage', 'title', e.currentTarget.value)}/>
                            <TextField fullWidth multiline label={t('Text')} rows={3} value={form.confirmationPage.text}
                                       onChange={(e) => update('confirmationPage', 'text', e.currentTarget.value)}/>
                            <Divider className={classes.divider}/>
                            <Typography className={classes.subheading} paragraph variant={'subheading'} style={{fontWeight: 'bold'}}>{t('When the contact has already confirmed')}</Typography>
                            <TextField fullWidth label={t('Title')} value={form.alreadyConfirmedPage.title}
                                       onChange={(e) => update('alreadyConfirmedPage', 'title', e.currentTarget.value)}/>
                            <TextField fullWidth multiline label={t('Text')} rows={3}
                                       value={form.alreadyConfirmedPage.text}
                                       onChange={(e) => update('alreadyConfirmedPage', 'text', e.currentTarget.value)}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {isCancelConfirmationVisible && <SignupConfirmCancelDialog/>}
        </DialogContent>
    );
};

const mapStateToProps = (state) => ({
    form: signupSelectForm(state),
    group: signupSelectGroup(state),
    loading: signupSelectUiIsLoading(state),
    senders: signupSelectSenders(state),
    isCancelConfirmationVisible: signupSelectUiCancelConfirmationIsVisible(state),
});

const mapDispatchToProps = (dispatch) => ({
    update: (category, field, value) => dispatch(signupFormChange(category, field, value)),
    onGenerate: () => dispatch(signupSave(false)),
});


const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);


export default enhance(SignupDialogContent);
