import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/styles";
import {Link} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TablePaginationActions from "../common/TablePaginationActions";
import DateField from '../common/Field/DateField';
import {TextField} from 'react-admin';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    link: {
        color: '#b24d43',
        textDecoration: 'none',
    }
}));

const CampaignReportList = (props) => {
    const classes = useStyles();
    const {count, data, link, onChangePage, onChangeRowsPerPage, page, loading, rowsPerPage, subtitle, title} = props;
    const {t} = useTranslation();

    return (
        <Card>
            {!loading &&
            <React.Fragment>
                <CardHeader title={title} subheader={<Link to={link} className={classes.link}>{subtitle}</Link>}/>

                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Timestamp')}</TableCell>
                                <TableCell>{t('Contact')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((event) => (
                                <TableRow key={event.id}>
                                    <TableCell>
                                        <DateField label={'Timestamp'} record={{timestamp: event.timestamp}} source={'timestamp'}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField label={'Contact'} record={{contact: event.contact}} source={'contact'}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {data.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={2}>
                                    {t('No records to display')}
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination count={count}
                                                 onChangePage={onChangePage}
                                                 onChangeRowsPerPage={onChangeRowsPerPage}
                                                 page={page}
                                                 rowsPerPage={rowsPerPage}
                                                 rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                 ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </CardContent>
            </React.Fragment>
            }

            {loading &&
            <React.Fragment>
                <CardHeader title={title} subheader={t('Loading')}/>
                <LinearProgress/>
            </React.Fragment>}
        </Card>
    );
};

CampaignReportList.propTypes = {
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default CampaignReportList;