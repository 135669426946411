import validation from "./validation";
import {BooleanInput, SelectInput, SimpleForm, TextInput} from "react-admin";
import React from "react";

const WebhookForm = ({t, ...props}) => (
    <SimpleForm {...props} redirect={'/webhooks'} validate={validation}>
        <TextInput source={'name'} label={t('Name')} autoFocus fullWidth required/>
        <TextInput source={'description'} label={t('Description')} fullWidth/>
        <SelectInput label={t('Type')} source="type" choices={[
            {id: 'bounce', name: t('Bounce')},
        ]} fullWidth required/>
        <TextInput source={'destination'} label={t('Target URL')} fullWidth required/>
        <BooleanInput source={'state'} label={t('Enabled')} format={(v) => v === 'enabled'} parse={(v) => v ? 'enabled' : 'disabled'}/>
    </SimpleForm>
);

export default WebhookForm;
