import React from 'react';
import {Create, DateInput, DisabledInput, ReferenceInput, SelectInput, SimpleForm} from 'react-admin'
import {connect} from 'react-redux';

export const SubscriptionCreate = ({dispatch, user, ...rest}) => (
    <Create {...rest}>
        <SimpleForm redirect={`/admin/user/subscriptions?owner=${user.split('/').pop()}`}>
            <DisabledInput label={'User'} source={'user'} defaultValue={user} style={{display: "none"}}/>
            <ReferenceInput label={'Product'} source={'product'} reference={'admin/products'}>
                <SelectInput optionText={'name'}/>
            </ReferenceInput>
            <DateInput source={'startsOn'} label={'Starts on'}/>
            <DateInput source={'expiresOn'} label={'Expires on'}/>
        </SimpleForm>
    </Create>
);

const mapStateToProps = (state) => ({
    user: '/admin/users/' + state.mynuma_admin.selectedUser.id,
});

export default connect(mapStateToProps)(SubscriptionCreate);