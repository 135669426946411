import React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    TextField,
} from 'react-admin';
import ContactCardActions from './ContactCardActions';
import ContactFilter from './ContactFilter';
import ContactListTitle from './ContactListTitle';
import DateField from '../common/Field/DateField';
import {useTranslation} from 'react-i18next';
import ContactStateField from "./ContactStateField";

const ContactList = (props) => {
    const {t} = useTranslation();

    return (
        <List {...props}
              actions={<ContactCardActions/>}
              filters={<ContactFilter/>}
              sort={{
                  field: 'email',
                  order: 'ASC'
              }}
              title={<ContactListTitle/>}>
            <Datagrid>
                <TextField source={'email'} label={t('Email')}/>
                <ContactStateField label={t('State')} sortBy={'state.value'} translate={t}/>
                <DateField label={t('Created')} source={'createdAt'}/>
                {props.hasEdit && <EditButton/>}
            </Datagrid>
        </List>);
}

export default ContactList;
