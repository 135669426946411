import React from 'react';
import {useTranslation} from 'react-i18next';

const MessageTitle = ({record: {name}}) => {
    const {t} = useTranslation();

    return (
        <span>{t('Message # %{name}', {name})}</span>
    );
}

export default MessageTitle;