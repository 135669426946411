import {put, select, takeLatest} from 'redux-saga/effects';
import {REFRESH_VIEW} from 'react-admin/'
import {
    SMTP_STATISTICS_FETCH_START,
    SMTP_STATISTICS_MONTH_NEXT,
    SMTP_STATISTICS_MONTH_PREVIOUS
} from '../actions/actionTypes';
import API from '../api';
import {smtpStatisticsFetchFailed, smtpStatisticsFetchSuccess} from '../actions/smtpActions';
import {smtpStatisticsSelectMonth, smtpStatisticsSelectYear} from '../selectors/smtpSelectors';
import {getLocation} from '../selectors/routerSelector';

function* fetchSmtpStatistics() {
    const year = yield  select(smtpStatisticsSelectYear);
    const month = yield select(smtpStatisticsSelectMonth);
    const response = yield API.smtpStatistics(year, month);
    const {status, json :{data}} = response;

    if (status !== 200)
        yield put(smtpStatisticsFetchFailed());

    yield put(smtpStatisticsFetchSuccess(year, month, data));
}

function* refreshDashboard() {
    const location = yield select(getLocation);
    const path = location.pathname;

    if (path !== '/home')
        return;

    yield fetchSmtpStatistics();
}

export function* smtpSagas() {
    yield takeLatest(SMTP_STATISTICS_FETCH_START, fetchSmtpStatistics);
    yield takeLatest(SMTP_STATISTICS_MONTH_NEXT, fetchSmtpStatistics);
    yield takeLatest(SMTP_STATISTICS_MONTH_PREVIOUS, fetchSmtpStatistics);
    yield takeLatest(REFRESH_VIEW, refreshDashboard);
}