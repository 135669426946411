import React from 'react';
import {Public, VpnLock}  from '@material-ui/icons/';

const MessageIcons = ({record}) => (
    <React.Fragment>
        {record['publishState'] === 'published' && <Public color={'primary'}/>}
        {record['publishState'] === 'not-published' && <VpnLock color={'primary'}/>}
    </React.Fragment>
);

export default MessageIcons;