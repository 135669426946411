import {
    TAG_DIALOG_TOGGLE_OPEN, TAG_LABEL_CHANGE,
    TAG_SAVE, TAG_SAVE_ERROR, TAG_SAVE_SUCCESS,
} from './actionTypes';

export const tagDialogToggleOpen = (open) => ({
    type: TAG_DIALOG_TOGGLE_OPEN,
    payload: {
        open,
    },
});

export const tagLabelChange = (label) => ({
    type: TAG_LABEL_CHANGE,
    payload: {
        label,
    },
});

export const tagSave = (label) =>({
    type: TAG_SAVE,
    payload: {
        label,
    },
});

export const tagSaveError = () => ({ type: TAG_SAVE_ERROR });

export const tagSaveSuccess = (tag) => ({
    type: TAG_SAVE_SUCCESS,
    payload: {
        tag,
    },
});
