import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
} from 'react-admin'
import {Field} from 'redux-form';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import JoditEditorInput from '../common/JoditEditorInput';
import MessageEditAction from './MessageEditActions';
import MessageEditLinks from './MessageEditLinks';
import TopolContainer from './TopolContainer';
import MessageEditToolbar from './MessageEditToolbar';
import MessageTitle from './MessageTitle';
import MessageTextField from './MessageTextField';
import {Web, TextFields, Public} from '@material-ui/icons/';
import MessageSwitch from './MessageSwitch';
import {useTranslation} from 'react-i18next';
import {withStyles} from '@material-ui/core/styles';
import {getResponseUrl} from '../../util/entrypoint';

const MessageEdit = ({builder, change, classes, handleLinksTabClicked, message, messageSaving = true, toggleEnabled, ...other} ) => {
    const {t} = useTranslation();

    const publishLabel = (message) => {
        return message.privacy === 'private'
            ? t('Message is ready for sending in campaigns')
            : t('Publish landing page');
    }

    const isLandingPage = (message) => message.privacy === 'public';
    const isPublished = (message) => message.publishState === 'published';

    return (
        <Edit
            {...other}
            actions={<MessageEditAction id={message.id} messageSaving={messageSaving}/>}
            undoable={false}
            title={<MessageTitle/>}
        >
            <TabbedForm
                redirect={'/messages'}
                toolbar={<MessageEditToolbar/>}
            >
                <FormTab label={'HTML'} disabled={messageSaving}>
                    <MessageTextField
                        error={false}
                        field={'name'}
                        label={t('Name')}
                        required={true}
                    />
                    {isLandingPage(message) &&
                    <MessageTextField
                        error={false}
                        field={'title'}
                        label={t('Landing page title')}
                    />}
                    {message.builder === 'html' &&
                    <JoditEditorInput
                        input={{
                            onChange: (value) => change('html', value),
                            value: message.html
                        }}
                    />}
                    {message.builder === 'structured' &&
                    <Field name={'html'} component={TopolContainer} value={message.html}/>}

                    <MessageSwitch
                        field={'privacy'}
                        format={(value) => value ? 'public' : 'private'}
                        icon={<Web/>}
                        label={t('Use this message as a landing page')}
                        parse={(value) => value === 'public'}
                    />

                    {!isLandingPage(message) &&
                        <MessageSwitch
                            field={'hasTextVariant'}
                            icon={<TextFields/>}
                            label={t('This message has a text variant')}
                        />
                    }
                    <MessageSwitch
                        field={'publishState'}
                        format={(value) => value ? 'published' : 'not-published'}
                        parse={(value) => value === 'published'}
                        icon={<Public/>}
                        label={publishLabel(message)}
                    />

                    {isLandingPage(message) && isPublished(message) &&
                    <div className={classes.notification}>
                        <div style={{border: '0px solid red', display: 'flex', alignItems: 'center'}}>
                            <Typography className={classes.paddingRight} variant={'body1'} paragraph={false}>{`${t('Landing page URL:')} `}</Typography>
                            <Typography variant={'body1'} paragraph={false}>
                                <a  className={classes.link} color={'black'} href={`${getResponseUrl()}/page-${message.id}`} target={'_blank'}>
                                    {`${getResponseUrl()}/page-${message.id}`}
                                </a>
                            </Typography>
                        </div>

                    </div>
                    }
                </FormTab>
                {!isLandingPage(message) && message.hasTextVariant &&
                <FormTab label={t('Text')} disabled={messageSaving}>
                    <MessageTextField
                        error={false}
                        field={'text'}
                        label={t('Text')}
                        multiline={true}
                        required={true}
                        rows={25}
                    />
                </FormTab>}
                {!isLandingPage(message) &&
                <FormTab
                    disabled={messageSaving}
                    label={t('Links')}
                    onClick={(event) => handleLinksTabClicked(event)}
                >
                    {message.links &&
                    <MessageEditLinks
                        links={message.links}
                        toggleEnabled={toggleEnabled}
                    />}
                </FormTab>}
            </TabbedForm>
        </Edit>
    );
};

MessageEdit.propTypes = {
    change: PropTypes.func.isRequired,
    handleLinksTabClicked: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
    messageSaving: PropTypes.bool.isRequired,
    toggleEnabled: PropTypes.func.isRequired,
};

export const styles = () => ({
    link: {
        color: 'black',
        textDecoration: 'none',
        '&:hover': {
            color: '#ff6f61',
        }
    },
    paddingRight: {
        padding: '0.25rem',
    },
    notification: {
        backgroundColor: '#cfe2ff',
        border: '1px solid #b6d4fe',
        borderRadius: '.25rem',
        color: '#084298',
        display: 'inline-block',
        marginBottom: '1rem',
        marginTop: '1rem',
        padding: '1rem 1rem',
        position: 'relative',
        width: 'calc(100% - 2rem)',
    }
});

export default withStyles(styles)(MessageEdit);
