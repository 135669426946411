import {fetchUtils} from 'react-admin';

export const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('token');

    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/ld+json'
        });
    }

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, options);
};
