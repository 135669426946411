import React from 'react';
import Proptypes from 'prop-types';
import {connect} from 'react-redux';
import SignupButton from './SignupButton';
import {signupSelectUiIsVisible} from '../../selectors/signupSelector';
import SignupDialog from './SignupDialog';

const Signup = ({group, visible}) => (
    <React.Fragment>
        <SignupButton group={group}/>
        {visible && <SignupDialog/>}
    </React.Fragment>
);

Signup.propTypes = {
    group: Proptypes.string.isRequired,
    visible: Proptypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    visible: signupSelectUiIsVisible(state),
});

export default connect(mapStateToProps)(Signup);