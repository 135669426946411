import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin'
import {email} from 'react-admin';
import validation from './validation';
import {withTranslation} from 'react-i18next';

const validateEmail = email();

const SenderCreate = ({t, ...props}) => (
    <Create {...props} title={t('Create sender')}>
        <SimpleForm redirect={'/campaign/senders'} validate={validation}>
            <TextInput source={'name'} label={t('Name')} autoFocus fullWidth/>
            <TextInput source={'email'} label={t('Email')} validate={validateEmail} fullWidth/>
        </SimpleForm>
    </Create>
);

export default withTranslation()(SenderCreate);