import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {CardActions as BaseCardActions, CreateButton} from 'react-admin';
import ImportButton from './ContactImportButton';
import SignupContainer from '../signup/SignupContainer';
import {GroupHelper} from '../../util/GroupHelper';
import {isAccountOverContactLimit} from '../../selectors/accountSelectors';


class ContactCardActions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            group: GroupHelper.getContactListGroupId()
        };
    }

    handleClick = (e) => {
        e.preventDefault();

        const {group} = this.state;
        const {goToCreate} = this.props;

        goToCreate(group);
    };

    render() {
        const {
            bulkActions,
            basePath,
            displayedFilters,
            filters,
            filterValues,
            isOverContactLimit,
            onUnselectItems,
            resource,
            selectedIds,
            showFilter,
        } = this.props;
        const {group} = this.state;

        return (<BaseCardActions>
                {bulkActions && React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems,
                })}
                {filters && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
                {!isOverContactLimit && <CreateButton basePath={basePath} onClick={(e) => this.handleClick(e)}/>}
                {!isOverContactLimit && <ImportButton/>}
                <SignupContainer group={group}/>
            </BaseCardActions>
        );
    }
}

const mapStateToProps = (state) => ({
    isOverContactLimit: isAccountOverContactLimit(state),
});

const mapDispatchToProps = (dispatch) => ({
    goToCreate: (group) => dispatch(push(`/contacts/create?group=${group}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactCardActions);