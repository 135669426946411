import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    messageSave,
    messageShowInBrowser,
    messageTopolSaving
} from '../../actions/messageActions';
import {
    selectIsMessageSaving,
    selectMessageSaveAction,
    selectMessageType
} from '../../selectors/messageSelectors';
import API from '../../api';
import MessagePreview from './MessagePreview';

class MessagePreviewContainer extends Component {
    state = {
        device: 'computer',
        open: false,
        loading: true,
        source: '',
    };

    open = () => this.setState({open: true});
    close = () => this.setState({open: false});

    displayLikeComputer = () => this.setState({device: 'computer'});
    displayLikePhone = () => this.setState({device: 'phone'});
    toggleDisplayDevice = () => {
        const {device} = this.state;

        this.setState({device: device === 'computer' ? 'phone' : 'computer'});
    };

    handlePreview = () => {
        const {messageShowInBrowser, messageSave, messageTopolSaving, saveBeforePreview = true, type} = this.props;

        if (!saveBeforePreview) {
            this.loadPreview().then();

            return;
        }

        messageShowInBrowser();
        if (type === 'structured') {
            messageTopolSaving();
        } else {
            messageSave();
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {action, id, messageSaving} = this.props;

        if (action === 'show-in-browser' && messageSaving === true && nextProps.messageSaving === false) {
            if (id) {
                this.loadPreview().then();
            }
        }

        if (id !== nextProps.id) {
            this.setState({
                loading: true,
                source: ''
            });
        }

        return true;
    }

    loadPreview = async () => {
        const {id} = this.props;
        const {json} = await API.messageGetPreview(id);

        this.setState({
            loading: false,
            source: json.html,
        });
    };

    render() {
        const {device, open, loading, source} = this.state;
        const {button = true, id, saving} = this.props;

        return <MessagePreview
            button={button}
            device={device}
            disabled={id === null || saving}
            handlePreview={this.handlePreview}
            handleToggleDevice={this.toggleDisplayDevice}
            onClose={this.close}
            onDisplayLikeComputer={this.displayLikeComputer}
            onDisplayLikePhone={this.displayLikePhone}
            onOpen={this.open}
            open={open}
            loading={loading}
            saving={saving}
            source={source}
        />;
    }
}

MessagePreviewContainer.propTypes = {
    button: PropTypes.bool,
    id: PropTypes.string,
    saveBeforePreview: PropTypes.bool,
    saving: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    action: selectMessageSaveAction(state),
    saving: selectIsMessageSaving(state),
    type: selectMessageType(state),
});

const mapDispatchToProps = (dispatch) => ({
    messageShowInBrowser: () => dispatch(messageShowInBrowser()),
    messageTopolSaving: () => dispatch(messageTopolSaving('show-in-browser')),
    messageSave: () => dispatch(messageSave('show-in-browser')),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagePreviewContainer);