import React from 'react';
import {
    Filter,
    DateInput,
    TextInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import {useTranslation} from 'react-i18next';

const MessageFilter = (props) => {
    const {t} = useTranslation();

    return (
        <Filter {...props}>
            <ReferenceInput
                label={t('Sender')}
                source={'sender'}
                reference={'campaign/senders'}
                resettable
                alwaysOn
            >
                <SelectInput optionText={'headerValue'}/>
            </ReferenceInput>
            <TextInput
                autoFocus
                label={t('To')}
                source={'to'}
                resettable
                alwaysOn
            />
            <TextInput
                autoFocus
                label={t('Subject')}
                source={'subject'}
                resettable
                alwaysOn
            />
            <DateInput
                label={t('Date')}
                source={'sentOn'}
                alwaysOn
            />
        </Filter>
    );
}

export default MessageFilter;
