import React from 'react';

const ContactStateField = ({record: {state}, translate: t}) => {
    const translator = {
        'active': t('active'),
        'blacklisted': t('blacklisted'),
        'deleted': t('deleted'),
        'unsubscribed': t('unsubscribed'),
        'bounced-out': t('bounced-out'),
        'signed-up-not-confirmed': t('signed-up-not-confirmed'),
    };

    return <span>{translator[state]}</span>;
}

export default ContactStateField;
