import React from 'react';
import {
    CardActions,
    CreateButton,
    Datagrid,
    DateField,
    List,
    ReferenceField,
    TextField,
} from 'react-admin';

const SubscriptionListActions = () => {
    return (
        <CardActions>
            <CreateButton basePath={'/admin/user/subscriptions/create'}/>
        </CardActions>
    );
}

const rowStyle = (record) => {
    return {
        backgroundColor: record.expired ? '#ececec' : 'transparent',
    };
};

export const SubscriptionList = (props) => {


    return (
        <List {...props} sort={{field: 'expiresOn', order: 'DESC'}} bulkActionButtons={false} actions={<SubscriptionListActions/>}>
            <Datagrid rowStyle={rowStyle}>
                <ReferenceField label={'Product'} source={'product_id'} reference={'admin/products'} linkType={false}>
                    <TextField source={'name'}/>
                </ReferenceField>
                <DateField source='startsOn' locales={'fr-FR'}/>
                <DateField source='expiresOn' locales={'fr-FR'}/>
            </Datagrid>
        </List>
    );
}

export default SubscriptionList;