import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import ReadIcon from '@material-ui/icons/Camera';
import ClickIcon from '@material-ui/icons/TouchApp';
import UnsubscribeIcon from '@material-ui/icons/SentimentDissatisfied';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import green from '@material-ui/core/colors/blue';
import {withTranslation} from 'react-i18next';
import CardWithBackground from "../common/CardWithBackground";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: green[500],
    },
}));

const DashboardRatiosCard = (props) => {
    const classes = useStyles();
    const {
        clickCount,
        clickRatio,
        openCount,
        openRatio,
        statsLoading,
        t,
        totalCampaignsSent,
        totalContactsSent,
        unsubscribeCount,
        unsubscribeRatio
    } = props;

    const subheader = statsLoading ? 'loading' : t('%{totalContactsSent} contacts sent in %{totalCampaignsSent} campaigns', {totalContactsSent, totalCampaignsSent});
    return (
        <CardWithBackground>
            <CardHeader avatar={
                <Avatar aria-label={t('Ratios')} className={classes.avatar}>R</Avatar>}
                        title={t('Ratios')} subheader={subheader}/>
            {!statsLoading &&
                <CardContent>
                    <List>
                        <ListItem>
                            <Avatar>
                                <ReadIcon />
                            </Avatar>
                            <ListItemText primary={t('Read: %{openRatio}%', {openRatio})} secondary={t('%{count} contacts', {count: openCount})}/>
                        </ListItem>
                        <ListItem>
                            <Avatar>
                                <ClickIcon />
                            </Avatar>
                            <ListItemText primary={t('Click: %{clickRatio}%', {clickRatio})} secondary={t('%{count} contacts', {count: clickCount})}/>
                        </ListItem>
                        <ListItem>
                            <Avatar>
                                <UnsubscribeIcon />
                            </Avatar>
                            <ListItemText primary={t('Unsubscribe: %{unsubscribeRatio}%', {unsubscribeRatio})} secondary={t('%{count} contacts', {count: unsubscribeCount})}/>
                        </ListItem>
                    </List>
                </CardContent>
            }
            {statsLoading && <LinearProgress />}
        </CardWithBackground>
    );
};

DashboardRatiosCard.propTypes = {
    clickCount: PropTypes.number.isRequired,
    clickRatio: PropTypes.number.isRequired,
    openCount: PropTypes.number.isRequired,
    openRatio: PropTypes.number.isRequired,
    statsLoading: PropTypes.bool.isRequired,
    totalCampaignsSent: PropTypes.number.isRequired,
    totalContactsSent: PropTypes.number.isRequired,
    unsubscribeCount: PropTypes.number.isRequired,
    unsubscribeRatio: PropTypes.number.isRequired,
};

export default withTranslation()(DashboardRatiosCard);
