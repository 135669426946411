import React from 'react';
import {
    SimpleForm,
    TextInput,
} from 'react-admin'
import validateForm from './validation';
import {useTranslation} from 'react-i18next';

const AttributeForm = (props) => {
    const {t} = useTranslation();

    return (
        <SimpleForm {...props} redirect={'/attributes'} validate={validateForm}>
            <TextInput source={'name'} label={t('Name')} autoFocus fullWidth/>
        </SimpleForm>
    );
}

export default AttributeForm;
