import {
    DASHBOARD_CAMPAIGNS_SCHEDULED_SET,
    DASHBOARD_CAMPAIGNS_SENT_SET,
    DASHBOARD_MESSAGES_SET
} from '../actions/actionTypes';

const initialState = {
    campaignsScheduled: [],
    campaignsScheduledLoading: true,
    campaignsSent: [],
    campaignsSentLoading: true,
    messages: [],
    messagesLoading: true,
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_CAMPAIGNS_SCHEDULED_SET:
            return Object.assign({}, state, {
                campaignsScheduled: action.payload.campaigns,
                campaignsScheduledLoading: false,
            });

        case DASHBOARD_CAMPAIGNS_SENT_SET:
            return Object.assign({}, state, {
                campaignsSent: action.payload.campaigns,
                campaignsSentLoading: false,
            });

        case DASHBOARD_MESSAGES_SET:
            return Object.assign({}, state, {
                messages: action.payload.messages,
                messagesLoading: false,
            });

        default:
            return state;
    }
}
