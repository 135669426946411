import ldjsonServer from '../ldjsonServer';
import {entrypoint} from '../../config/config';
import {httpClient} from '../httpClient';
import {decoratedDataProvider} from './decorator/decoratedDataProvider';

export const dataProvider = decoratedDataProvider(
    ldjsonServer(
        entrypoint,
        httpClient
    )
);