export const ACCOUNT_GET_INFO = 'ACCOUNT_GET_INFO';
export const ACCOUNT_GET_STATS = 'ACCOUNT_GET_STATS';
export const ACCOUNT_RESET_PASSWORD = 'ACCOUNT_RESET_PASSWORD';
export const ACCOUNT_SET_ATTRIBUTES = 'ACCOUNT_SET_ATTRIBUTES';

export const ARCHIVE_RECORD = 'ARCHIVE_RECORD';
export const RESTORE_RECORD = 'RESTORE_RECORD';

export const CAMPAIGN_CHANGE = 'CAMPAIGN_CHANGE';
export const CAMPAIGN_DISPATCHED = 'CAMPAIGN_DISPATCH';
export const CAMPAIGN_LAUNCH = 'CAMPAIGN_LAUNCH';
export const CAMPAIGN_SET_SEND_METHOD = 'CAMPAIGN_SET_SEND_METHOD';
export const CAMPAIGN_UNIQUE_CONTACTS_COUNTED = 'CAMPAIGN_UNIQUE_CONTACTS_COUNTED';
export const CAMPAIGN_UNIQUE_CONTACT_COUNT_FETCH = 'CAMPAIGN_UNIQUE_CONTACT_COUNT_FETCH';
export const CAMPAIGN_REPORT_FETCHED = 'CAMPAIGN_REPORT_FETCHED';
export const CAMPAIGN_REPORT_FETCHING = 'CAMPAIGN_REPORT_FETCHING';
export const CAMPAIGN_REPORT_LIST_FETCHED = 'CAMPAIGN_REPORT_LIST_FETCHED';
export const CAMPAIGN_REPORT_LIST_FETCHING = 'CAMPAIGN_REPORT_LIST_FETCHING';
export const CAMPAIGN_REPORT_LIST_CHANGE_PAGE = 'CAMPAIGN_REPORT_LIST_CHANGE_PAGE';
export const CAMPAIGN_REPORT_LIST_SET_CAMPAIGN_ID = 'CAMPAIGN_REPORT_LIST_SET_CAMPAIGN_ID';
export const CAMPAIGN_REPORT_LIST_SET_EVENT = 'CAMPAIGN_REPORT_LIST_SET_EVENT';
export const CAMPAIGN_REPORT_LIST_SET_ROWS_PER_PAGE = 'CAMPAIGN_REPORT_LIST_SET_ROWS_PER_PAGE';
export const CAMPAIGN_WIZARD_INITIALIZE = 'CAMPAIGN_WIZARD_INITIALIZE';
export const CAMPAIGN_WIZARD_FETCH_GROUPS = 'CAMPAIGN_WIZARD_FETCH_GROUPS';
export const CAMPAIGN_WIZARD_FETCH_MESSAGES = 'CAMPAIGN_WIZARD_FETCH_MESSAGES';
export const CAMPAIGN_WIZARD_FETCH_SENDERS = 'CAMPAIGN_WIZARD_FETCH_SENDERS';
export const CAMPAIGN_WIZARD_FETCH_TAGS = 'CAMPAIGN_WIZARD_FETCH_TAGS';
export const CAMPAIGN_WIZARD_SAVE = 'CAMPAIGN_WIZARD_SAVE';
export const CAMPAIGN_WIZARD_SAVE_SUCCESS = 'CAMPAIGN_WIZARD_SAVE_SUCCESS';
export const CAMPAIGN_WIZARD_SAVE_ERROR = 'CAMPAIGN_WIZARD_SAVE_ERROR';
export const CAMPAIGN_WIZARD_SET_GROUPS = 'CAMPAIGN_WIZARD_SET_GROUPS';
export const CAMPAIGN_WIZARD_SET_MESSAGES = 'CAMPAIGN_WIZARD_SET_MESSAGES';
export const CAMPAIGN_WIZARD_SET_SENDERS = 'CAMPAIGN_WIZARD_SET_SENDERS';
export const CAMPAIGN_WIZARD_SET_TAGS = 'CAMPAIGN_WIZARD_SET_TAGS';
export const CAMPAIGN_WIZARD_SET_ATTRIBUTES = 'CAMPAIGN_WIZARD_SET_ATTRIBUTES';
export const CAMPAIGN_WIZARD_SET_CAMPAIGNS = 'CAMPAIGN_WIZARD_SET_CAMPAIGNS';

export const CAMPAIGN_WIZARD_SET_FILTERS = 'CAMPAIGN_WIZARD_SET_FILTERS';
export const CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECT = 'CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECT';
export const CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECTED = 'CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECTED';
export const CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCH = 'CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCH';
export const CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCHED = 'CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCHED';

export const CONTACT_IMPORT_REPORT_CLOSE = 'CONTACT_IMPORT_REPORT_CLOSE';
export const CONTACT_IMPORT_REPORT_DOWNLOAD = 'CONTACT_IMPORT_REPORT_DOWNLOAD';
export const CONTACT_IMPORT_REPORT_DOWNLOAD_DONE = 'CONTACT_IMPORT_REPORT_DOWNLOAD_DONE';

export const CONTACT_IMPORT_TRANSACTION_COMPLETED = 'CONTACT_IMPORT_TRANSACTION_COMPLETED';
export const CONTACT_IMPORT_TRANSACTION_CREATE = 'CONTACT_IMPORT_TRANSACTION_CREATE';
export const CONTACT_IMPORT_TRANSACTION_CREATED = 'CONTACT_IMPORT_TRANSACTION_CREATED';
export const CONTACT_IMPORT_TRANSACTION_IMPORTING = 'CONTACT_IMPORT_TRANSACTION_IMPORTING';
export const CONTACT_IMPORT_TRANSACTION_IMPORTED = 'CONTACT_IMPORT_TRANSACTION_IMPORTED';
export const CONTACT_IMPORT_TRANSACTION_RESET_STATE = 'CONTACT_IMPORT_TRANSACTION_RESET_STATE';
export const CONTACT_IMPORT_TRANSACTION_SET_ATTRIBUTES = 'CONTACT_IMPORT_TRANSACTION_SET_ATTRIBUTES';
export const CONTACT_IMPORT_TRANSACTION_SET_DATA = 'CONTACT_IMPORT_TRANSACTION_SET_DATA';
export const CONTACT_IMPORT_TRANSACTION_SET_FILENAME = 'CONTACT_IMPORT_TRANSACTION_SET_FILENAME';
export const CONTACT_IMPORT_TRANSACTION_SET_MAP = 'CONTACT_IMPORT_TRANSACTION_SET_MAP';
export const CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_MESSAGE = 'CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_MESSAGE';
export const CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_VALUE = 'CONTACT_IMPORT_TRANSACTION_SET_PROGRESS_VALUE';
export const CONTACT_IMPORT_TRANSACTION_SET_STEP = 'CONTACT_IMPORT_TRANSACTION_SET_STEP';
export const CONTACT_IMPORT_TRANSACTION_TOGGLE_UI = 'CONTACT_IMPORT_TRANSACTION_TOGGLE_UI';

export const CONTACT_LIST_SET_GROUP = 'CONTACT_LIST_SET_GROUP';

export const DASHBOARD_MESSAGES_SET = 'DASHBOARD_MESSAGES_SET';
export const DASHBOARD_CAMPAIGNS_SENT_SET = 'DASHBOARD_CAMPAIGNS_SENT_SET';
export const DASHBOARD_CAMPAIGNS_SCHEDULED_SET = 'DASHBOARD_CAMPAIGNS_SCHEDULED_SET';

export const GROUP_CONTACTS_BUTTON_CLICKED = 'GROUP_CONTACTS_BUTTON_CLICKED';

export const MESSAGE_CHANGE = 'MESSAGE_CHANGE';
export const MESSAGES_DESTROY = 'MESSAGES_DESTROY';
export const MESSAGE_LINK_TOGGLE_ENABLED = 'MESSAGE_LINK_TOGGLE_ENABLED';
export const MESSAGE_PREVIEW_HIDE = 'MESSAGE_PREVIEW_HIDE';
export const MESSAGE_PREVIEW_SHOW = 'MESSAGE_PREVIEW_SHOW';
export const MESSAGE_SAVE = 'MESSAGE_SAVE';
export const MESSAGE_SAVED = 'MESSAGE_SAVED';
export const MESSAGE_SHOW_IN_BROWSER = 'MESSAGE_SHOW_IN_BROWSER';
export const MESSAGE_SEND_PREVIEW_TO = 'MESSAGE_SEND_PREVIEW_TO';
export const MESSAGE_SET_LINKS = 'MESSAGE_SET_LINKS';
export const MESSAGE_TOPOL_SAVED = 'MESSAGE_TOPOL_SAVED';
export const MESSAGE_TOPOL_SAVING = 'MESSAGE_TOPOL_SAVING';
export const MESSAGE_DUPLICATE = 'MESSAGE_DUPLICATE';

export const PUSHER_MESSAGE_RECEIVED = 'PUSHER_MESSAGE_RECEIVED';

export const SIGNUP_FETCH_FORM = 'SIGNUP_FETCH_FORM';
export const SIGNUP_FETCH_GROUPS = 'SIGNUP_FETCH_GROUPS';
export const SIGNUP_FETCH_SENDERS = 'SIGNUP_FETCH_SENDERS';
export const SIGNUP_FORM_CHANGE = 'SIGNUP_FORM_CHANGE';
export const SIGNUP_HIDE_REQUESTED = 'SIGNUP_HIDE_REQUESTED';
export const SIGNUP_HIDE = 'SIGNUP_HIDE';
export const SIGNUP_SAVE = 'SIGNUP_SAVE';
export const SIGNUP_SAVED = 'SIGNUP_SAVED';
export const SIGNUP_SET_FORM = 'SIGNUP_SET_FORM';
export const SIGNUP_SET_GROUP = 'SIGNUP_SET_GROUP';
export const SIGNUP_SET_SENDERS = 'SIGNUP_SET_SENDERS';
export const SIGNUP_SHOW = 'SIGNUP_SHOW';
export const SIGNUP_SHOW_CANCEL_CONFIRMATION = 'SIGNUP_SHOW_CANCEL_CONFIRMATION';

export const SMTP_STATISTICS_FETCH_START = 'SMTP_STATISTICS_FETCH_START';
export const SMTP_STATISTICS_FETCH_SUCCESS = 'SMTP_STATISTICS_FETCH_SUCCESS';
export const SMTP_STATISTICS_FETCH_FAILED = 'SMTP_STATISTICS_FETCH_FAILED';
export const SMTP_STATISTICS_DAY_NEXT = 'SMTP_STATISTICS_DAY_NEXT';
export const SMTP_STATISTICS_DAY_PREVIOUS = 'SMTP_STATISTICS_DAY_PREVIOUS';
export const SMTP_STATISTICS_MONTH_NEXT = 'SMTP_STATISTICS_MONTH_NEXT';
export const SMTP_STATISTICS_MONTH_PREVIOUS = 'SMTP_STATISTICS_MONTH_PREVIOUS';

export const TAG_DIALOG_TOGGLE_OPEN = 'TAG_DIALOG_TOGGLE_OPEN';
export const TAG_LABEL_CHANGE = 'TAG_LABEL_CHANGE';
export const TAG_SAVE = 'TAG_SAVE';
export const TAG_SAVE_SUCCESS = 'TAG_SAVE_SUCCESS';
export const TAG_SAVE_ERROR = 'TAG_SAVE_ERROR';

export const UI_AUTH_DIALOG_SHOW = 'UI_AUTH_DIALOG_SHOW';
export const UI_AUTH_DIALOG_SET_INTERVAL = 'UI_AUTH_DIALOG_SET_INTERVAL';
export const UI_BLACKLIST_IMPORT_DIALOG_HIDE = 'UI_BLACKLIST_IMPORT_DIALOG_HIDE';
export const UI_BLACKLIST_IMPORT_DIALOG_SHOW = 'UI_BLACKLIST_IMPORT_DIALOG_SHOW';
export const UI_CONTACT_LIST_SET_LIST_NAME = 'UI_CONTACT_LIST_SET_LIST_NAME';

export const ADMIN_SET_USER = 'ADMIN_SET_USER';
