import {resolveBrowserLocale} from 'react-admin';

export const getLocale = () => {
    const browserLocale = resolveBrowserLocale();

    if (browserLocale === 'nl') {
        return 'nl';
    }

    return 'en';
}
