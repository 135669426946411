import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import API from "../../api";
import {localize} from "../../util/date";
import {useTranslation} from 'react-i18next';

export const MessageDetails = ({id, onClose}) => {
    const {t} = useTranslation();
    const [tab, setTab] = useState('html');
    const [data, setData] = useState({});
    const [sources, setSources] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingSources, setLoadingSources] = useState(true);

    async function getMessageDetails() {
        setLoading(true);
        API.smtpMessage(id).then(
            (response) => {
                const {json, status} = response;

                if (status !== 200) {
                    alert(t('Could not fetch message details'));
                    return;
                }
                setData(json);
                setLoading(false);
            }
        );
        API.smtpMessageSource(id).then(
            (response) => {
                const {json, status} = response;

                if (status !== 200) {
                    alert(t('Could not fetch message details'));
                    return;
                }

                setSources(json);
                setLoadingSources(false);
            }
        );
    }

    useEffect(() => {
        getMessageDetails().then();
    }, [id]);

    const {
        bounceEvents = [],
        readEvents = [],
        visitEvents = [],
        from = '',
        sentOn = '',
        subject = '',
        to = '',
    } = data;
    const {
        html = '',
        text = ''
    } = sources;

    const hasEvents = (bounceEvents.length + readEvents.length + visitEvents.length) !== 0;

    return (
        <Dialog open={true} fullWidth={true} maxWidth={'md'} onClose={onClose}>
            <DialogTitle>{t('Subject: %{subject}', {subject})}</DialogTitle>
            {(loading || loadingSources) && <DialogContent>
                <LinearProgress/>
            </DialogContent>}
            {!(loading || loadingSources)  && <DialogContent>
                <Grid container={true}>
                    <Grid item={true} xs={12}>
                        <Typography variant={'title'}/>
                    </Grid>
                </Grid>
                <Typography>{t('To: %{to}', {to})}</Typography>
                <Typography variant={'caption'}>{t('From: %{from} sent on %{sentOn}', {from, sentOn: localize(sentOn)})}</Typography>

                {hasEvents &&
                <React.Fragment>
                    <Typography style={{marginTop: '10px',}}>{t('Events')}</Typography>

                    {readEvents && readEvents.map((read, index) => (
                        <Typography key={index} variant={'caption'}
                                    style={{display: 'inline'}}>{t('%{timestamp} Read (%{ip})', {timestamp: localize(read.timestamp), ip: read.ip})}</Typography>
                    ))}

                    {visitEvents && visitEvents.map((visit, index) => (
                        <Typography key={index}
                                    variant={'caption'}>{t('%{timestamp} Click on %{url} (%{ip})', {timestamp: localize(visit.timestamp), ip: visit.ip, url: visit.url})}</Typography>
                    ))}

                    {bounceEvents && bounceEvents.map((bounce, index) => (
                        <Typography key={index}
                                    variant={'caption'}>{t('%{timestamp} Bounced with code: %{code}', {timestamp: localize(bounce.timestamp), code:bounce.code})}</Typography>
                    ))}
                </React.Fragment>
                }


                <Tabs value={tab} onChange={(e, v) => setTab(v)} style={{
                    marginTop: '24px',
                }} indicatorColor={'primary'} textColor={'primary'}>
                    <Tab label={t('HTML')} value={'html'}/>
                    <Tab label={t('Text')} value={'text'}/>
                </Tabs>


                <div>
                    <iframe
                        style={{
                            height: '750px',
                            width: '100%',
                        }}
                        frameBorder={0}
                        src={"data:text/html," + encodeURIComponent(tab === 'text' ? `<pre>${text}</pre>` : html)}
                        title={'message-preview'}
                    />
                </div>
            </DialogContent>}
        </Dialog>
    )
};
