import React from 'react';
import {
    BooleanInput,
    Filter,
    TextInput
} from 'react-admin';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {withTranslation} from 'react-i18next';

const SenderFilter = ({t, ...props}) => (
    <Filter {...props}>
        <TextInput autoFocus label={t('Name')} source="person.name" />
        <TextInput autoFocus label={t('Email')} source="person.email" />
        <BooleanInput label={t('State')}
                      format={v => v === 'verified'}
                      parse={v => v ? 'verified' : 'not-verified'}
                      source="state.value"
                      options={{checkedIcon: <VerifiedUserIcon />}}

        />
    </Filter>
);

export default withTranslation()(SenderFilter);
