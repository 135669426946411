import { GET_LIST } from 'react-admin';

/**
 * @param response
 * @param type
 * @param resource
 * @param params
 * @returns {*}
 */
export const subscriptionNormalizer = (response, type, resource, params) => {
    if (resource !== 'admin/user/subscriptions') {
        return response;
    }

    if (type === GET_LIST) {
        response = addProductIdsFromIri(response);
    }

    return response;
};

/**
 * @param response
 * @returns {*}
 */
const addProductIdsFromIri = (response) => {
    response.json['hydra:member'] = response.json['hydra:member'].map((subscription) => {
        subscription.product_id = subscription.product.split('/').pop();
        return subscription;
    })

    return response;
}
