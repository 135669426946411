import React from 'react';
import {Edit} from 'react-admin';
import AttributeForm from './AttributeForm';
import AttributeTitle from './AttributeTitle';

const AttributeEdit = props => (
    <Edit {...props} title={<AttributeTitle/>}>
        <AttributeForm/>
    </Edit>
);

export default AttributeEdit;