import React from 'react';
import {BulkDeleteButton} from 'react-admin';
import BulkArchiveButton from '../common/Button/BulkArchiveButton';
import BulkRestoreButton from '../common/Button/BulkRestoreButton';

const MessageListBulkActionButtons = (props) => (
    <React.Fragment>
        {!props.filterValues.inArchive && <BulkArchiveButton {...props} />}
        {props.filterValues.inArchive && <BulkRestoreButton {...props} />}
        <BulkDeleteButton {...props}/>
    </React.Fragment>);

export default MessageListBulkActionButtons;