import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#ff8b80',
            main: '#ff6f61',
            dark: '#b24d43',
            contrastText: '#fff',
        },
        secondary: {
            light: '#f3f3f3',
            main: '#f1f1f1',
            dark: '#a8a8a8',
            contrastText: '#000',
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif',
        ]
    },
    overrides: {
        MuiCard: {
            root: {},
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            },
            elevation1: {
                border: '1px solid #eee',
            },
            rounded: {
                borderRadius: '0px',
            }
        },
        MuiList: {
            root: {
                backgroundColor: '#FAFAFA',
            }
        },
    },
});

export default theme;
