import { put, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { REGISTER_FIELD } from 'redux-form/es/actionTypes';
import { change } from 'redux-form';
import { getUrlParams } from '../util/string';
import { contactListSetGroup } from '../actions/contactListActions';
import { GroupHelper } from '../util/GroupHelper';

function* setContactListGroupId(action) {
    const {payload: {pathname, search}} = action;

    if (pathname !== '/contacts' && pathname !== '/contacts/create') {
        return;
    }

    const params = getUrlParams(search);

    let group = {
        id: null,
    };

    if (params.hasOwnProperty('group')) {
        group.id = params.group;
    } else if(params.hasOwnProperty('filter')) {
        const filter = JSON.parse(params.filter);

        if (filter.hasOwnProperty('group')) {
            group.id = filter.group;
        }
    }

    if (group.id !== GroupHelper.getContactListGroupId()) {
        yield put(contactListSetGroup(group));
    }
}
 function* forceGroupFilter(action) {
    const {meta = null, payload = null} = action;

    if (meta === null || payload === null) {
        return;
    }

    const {form = null} = meta;
    const {name = null} = payload;

    if (form === null || form !== 'filterForm' || name !== 'group') {
        return;
    }

    yield put(change( 'filterForm', 'group', GroupHelper.getContactListGroupId(), true, false));
 }

export function* contactListSagas() {
    yield takeEvery(LOCATION_CHANGE, setContactListGroupId);
    yield takeEvery(REGISTER_FIELD, forceGroupFilter);
}