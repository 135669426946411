import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-admin';
import {default as MuiButton} from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core/';
import {Send} from '@material-ui/icons/';
import {useTranslation} from 'react-i18next';

const PreviewButton = ({disabled, onCancel, onChangeEmail, onOpen, onSend, open}) => {
    const {t} = useTranslation();

    return (
        <div>
            <Button
                disabled={disabled}
                label={t('Send preview')}
                onClick={() => onOpen()}
            >
                <Send/>
            </Button>

            <Dialog open={open}>
                <DialogTitle>
                    {t('Send message preview')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('A message preview will be sent to the following email address')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id={'email'}
                        label={t('Email')}
                        type={'email'}
                        onChange={(e) => onChangeEmail(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <MuiButton
                        onClick={() => onSend()}
                        color={'primary'}
                        variant={'contained'}
                    >
                        {t('Send')}
                    </MuiButton>
                    <MuiButton
                        onClick={() => onCancel()}
                        color={'primary'}
                    >
                        {t('Cancel')}
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

PreviewButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChangeEmail: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default PreviewButton;