import React from 'react';
import {Toolbar} from 'react-admin';
import PropTypes from 'prop-types';
import ScheduleIcon from '@material-ui/icons/Today';
import CampaignLaunchDialogContainer from './CampaignLaunchDialogContainer';
import CampaignSaveButton from './CampaignSaveButton';
import CampaignScheduleDialogContainer from "./CampaignScheduleDialogContainer";
import {useTranslation} from 'react-i18next';

const CampaignToolbar = ({
                             displayLaunchDialog,
                             displayScheduleDialog,
                             hideLaunchDialog,
                             hideScheduleDialog,
                             launch,
                             method,
                             recipients,
                             save,
                             schedule,
                             showLaunchDialog,
                             showScheduleDialog,
                             tab,
                             title = '',
                             wizardSaving,
                         }) => {
    const {t} = useTranslation();

    return (
        <Toolbar>
            {tab !== 2 &&
            <CampaignSaveButton
                save={() => save(tab === 1 ? 'send' : null)}
                label={tab === 1 ? t('Next') : t('Save')}
                wizardSaving={wizardSaving}
            />}

            {tab === 2 && method === 'direct' &&
            <React.Fragment>
                <CampaignSaveButton
                    save={() => save('/campaigns')}
                    wizardSaving={wizardSaving}
                />
                <CampaignSaveButton
                    label={t('Send now')}
                    save={() => save(displayLaunchDialog)}
                    wizardSaving={wizardSaving}
                />
            </React.Fragment>}

            {tab === 2 && method === 'scheduled' &&
            <CampaignSaveButton
                save={() => save(displayScheduleDialog)}
                wizardSaving={wizardSaving}
                icon={
                    <ScheduleIcon/>
                }
                label={t('Schedule')}
            />}

            <CampaignLaunchDialogContainer
                hideLaunchDialog={hideLaunchDialog}
                launch={launch}
                open={showLaunchDialog}
                recipients={recipients}
                title={title}
            />
            <CampaignScheduleDialogContainer
                hideScheduleDialog={hideScheduleDialog}
                schedule={schedule}
                open={showScheduleDialog}
                recipients={recipients}
                title={title}
            />
        </Toolbar>);
};

CampaignToolbar.propTypes = {
    displayLaunchDialog: PropTypes.func,
    displayScheduleDialog: PropTypes.func,
    hideLaunchDialog: PropTypes.func,
    hideScheduleDialog: PropTypes.func,
    launch: PropTypes.func,
    method: PropTypes.string,
    recipients: PropTypes.number.isRequired,
    save: PropTypes.func.isRequired,
    schedule: PropTypes.func,
    showLaunchDialog: PropTypes.bool,
    showScheduleDialog: PropTypes.bool,
    tab: PropTypes.number.isRequired,
    wizardSaving: PropTypes.bool.isRequired,
};

export default CampaignToolbar;
