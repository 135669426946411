import produce from 'immer';
import {
    SMTP_STATISTICS_DAY_NEXT,
    SMTP_STATISTICS_DAY_PREVIOUS,
    SMTP_STATISTICS_FETCH_FAILED,
    SMTP_STATISTICS_FETCH_START,
    SMTP_STATISTICS_FETCH_SUCCESS,
    SMTP_STATISTICS_MONTH_NEXT,
    SMTP_STATISTICS_MONTH_PREVIOUS,
} from '../actions/actionTypes';

const initialState = {
    data: {},
    day: new Date().getDate(),
    loaded: false,
    loading: false,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
}
const smtpReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {

            case SMTP_STATISTICS_FETCH_START:
                draft.loaded = false;
                draft.loading = true;
                break;

            case SMTP_STATISTICS_FETCH_FAILED:
                draft.loaded = false;
                draft.loading = false;
                break;

            case SMTP_STATISTICS_FETCH_SUCCESS:
                const {payload: {year, month, data}} = action;

                draft.loaded = true;
                draft.loading = false;
                draft.year = year;
                draft.month = month;
                draft.data = data;
                break;

            case SMTP_STATISTICS_DAY_NEXT:
                draft.day += 1;
                break;

            case SMTP_STATISTICS_DAY_PREVIOUS:
                draft.day -= 1;
                break;

            case SMTP_STATISTICS_MONTH_NEXT:
                draft.day = 1;
                if (draft.month === 12) {
                    draft.month = 1;
                    draft.year += 1;
                } else
                    draft.month += 1;

                break;

            case SMTP_STATISTICS_MONTH_PREVIOUS:
                draft.day = 1;
                if (draft.month === 1) {
                    draft.month = 12;
                    draft.year -= 1;
                } else
                    draft.month -= 1;

                break;

            // no default
        }
    });
};

export default smtpReducer;