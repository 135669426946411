import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import CampaignFilterFilter from './CampaignFilterFilter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {useTranslation} from 'react-i18next';

const CampaignFilterAttributeAttributes = (
    {
        attributes,
        filter: {
            attribute,
            filter: filterType,
            value
        },
        index,
        onUpdate
    }
) => {
    const FILTER_EQUALS = 'equals';
    const FILTER_NOT_EQUALS = 'not-equals';
    const FILTER_CONTAINS = 'contains';

    const {t} = useTranslation();

    const attributeUpdateHandler = (event) => onUpdate(index, {attribute: event.target.value});
    const filterTypeUpdateHandler = (filter) => onUpdate(index, {filter});
    const valueUpdateHandler = (event) => onUpdate(index, {value: event.target.value});

    const mapAttributes = () => attributes.data.map(
        (attribute, index) => <MenuItem key={index} value={attribute['@id']}>{attribute.name}</MenuItem>);

    return (
        <>
            {attributes.loaded &&
                <div style={{marginRight: '20px'}}>
                    <FormControl>
                        <InputLabel htmlFor='type'>{t('Contact Attributes')}</InputLabel>
                        <Select
                            value={attribute}
                            onChange={attributeUpdateHandler}
                            inputProps={{
                                name: 'attribute',
                                id: 'attribute',
                            }}
                            style={{
                                minWidth: '250px'
                            }}
                        >
                            {mapAttributes()}
                        </Select>
                    </FormControl>
                </div>}
            <CampaignFilterFilter value={filterType} onChange={filterTypeUpdateHandler}>
                <MenuItem value={FILTER_EQUALS}>{t('Equals')}</MenuItem>
                <MenuItem value={FILTER_NOT_EQUALS}>{t('Not equals')}</MenuItem>
                <MenuItem value={FILTER_CONTAINS}>{t('Contains')}</MenuItem>
            </CampaignFilterFilter>
            <TextField id={'value'}
                       label={t('Value')}
                       style={{width: '100%'}}
                       value={value}
                       onChange={valueUpdateHandler}
            />
        </>
    );
}

CampaignFilterAttributeAttributes.propType = {
    filter: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onUpdate: PropTypes.func.isRequired,
}

CampaignFilterAttributeAttributes.propTypes = {
    filter: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    attributes: state.campaign.wizard.attributes,
});

export default connect(mapStateToProps)(CampaignFilterAttributeAttributes);
