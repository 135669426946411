import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {hideNotification} from 'react-admin';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {translate} from 'ra-core';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const styles = theme => ({
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: '#d32f2f',
    },
    info: {
        backgroundColor: '#1976d2',
    },
    warning: {
        backgroundColor: amber[700],
    },
});

class Notifications extends React.Component {
    state = {
        open: false,
        messageInfo: {},
    };


    handleClose = (event, reason) => {
        this.props.hideNotification();

        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false});
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.notifications.length > 1) {
            this.props.hideNotification();
        }

        this.setState({open: true});
    }

    render() {
        const {classes, translate, notifications} = this.props;
        const notification = notifications.slice(0, 1)[0];

        if (notification) {
            const className = (notification.type) ? classes[notification.type] : '';

            return (
                <div>
                    <Snackbar
                        key={new Date().getMilliseconds()}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={60000}
                        onClose={() => this.handleClose()}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}

                    >
                        <SnackbarContent
                            className={className}
                            message={<span id="message-id">{translate(notification.message)}</span>}
                            action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleClose}
                            >
                                <CloseIcon/>
                            </IconButton>,
                            ]}/>
                    </Snackbar>
                </div>
            );
        } else {
            return (<React.Fragment/>);
        }
    }
}

Notifications.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        notifications: state.admin.notifications
    }
};

const mapDispatchToProps = (dispatch) => ({
    hideNotification: () => dispatch(hideNotification()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(translate(Notifications)));
