import React from 'react';
import { refreshView } from 'react-admin';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import ContactImportReport from './ContactImportReport';
import {
    contactImportReportClose,
    contactImportReportDownload
} from '../../actions/contactImportReportActions';
import { idSelector } from '../../selectors/contactImportSelectors';
import { contactImportTransactionToggleDialog } from '../../actions/contactImportActions';
import {GroupHelper} from "../../util/GroupHelper";

class ContactImportReportContainer extends React.Component {
    /**
     * If the user did not close the import wizard, close it
     */
    closeUnderlyingImportWizard = () => {
        const {activeTransactionId, onCloseImportWizard, transaction} = this.props;

        if (activeTransactionId && transaction === activeTransactionId) {
            onCloseImportWizard()
        }
    };

    /**
     * if view the contact list for which a report has been received, refresh the view
     */
    refreshGroup = () => {
        const {activeGroupId, groupId, refreshGroup} = this.props;

        if (activeGroupId && groupId === activeGroupId) {
            refreshGroup();
        }
    };

    componentDidUpdate = () => {
        this.closeUnderlyingImportWizard();
        this.refreshGroup();
    };

    render = () => <ContactImportReport {...this.props} />;
}

const mapStateToProps = (state) => {
    return {
        contactsCreated: state.importReport.contactsCreated,
        contactsUpdated: state.importReport.contactsUpdated,
        downloading: state.importReport.downloading,
        filename: state.importReport.filename,
        group: state.importReport.group,
        groupId: state.importReport.groupId,
        open: state.importReport.open,
        transaction: state.importReport.transaction,
        activeTransactionId: idSelector(state),
        activeGroupId: GroupHelper.getContactListGroupId(),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        close: () => dispatch(contactImportReportClose()),
        download: () => dispatch(contactImportReportDownload()),
        onCloseImportWizard: () => {
            dispatch(contactImportTransactionToggleDialog(false));
            dispatch(reset('import-upload-form'));
        },
        refreshGroup: () => dispatch(refreshView()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactImportReportContainer);