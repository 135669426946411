import React from 'react';
import {Button} from 'react-admin';
import PropTypes from 'prop-types';
import BlacklistIcon from '@material-ui/icons/NotInterested';
import {useTranslation} from 'react-i18next';

const BlacklistButton = ({onClickHandler}) => {
    const {t} = useTranslation();

    return <Button label={t('Blacklist')} onClick={onClickHandler}>
        <BlacklistIcon/>
    </Button>;
};

BlacklistButton.propTypes = {
    onClickHandler: PropTypes.func.isRequired
};

export default BlacklistButton;