import {
    CONTACT_IMPORT_REPORT_CLOSE,
    CONTACT_IMPORT_REPORT_DOWNLOAD,
    CONTACT_IMPORT_REPORT_DOWNLOAD_DONE,
    CONTACT_IMPORT_TRANSACTION_COMPLETED,
    PUSHER_MESSAGE_RECEIVED
} from '../actions/actionTypes';

const initialState = {
    contactsCreated: 0,
    contactsUpdated: 0,
    downloading: false,
    filename: '',
    group: '',
    groupId: null,
    open: false,
    transaction: null
};

export default function contactImportReportReducers(state = initialState, action) {
    switch (action.type) {
        case CONTACT_IMPORT_REPORT_CLOSE:
            return {...initialState};

        case CONTACT_IMPORT_REPORT_DOWNLOAD:
            return Object.assign({}, state, {downloading: true});

        case CONTACT_IMPORT_REPORT_DOWNLOAD_DONE:
            return Object.assign({}, state, {downloading: false});

        case PUSHER_MESSAGE_RECEIVED:
            if (CONTACT_IMPORT_TRANSACTION_COMPLETED !== action.payload.type) {
                return state;
            }
            return Object.assign({}, state, {
                open: true,
                filename: action.payload.filename,
                group: action.payload.group,
                groupId: action.payload.groupId,
                contactsCreated: action.payload.contactsCreated,
                contactsUpdated: action.payload.contactsUpdated,
                transaction: action.payload.transaction
            });

        default:
            return state;
    }
}
