 import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import {useTranslation} from 'react-i18next';

const CampaignSaveButton = ({label = null, save, wizardSaving}) => {
    const {t} = useTranslation();

    if (label === null) {
        label = t('Save');
    }

    return (
        <Button
            disabled={wizardSaving}
            label={label}
            onClick={() => save()}
            variant={'raised'}
            style={{marginRight: '8px'}}>
            <SaveIcon/>
        </Button>
    );
}

CampaignSaveButton.propTypes = {
    label: PropTypes.string,
    save: PropTypes.func.isRequired,
    wizardSaving: PropTypes.bool.isRequired
};

export default CampaignSaveButton;
