import React from 'react';
import {Link} from 'react-router-dom';
import EditIcon from '@material-ui/icons/Games';
import ScheduledIcon from '@material-ui/icons/Today';
import SendingIcon from '@material-ui/icons/FlightTakeoff';
import ReportIcon from '@material-ui/icons/Assessment';

const CampaignIcons = ({record}) => (
    <span>
        {record['state'] === 'done' &&
        <Link to={`/campaigns/${record.id}/report`}>
            <ReportIcon color={'primary'}/>
        </Link>}
        {record['state'] === 'new' &&
        <Link to={`/campaigns/${record.id}`}>
            <EditIcon color={'primary'}/>
        </Link>}
        {record['state'] === 'scheduled' &&
        <Link to={`/campaigns/${record.id}`}>
            <ScheduledIcon color={'primary'}/>
        </Link>}
        {record['state'] === 'sending' && <SendingIcon color={'error'}/>}
    </span>
);

export default CampaignIcons