import React from 'react';
import {
    Filter,
    TextInput
} from 'react-admin';
import {withTranslation} from 'react-i18next';

const TagFilter = ({t, ...props}) => (
    <Filter {...props}>
        <TextInput
            autoFocus
            label={t('Label')}
            source={'label.value'}
        />
    </Filter>
);

export default withTranslation()(TagFilter);