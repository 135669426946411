import React from 'react';
import {connect} from 'react-redux';
import {
    Edit,
    FormTab,
    TabbedForm,
} from 'react-admin';
import {
    campaignChange,
    campaignDispatch,
    campaignWizardInitialize,
    campaignWizardSave
} from '../../actions/campaignActions';
import {
    campaignSelectCampaign,
    campaignSelectWizard,
    campaignSelectWizardLoading
} from '../../selectors/campaignSelector';
import CampaignCreateMetaData from './CampaignEditMetaData';
import CampaignCreateOverview from './CampaignEditOverview';
import CampaignToolbarContainer from './CampaignToolbarContainer';
import CampaignEditSend from './CampaignEditSend';
import CampaignTitle from './CampaignTitle';
import {withTranslation} from 'react-i18next';

class CampaignEdit extends React.Component {
    componentDidMount() {
        const {wizard, wizardInitialize} = this.props;

        if (!wizard.initialized) {
            wizardInitialize();
        }
    }

    render() {
        const {dispatch, campaign, change, method, launch, save, schedule, wizard, wizardInitialize, wizardLoading, t, ...rest} = this.props;
        const groupOptionRenderer = choice => t('%{name} (%{numberOfContacts} contacts)', {
            name: choice.name,
            numberOfContacts: choice.numberOfContacts,
        });
        const {basePath, hasList, hasCreate, hasEdit, hasShow, ...less} = rest;

        return (
            <Edit
                {...rest}
                undoable={false}
                title={<CampaignTitle campaign={campaign}/>}
            >
                <TabbedForm
                    {...less}
                    redirect={false}
                    toolbar={
                        <CampaignToolbarContainer
                            launch={launch}
                            method={campaign.schedule ? 'scheduled' : 'direct'}
                            recipients={wizard.contactCounter.contacts}
                            save={save}
                            schedule={schedule}
                            wizardSaving={wizard.saving || wizardLoading}
                            tab={wizard.tab}moo
                            title={campaign.name}
                        />
                    }
                >
                    <FormTab label={t('Metadata')}>
                        <CampaignCreateMetaData
                            campaign={campaign}
                            change={change}
                            groupOptionRenderer={groupOptionRenderer}
                            loadingUniqueContacts={!wizard.contactCounter.loaded || wizard.contactCounter.loading}
                            uniqueContacts={wizard.contactCounter.contacts}
                            wizard={wizard}
                        />
                    </FormTab>
                    <FormTab disabled={!campaign} label={t('Overview')}>
                        <CampaignCreateOverview
                            campaign={campaign}
                            wizard={wizard}
                            wizardLoading={wizardLoading}
                        />
                    </FormTab>
                    <FormTab disabled={!campaign || 0 === wizard.contactCounter.contacts} label={t('Send')}>
                        <CampaignEditSend
                            campaign={campaign}
                            change={change}
                        />
                    </FormTab>
                </TabbedForm>
            </Edit>
        );
    }
}

const mapStateToProps = state => {
    return {
        campaign: campaignSelectCampaign(state),
        wizard: campaignSelectWizard(state),
        wizardLoading: !campaignSelectWizardLoading(state),
        method: state.campaign['send-method'],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        launch: () => dispatch(campaignDispatch()),
        schedule: () => dispatch(campaignWizardSave('/campaigns', true)),
        change: (field, value) => dispatch(campaignChange(field, value)),
        save: (redirect) => dispatch(campaignWizardSave(redirect, false)),
        wizardInitialize: () => dispatch(campaignWizardInitialize()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CampaignEdit));