import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress} from '@material-ui/core'
import API from '../../api';
import MessagePreviewContainer from "../message/MessagePreviewContainer";

const CampaignReportMetricsMessage = (props) => {
    const [name, setName] = useState('');

    useEffect(() => {
        async function fetchData() {
            const response = await API.getMessage(props.id);

            if (response && response.status === 200) {
                setName(response.json.name);
            }
        }

        fetchData();
    });

    return (
        <React.Fragment>
            {name === '' && <CircularProgress size={12} color={'primary'}/>}
            {name !== '' && <span>{name} <MessagePreviewContainer button={false} id={props.id} saveBeforePreview={false}/></span>}
        </React.Fragment>
    );
};

CampaignReportMetricsMessage.propTypes = {
    id: PropTypes.string.isRequired
};

export default CampaignReportMetricsMessage;