import {campaignsDecorator} from './campaignsDecorator';
import {messagesDecorator} from './messagesDecorator';
import {contactsDecorator} from './contactsDecorator';
import {adminUserDecorator} from './adminUserDecorator';
import {adminUserSubscriptionsDecorator} from './adminUserSubscriptionDecorator';
import {webhookDecorator} from './webhookDecorator';
import {smtpMessagesDecorator} from './smtpMessagesDecorator';

/**
 * @param dataProvider
 * @returns {function(...[*]=)}
 */
export const decoratedDataProvider = (dataProvider) => {
    dataProvider = campaignsDecorator(dataProvider);
    dataProvider = contactsDecorator(dataProvider);
    dataProvider = messagesDecorator(dataProvider);
    dataProvider = adminUserDecorator(dataProvider);
    dataProvider = adminUserSubscriptionsDecorator(dataProvider);
    dataProvider = webhookDecorator(dataProvider);
    dataProvider = smtpMessagesDecorator(dataProvider)

    return dataProvider;
};
