import React from 'react';
import {useTranslation} from 'react-i18next';

const ContactTitle = ({record}) => {
    const {t} = useTranslation();

    return (
        <span>{t('Contact # %{email}', {email: record.email})}</span>
    );
}

export default ContactTitle;