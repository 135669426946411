import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    link: {
        color: '#b24d43',
        textDecoration: 'none',
    },
}));

const getLinkName = (translator, link) => {
    if (!link.hasOwnProperty('landingPage') || link.landingPage === null) {
        return link.url;
    }

    return `${translator('Landing page')}: ${link.landingPage.name}`;
}

const CampaignReportLinks = (props) => {
    const classes = useStyles();
    const {id, links} = props;
    const {t} = useTranslation();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{t('Link')}</TableCell>
                    <TableCell>{t('Unique')}</TableCell>
                    <TableCell>{t('Total')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {links && Object.keys(links).map((key) => {
                    const link = links[key];
                    return (
                        <TableRow key={link.id}>
                            <TableCell>
                                <a href={link.url} target={'_blank'} style={{
                                    textDecoration: 'none'
                                }}>
                                    <Typography variant={'subheading'}>
                                        {getLinkName(t, link)}
                                    </Typography>
                                </a>
                                <Typography variant={'caption'}>{t('ID')}: {link.id}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'title'} color={'primary'}>
                                    {link.contactActions === 0 && `0`}
                                    {link.contactActions > 0 &&
                                    <Link to={`/campaigns/${id}/report/list/clicked/${link.id}`} className={classes.link}>
                                        {link.contactActions}
                                    </Link>
                                    }
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'caption'}>{link.totalActions}</Typography>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

CampaignReportLinks.propTypes = {
    id: PropTypes.string.isRequired,
    links: PropTypes.array.isRequired,
};

export default CampaignReportLinks;
