import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {useTranslation} from 'react-i18next';

const CampaignFilterFilter = ({children, onChange, value}) => {
    const {t} = useTranslation();

    return (
        <div style={{marginRight: '20px'}}>
            <FormControl>
                <InputLabel htmlFor='type'>{t('Filter')}</InputLabel>
                <Select
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    inputProps={{
                        name: 'filter',
                        id: 'filter',
                    }}
                    style={{
                        minWidth: '150px'
                    }}
                >
                    {children}
                </Select>
            </FormControl>
        </div>
    );
}

CampaignFilterFilter.propTypes = {
    value: PropTypes.oneOf(['contains', 'equals', 'not-equals', 'read', 'not-read', 'uri-visited', 'uri-not-visited']).isRequired,
    onChange: PropTypes.func.isRequired,
}

export default CampaignFilterFilter;
