import {ARCHIVE_RECORD, RESTORE_RECORD} from './actionTypes';

export const addToArchive = (resource, id) => ({
    type: ARCHIVE_RECORD,
    payload: {
        resource,
        id,
    }
});

export const restoreFromArchive = (resource, id) => ({
    type: RESTORE_RECORD,
    payload: {
        resource,
        id,
    }
});