import React from 'react';
import {Button, Link} from 'react-admin';
import ContactsIcon from '@material-ui/icons/Contacts';
import {connect} from 'react-redux';
import {groupContactsButtonClicked} from '../../actions/groupActions';
import {uiContactListSetListName} from '../../actions/UiActions';
import {useTranslation} from 'react-i18next';

const ContactsButton = ({onClick, record: {id}}) => {
    const {t} = useTranslation();

    return <Link to={`/contacts?group=${id}`} onClick={onClick}>
        <Button label={t('Contacts')}>
            <ContactsIcon/>
        </Button>
    </Link>
};

const mapDispatchToProps = (dispatch, {record: {id, name}}) => {
    return {
        onClick: () => {
            dispatch(groupContactsButtonClicked(id));
            dispatch(uiContactListSetListName(name));
        }
    }
};

export default connect(null, mapDispatchToProps)(ContactsButton);

