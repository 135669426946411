import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormControl, InputLabel, LinearProgress, MenuItem, Select, Typography} from '@material-ui/core/';
import {campaignSelectCampaign} from '../../selectors/campaignSelector';
import {campaignChange} from '../../actions/campaignActions';
import CampaignInput from "./CampaignInput";

const CampaignSelect = ({campaign, change, error, field, label, options, optionText = 'name'}) => {
    if (options.loaded === false) {
        return (
            <CampaignInput>
                <FormControl>
                    <Typography variant={'caption'}>{label}</Typography>
                    <LinearProgress style={{marginTop: '8px', width: '250px'}}/>
                </FormControl>
            </CampaignInput>
        );
    }

    return (
        <CampaignInput>
            <FormControl error={error} fullWidth>
                <InputLabel htmlFor={field}>{label}</InputLabel>
                <Select
                    fullWidth
                    value={campaign && campaign[field] ? campaign[field] : ''}
                    onChange={(e) => change(field, e.target.value)}
                    inputProps={{
                        name: field,
                        id: field,
                    }}
                >
                    {options.data.map((o) => (
                        <MenuItem key={o['@id']} value={o['@id']}>{o[optionText]}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </CampaignInput>
    );
};

CampaignSelect.propTypes = {
    campaign: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    campaign: campaignSelectCampaign(state)
});

const mapDispatchToProps = dispatch => ({
    change: (field, value) => dispatch(campaignChange(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSelect);