import produce from 'immer';
import {
    USER_LOGIN_SUCCESS
} from 'react-admin';
import {
    UI_AUTH_DIALOG_SHOW,
    UI_AUTH_DIALOG_SET_INTERVAL,
    UI_BLACKLIST_IMPORT_DIALOG_HIDE,
    UI_BLACKLIST_IMPORT_DIALOG_SHOW,
    UI_CONTACT_LIST_SET_LIST_NAME,
} from '../actions/actionTypes';

const initialState = {
    authDialog: {
        display: false,
        interval: null,
    },
    blacklist: {
        importDialog: {
            show: false,
        }
    },
    contactList: {
        title: {
            name: '',
        }
    },
};

const UiReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case USER_LOGIN_SUCCESS:
                draft.authDialog.display = false;
                break;

            case UI_AUTH_DIALOG_SHOW:
                draft.authDialog.display = true;
                break;

            case UI_AUTH_DIALOG_SET_INTERVAL:
                draft.authDialog.interval = action.payload.id;
                break;

            case UI_BLACKLIST_IMPORT_DIALOG_HIDE:
                draft.blacklist.importDialog.show = false;
                break;

            case UI_BLACKLIST_IMPORT_DIALOG_SHOW:
                draft.blacklist.importDialog.show = true;
                break;

            case UI_CONTACT_LIST_SET_LIST_NAME:
                draft.contactList.title.name = action.payload.name;
                break;

            // no default

        }
    });
};

export default UiReducer;