import {LOCATION_CHANGE} from 'react-router-redux';
import produce from 'immer';
import {
    CAMPAIGN_REPORT_FETCHED,
    CAMPAIGN_REPORT_FETCHING,
    CAMPAIGN_REPORT_LIST_CHANGE_PAGE,
    CAMPAIGN_REPORT_LIST_FETCHED,
    CAMPAIGN_REPORT_LIST_FETCHING,
    CAMPAIGN_REPORT_LIST_SET_CAMPAIGN_ID,
    CAMPAIGN_REPORT_LIST_SET_EVENT,
    CAMPAIGN_REPORT_LIST_SET_ROWS_PER_PAGE,
    CAMPAIGN_SET_SEND_METHOD,
    CAMPAIGN_UNIQUE_CONTACTS_COUNTED,
    CAMPAIGN_UNIQUE_CONTACT_COUNT_FETCH,
    CAMPAIGN_CHANGE,
    CAMPAIGN_WIZARD_INITIALIZE,
    CAMPAIGN_WIZARD_FETCH_SENDERS,
    CAMPAIGN_WIZARD_SET_SENDERS,
    CAMPAIGN_WIZARD_FETCH_MESSAGES,
    CAMPAIGN_WIZARD_SET_MESSAGES,
    CAMPAIGN_WIZARD_FETCH_GROUPS,
    CAMPAIGN_WIZARD_SET_GROUPS,
    CAMPAIGN_WIZARD_SAVE,
    CAMPAIGN_WIZARD_SAVE_SUCCESS,
    CAMPAIGN_WIZARD_SAVE_ERROR,
    CAMPAIGN_WIZARD_FETCH_TAGS,
    CAMPAIGN_WIZARD_SET_TAGS,
    CAMPAIGN_WIZARD_SET_ATTRIBUTES,
    CAMPAIGN_WIZARD_SET_CAMPAIGNS,
    TAG_SAVE_SUCCESS,
    CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCH,
    CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCHED,
    CAMPAIGN_WIZARD_SET_FILTERS,
    CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECT,
} from '../actions/actionTypes';
import {CHANGE} from 'redux-form/es/actionTypes';
import {CRUD_GET_ONE_SUCCESS, REFRESH_VIEW} from 'react-admin';


const initialState = {
    'send-method': 'direct',
    campaign: {
        excludeGroups: false,
        excludedGroups: [],
        enableFilters: false,
        filters: [{
            type: 'attributeFilter-006',
            filter: 'contains',
            attribute: '',
            value: '',
            campaign: '',
            link: '',
        }],
        id: null,
        message: null,
        name: '',
        notify: false,
        notifications: [],
        groups: [],
        schedule: false,
        scheduledOn: {
            timestamp: null,
            timestampUtc: null,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        sender: null,
        subjectLine: '',
        tags: [],
    },
    wizard: {
        initialized: false,
        contactCounter: {
            loading: false,
            loaded: false,
            contacts: 0,
        },
        errors: {},
        attributes: {
            data: [],
            loading: false,
            loaded: false,
        },
        campaigns: {
            data: [],
            loading: false,
            loaded: false,
        },
        links: {
            data: {},
            fetching: null,
            loading: false,
            loaded: false,
            queue: [],
        },
        groups: {
            data: [],
            loading: false,
            loaded: false,
        },
        messages: {
            data: [],
            loading: false,
            loaded: false,
        },
        saving: false,
        senders: {
            data: [],
            loading: false,
            loaded: false,
        },
        tags: {
            data: [],
            loading: false,
            loaded: false,
        },
        tab: 0,
    },
    report: {
        '@context': '/contexts/Campaign',
        '@id': '/campaigns/b76b45cd-8e22-4939-b7eb-e3b1173b8180',
        '@type': 'Campaign',
        id: 'b76b45cd-8e22-4939-b7eb-e3b1173b8180',
        name: 'A Campaign to 11 contact',
        subjectLine: 'Hello world campaign',
        senderId: 'a267f22f-abe3-49d3-a384-d6c93a19dc22',
        messageId: '0abe5899-1b13-4dce-94a2-bcfc0f647133',
        recipients: {
            groupIds: [
                'b280e4f8-a369-4479-ac36-625a76a21fff'
            ]
        },
        notify: false,
        notifications: [],
        isScheduled: false,
        scheduledOn: null,
        uniqueRecipients: 6,
        state: 'done',
        report: {
            contactBounceActions: 0,
            contactClickActions: 2,
            contactOpenActions: 5,
            contactUnsubscribeActions: 0,
            deliveryRate: 0,
            lastClickActionAt: '',
            lastOpenActionAt: '',
            ratioClick: 40,
            ratioRead: 83.33,
            ratioUnsubscribe: 0,
            sendingFinishedAt: '2019-03-07 13:40:25',
            sendingStartedAt: '2019-03-07 13:40:21',
            totalClickActions: 3,
            totalOpenActions: 7,
            link: [],
        },
        createdAt: '2019-03-07 13:34:39'
    },
    reportLoading: false,
    reportListMeta: {
        id: null,
        event: '',
        page: 0,
        rowsPerPage: 25,
    },
    reportList: {
        'hydra:members': [],
        'hydra:totalItems': 0
    },
    reportListLoading: false,
};

const campaignReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {

            // Wizard
            case CHANGE:
            case CAMPAIGN_CHANGE:
                const {meta: {field}} = action;

                if (initialState.campaign.hasOwnProperty(field)) {
                    draft.campaign[field] = action.payload;
                    if (draft.wizard.errors.hasOwnProperty(field)) {
                        delete draft.wizard.errors[field];
                    }
                }
                if (action.meta.field === 'scheduledTimestamp') {
                    draft.campaign.scheduledOn.timestamp = action.payload;
                }
                if (action.meta.field === 'scheduledTimestampUtc') {
                    draft.campaign.scheduledOn.timestampUtc = action.payload;
                }
                break;


            case CRUD_GET_ONE_SUCCESS:
                if (action.meta.resource !== 'campaigns') {
                    return;
                }

                draft.campaign.id = action.payload.data.id;
                draft.campaign.message = action.payload.data.message;
                draft.campaign.name = action.payload.data.name;
                draft.campaign.sender = action.payload.data.sender;
                draft.campaign.subjectLine = action.payload.data.subjectLine;

                // @todo remove BC
                if (action.payload.data.notifications.hasOwnProperty('hydra:member')) {
                    console.log('@deprecated');
                    draft.campaign.notifications = action.payload.data.notifications['hydra:member'].map((item) => item.email);

                    if (action.payload.data.notifications['hydra:member'].length > 0) {
                        draft.campaign.notify = true;
                    }
                } else {
                    draft.campaign.notifications = action.payload.data.notifications.map((item) => item.email);

                    if (action.payload.data.notifications.length > 0) {
                        draft.campaign.notify = true;
                    }
                }

                // @todo remove BC
                if (action.payload.data.recipients.hasOwnProperty('hydra:member')) {
                    console.log('@deprecated');
                    draft.campaign.groups = action.payload.data.recipients.groups['hydra:member'];
                } else {
                    draft.campaign.groups = action.payload.data.recipients.groups;
                }

                // @todo remove BC
                if ( action.payload.data.recipients.excludedGroups.hasOwnProperty('hydra:member')) {
                    console.log('@deprecated');
                    draft.campaign.excludedGroups = action.payload.data.recipients.excludedGroups['hydra:member'];

                    if (action.payload.data.recipients.excludedGroups['hydra:member'].length > 0) {
                        draft.campaign.excludeGroups = true;
                    }
                } else {
                    draft.campaign.excludedGroups = action.payload.data.recipients.excludedGroups;

                    if (action.payload.data.recipients.excludedGroups.length > 0) {
                        draft.campaign.excludeGroups = true;
                    }
                }

                draft.campaign.filters = action.payload.data.recipients.filters;
                if (action.payload.data.recipients.filters.length > 0) {
                    draft.campaign.enableFilters = true;
                }

                // @todo remove BC
                if (action.payload.data.tags.hasOwnProperty('hydra:member')) {
                    console.log('@deprecated');
                    draft.campaign.tags = action.payload.data.tags['hydra:member'];
                } else {
                    draft.campaign.tags = action.payload.data.tags;
                }

                if (action.payload.data.scheduledOn) {
                    draft.campaign.scheduledOn.timestamp = action.payload.data.scheduledOn.timestamp;
                    draft.campaign.schedule = true;
                }

                break;

            // OLD
            case CAMPAIGN_SET_SEND_METHOD:
                draft['send-method'] = action.payload.method;
                break;

            case CAMPAIGN_UNIQUE_CONTACTS_COUNTED:
                draft.wizard.contactCounter.contacts = action.payload['unique-contacts'];
                draft.wizard.contactCounter.loading = false;
                draft.wizard.contactCounter.loaded = true;
                break;

            case CAMPAIGN_UNIQUE_CONTACT_COUNT_FETCH:
                draft.wizard.contactCounter.loading = true;
                break;

            case CAMPAIGN_REPORT_FETCHED:
                draft.report = action.payload.report;
                draft.reportLoading = false;
                break;

            case CAMPAIGN_REPORT_FETCHING:
                draft.report = null;
                draft.reportLoading = false;
                break;

            case CAMPAIGN_REPORT_LIST_CHANGE_PAGE:
                draft.reportListMeta.page = action.payload.page;
                break;

            case CAMPAIGN_REPORT_LIST_FETCHED:
                draft.reportList = action.payload.report;
                draft.reportListLoading = false;
                break;

            case CAMPAIGN_REPORT_LIST_FETCHING:
                draft.reportList['hydra:members'] = [];
                draft.reportList['hydra:totalItems'] = 0;
                draft.reportListLoading = true;
                break;

            case CAMPAIGN_REPORT_LIST_SET_CAMPAIGN_ID:
                draft.reportListMeta.id = action.payload.id;
                break;

            case CAMPAIGN_REPORT_LIST_SET_EVENT:
                draft.reportListMeta.event = action.payload.event;
                break;

            case CAMPAIGN_REPORT_LIST_SET_ROWS_PER_PAGE:
                draft.reportListMeta.rowsPerPage = action.payload.rows;
                break;

            case CAMPAIGN_WIZARD_SET_FILTERS:
                draft.campaign.filters = action.payload;
                break;

            case REFRESH_VIEW:
                return initialState;

            case LOCATION_CHANGE:
                const path = action.payload.pathname;

                if (path === '/campaigns/create' || path === '/campaigns') {
                    return initialState;
                }

                if (action.payload.pathname.startsWith('/campaigns')) {
                    let parts = action.payload.pathname.split('/');

                    if (parts.length === 4) {
                        draft['current-tab'] = Number(parts[3]);
                        draft.wizard.tab = Number(parts[3]);
                    } else {
                        draft['current-tab'] = 0;
                        draft.wizard.tab = 0;
                    }
                }
                break;

            // Wizard cases
            case CAMPAIGN_WIZARD_INITIALIZE:
                draft.wizard.initialized = true;
                break;

            // Wizard groups cases:
            case CAMPAIGN_WIZARD_FETCH_GROUPS:
                draft.wizard.groups.loading = true;
                break;

            case CAMPAIGN_WIZARD_SET_GROUPS:
                draft.wizard.groups.loading = false;
                draft.wizard.groups.loaded = true;
                draft.wizard.groups.data = action.payload;
                break;

            // Wizard messages cases:
            case CAMPAIGN_WIZARD_FETCH_MESSAGES:
                draft.wizard.messages.loading = true;
                break;

            case CAMPAIGN_WIZARD_SET_MESSAGES:
                draft.wizard.messages.loading = false;
                draft.wizard.messages.loaded = true;
                draft.wizard.messages.data = action.payload;
                break;

            // Wizard sender cases:
            case CAMPAIGN_WIZARD_FETCH_SENDERS:
                draft.wizard.senders.loading = true;
                break;

            case CAMPAIGN_WIZARD_SET_SENDERS:
                draft.wizard.senders.loading = false;
                draft.wizard.senders.loaded = true;
                draft.wizard.senders.data = action.payload;
                break;

            // Wizard tag cases:
            case CAMPAIGN_WIZARD_FETCH_TAGS:
                draft.wizard.tags.loading = true;
                draft.wizard.tags.loaded = false;
                break;

            case CAMPAIGN_WIZARD_SET_TAGS:
                draft.wizard.tags.loading = false;
                draft.wizard.tags.loaded = true;
                draft.wizard.tags.data = action.payload;
                break;

            case CAMPAIGN_WIZARD_SET_ATTRIBUTES:
                draft.wizard.attributes.loading = false;
                draft.wizard.attributes.loaded = true;
                draft.wizard.attributes.data = action.payload;
                break;

            case CAMPAIGN_WIZARD_SET_CAMPAIGNS:
                draft.wizard.campaigns.loading = false;
                draft.wizard.campaigns.loaded = true;
                draft.wizard.campaigns.data = action.payload;
                break;

            case CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECT:
                draft.wizard.links.queue.push(action.payload);
                break;

            case CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCH:
                draft.wizard.links.fetching = action.payload;
                draft.wizard.links.loading = true;
                draft.wizard.links.loaded = false;
                draft.wizard.links.queue = state.wizard.links.queue.filter(id => id !== action.payload);
                break;

            case CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCHED:
                draft.wizard.links.loading = false;
                draft.wizard.links.loaded = true;
                draft.wizard.links.data[state.wizard.links.fetching] = action.payload;
                draft.wizard.links.fetching = null;
                break;

            case TAG_SAVE_SUCCESS:
                draft.campaign.tags.push(action.payload.tag['@id']);
                break;

            // Wizard save cases:
            case CAMPAIGN_WIZARD_SAVE:
                draft.wizard.saving = true;
                break;

            case CAMPAIGN_WIZARD_SAVE_SUCCESS:
            case CAMPAIGN_WIZARD_SAVE_ERROR:
                draft.wizard.saving = false;

                if (action.payload) {
                    draft.wizard.errors = action.payload;
                }
                break;


            // no default
        }
    })
};

export default campaignReducer;
