import React from 'react';
import PropTypes from 'prop-types';
import Signup from './Signup';

class SignupContainer extends React.Component {
    render = () => (
        <Signup group={this.props.group}/>
    );
}

SignupContainer.propTypes = {
    group: PropTypes.string.isRequired,
};

export default SignupContainer;