import React from 'react';
import {Dialog} from '@material-ui/core';
import SignupDialogTitle from './SignupDialogTitle';
import SignupDialogContent from './SignupDialogContent';
import SignupDialogActions from './SignupDialogActions';

const SignupDialog = () => (
    <Dialog open={true} fullScreen={true}>
        <SignupDialogTitle/>
        <SignupDialogContent/>
        <SignupDialogActions/>
    </Dialog>
);

export default SignupDialog;

