import React from 'react';
import CredentialsForm from './CredentialsForm';
import API from '../../api';
import {connect} from 'react-redux';
import {showNotification} from 'react-admin';
import {withTranslation} from "react-i18next";

class CredentialsFormContainer extends React.Component {
    state = {
        loading: false,
        validatingToken: true,
    };

    componentDidMount() {
        const {match: {params: {id: token}} = null} = this.props;

        this.tokenExists(token);
    }

    tokenExists = (token) => {
        API.getToken(token)
            .then(
                () => this.setState({validatingToken: false})
            ).catch(
            () => window.location.replace('#/login')
        );
    };

    onSubmit = (values) => {
        this.setState({loading: true});

        const {t} = this.props;

        try {
            API.setCredentials(values.key, values.password)
                .then(
                    (response) => {
                        localStorage.setItem('token', response.json.token);

                        this.props.showNotification(t('Password set, redirecting to the application'), 'success');

                        setTimeout(() => {
                            window.location.replace('#/home');
                            window.location.reload(true);
                        }, 2000);
                    },
                    (data) => {
                        this.setState({loading: false});

                        if (data.status === 404) {
                            this.props.showNotification(t('Token not found'), 'error');
                        }

                        if (data.status === 409) {
                            this.props.showNotification(t('Invalid password'), 'error');
                        }
                    }
                );
        } catch (exception) {
            this.props.showNotification(t('Something went wrong, please contact support'));
        }
    };

    render() {
        const {validatingToken} = this.state;

        return (
            <React.Fragment>
            {validatingToken && <div>Uw token wordt gecontroleerd</div>}
            {!validatingToken && <CredentialsForm onSubmit={this.onSubmit} token={this.props.match.params.id}
                             isLoading={this.state.loading}/>}
            </React.Fragment>
        );
    }
}

const mapDispatchToState = (dispatch) => ({
    showNotification: (message) => dispatch(showNotification(message))
});

export default connect(null, mapDispatchToState)(withTranslation()(CredentialsFormContainer));
