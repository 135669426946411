import React from 'react';
import {connect} from 'react-redux';
import CampaignFilterRow from "./CampaignFilterRow";
import {Button} from 'react-admin';
import {AddCircleOutline} from '@material-ui/icons';
import {
    campaignWizardSetFilters
} from "../../actions/campaignActions";
import {useTranslation} from 'react-i18next';

const CampaignFilters = ({filters: rows, onUpdateFilter}) => {
    const createEmptyFilter = () => ({
        type: 'attribute',
        filter: 'contains',
        attribute: '',
        value: '',
        campaign: '',
        link: '',
    });

    const {t} = useTranslation();
    const addFilterHandler = () => onUpdateFilter([...rows, createEmptyFilter()]);
    const removeFilterHandler = (index) => onUpdateFilter(
        rows.filter((filter, i) => {
            return i !== index;
        })
    );

    const updateFilterHandler = (index, data) => {
        const r = [...rows];
        r[index] = {...rows[index], ...data};

        onUpdateFilter(r);
    };

    return (
        <div>
            {rows.map((filter, index) => {
                    return <CampaignFilterRow key={index}
                                              filter={filter}
                                              index={index}
                                              onRemove={removeFilterHandler}
                                              onUpdate={updateFilterHandler}
                    />
                }
            )}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Button color={'primary'} disabled={false} aria-label={t('ADD')} label={t('ADD')} onClick={addFilterHandler}>
                    <AddCircleOutline/>
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        filters: state.campaign.campaign.filters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateFilter: (filters) => dispatch(campaignWizardSetFilters(filters)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFilters);
