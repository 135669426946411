import React from 'react';
import {Create} from 'react-admin'
import {withTranslation} from 'react-i18next';
import WebhookForm from "./WebhookForm";

const WebhookCreate = ({t, ...props}) => (
    <Create {...props} title={t('Create webhook')}>
        <WebhookForm {...props} t={t}/>
    </Create>
);

export default withTranslation()(WebhookCreate);
