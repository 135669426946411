import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-admin';
import {
    AppBar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Switch,
    Toolbar
} from '@material-ui/core';
import {Close, Computer, OpenInBrowser, PhoneIphone} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import {styles} from './MessagePreviewStyles';
import {useTranslation} from 'react-i18next';

const MessagePreview = ({
                            button = true,
                            classes,
                            device,
                            disabled,
                            handlePreview,
                            handleToggleDevice,
                            loading,
                            onClose,
                            onOpen,
                            onDisplayLikeComputer,
                            onDisplayLikePhone,
                            open,
                            saving,
                            source
                        }) => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            {button &&
            <Button
                disabled={disabled}
                onClick={() => {
                    onOpen();
                    handlePreview();
                }}
                label={t('Preview')}
            >
                <OpenInBrowser/>
            </Button>}
            {!button &&
            <a
                href={'/#'}
                onClick={(e) => {
                    e.preventDefault();
                    onOpen();
                    handlePreview();
                }}
                style={{
                    color: '#ff6f61',
                    textDecoration: 'none',
                }}
            >
                {t('Preview')}
            </a>}

            <Dialog
                fullScreen
                open={open}
                onClose={() => onClose()}
                scroll={'paper'}
            >
                <DialogTitle>
                    <AppBar>
                        <Toolbar>
                            <div className={classes.toolbarRoot}>
                                <div className={classes.toolbarDeviceSwitchRoot}>
                                    <Computer
                                        className={device === 'computer' ? classes.iconSelected : classes.iconNotSelected}
                                        onClick={() => onDisplayLikeComputer()}
                                    />
                                    <Switch
                                        checked={device === 'phone'}
                                        onChange={() => handleToggleDevice()}
                                    />
                                    <PhoneIphone
                                        className={device === 'phone' ? classes.iconSelected : classes.iconNotSelected}
                                        onClick={() => onDisplayLikePhone()}
                                    />
                                </div>
                                <div>
                                    <IconButton onClick={() => onClose()}>
                                        <Close/>
                                    </IconButton>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {(saving || loading) && <LinearProgress/>}
                    {!saving && !loading &&
                    <div className={classes.previewRoot}>
                        <iframe
                            className={device === 'computer' ? classes.previewComputer : classes.previewPhone}
                            frameBorder={0}
                            src={'data:text/html,' + encodeURIComponent(source)}
                            title={'message-preview'}
                        />
                    </div>}
                </DialogContent>
            </Dialog>
        </React.Fragment>);
}

MessagePreview.propTypes = {
    button: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    device: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    handlePreview: PropTypes.func.isRequired,
    handleToggleDevice: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDisplayLikeComputer: PropTypes.func.isRequired,
    onDisplayLikePhone: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    saving: PropTypes.bool,
    source: PropTypes.string,
};

export default withStyles(styles)(MessagePreview);