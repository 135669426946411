import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import CampaignReportRate from "./CampaignReportRate";
import {useTranslation} from 'react-i18next';

const CampaignReportRatesRow = ({report}) => {
    const {t} = useTranslation();

    return (
        <Grid container spacing={24}>
            <Grid item xs={6}>
                <CampaignReportRate label={t('Open rate')} ratio={report.ratioRead} uniqueActions={report.contactOpenActions}
                                    totalActions={report.totalOpenActions} lastOccurrence={report.lastOpenActionAt}/>
            </Grid>
            <Grid item xs={6}>
                <CampaignReportRate label={t('Click rate')} ratio={report.ratioClick} uniqueActions={report.contactClickActions}
                                    totalActions={report.totalClickActions} lastOccurrence={report.lastClickActionAt}/>
            </Grid>
        </Grid>
    );
};

CampaignReportRatesRow.propTypes = {
    report: PropTypes.object.isRequired,
};

export default CampaignReportRatesRow;
