import React from 'react';
import {
    Filter,
    SelectInput,
    TextInput
} from 'react-admin';
import {useTranslation} from 'react-i18next';

const ContactFilter = (props) => {
    const {t} = useTranslation();

    return (
        <Filter {...props}>
            <TextInput source={'group'} alwaysOn={true} style={{display: 'none'}} />
            <TextInput autoFocus label={t('Email')} source='email'/>
            <SelectInput source={'state.value'} choices={[
                {id: 'active', name: t('Active')},
                {id: 'blacklisted', name: t('Blacklisted')},
                {id: 'bounced-out', name: t('Bounced out')},
                {id: 'signed-up-not-confirmed', name: t('Signed up not confirmed')},
                {id: 'unsubscribed', name: t('Unsubscribed')},
            ]} label={t('State')}/>
        </Filter>
    );
}

export default ContactFilter;