import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button} from 'react-admin';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import {restoreFromArchive} from "../../../actions/commonActions";
import {refreshView} from 'react-admin';
import {setListSelectedIds} from "../../../actions/reactAdmin";

class BulkRestoreButton extends React.Component {
    handleClick = () => {
        const {refreshView, resource, restoreFromArchive, selectedIds, setListSelectedIds} = this.props;

        selectedIds.map((id) => (
            restoreFromArchive(resource, id)
        ));

        refreshView();
        setListSelectedIds(resource, []);
    };

    render = () => {
        const {label} = this.props;

        return (<Button label={label} onClick={this.handleClick}><UnarchiveIcon/></Button>);
    };
}

BulkRestoreButton.defaultProps = {
    label: 'Restore'
};

BulkRestoreButton.propTypes = {
    label: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    restoreFromArchive: (resource, id) => dispatch(restoreFromArchive(resource, id)),
    setListSelectedIds: (resource, ids) => dispatch(setListSelectedIds(resource, ids)),
    refreshView: () => dispatch(refreshView()),
});

export default connect(null, mapDispatchToProps)(BulkRestoreButton);