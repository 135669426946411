import React from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-admin';
import Icon from '@material-ui/icons/AssignmentInd';
import {signupShow} from '../../actions/signupActions';
import {useTranslation} from 'react-i18next';

const SignupButton = ({group, handleClick}) => {
    const {t} = useTranslation();

    return (
        <Button label={t('Signup form')} onClick={() => handleClick(group)}>
            <Icon/>
        </Button>);
}

const mapDispatchToProps = (dispatch) => ({
    handleClick: (group) => dispatch(signupShow(group)),
});

export default connect(null, mapDispatchToProps)(SignupButton);