import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CampaignFilterFilter from './CampaignFilterFilter';
import {campaignWizardFilterCampaignSelect} from "../../actions/campaignActions";
import {useTranslation} from 'react-i18next';

const CampaignFilterEventAttributes = (
    {
        campaigns,
        campaignSelected,
        filter: {
            campaign,
            filter: filterType,
            link,
        },
        index,
        links,
        onUpdate,
    }
) => {
    const FILTER_CAMPAIGN_READ = 'read';
    const FILTER_CAMPAIGN_NOT_READ = 'not-read';
    const FILTER_URI_VISITED = 'uri-visited';
    const FILTER_URI_NOT_VISITED = 'uri-not-visited';

    const {t} = useTranslation();

    const isUriEvent = () => [FILTER_URI_VISITED, FILTER_URI_NOT_VISITED].includes(filterType);

    const filterTypeUpdateHandler = (filter) => onUpdate(index, {filter});
    const linkUpdateHandler = (event) => onUpdate(index, {link: event.target.value});
    const campaignUpdateHandler = (event) => {
        const selected = event.target.value;

        onUpdate(index, {campaign: selected});
        campaignSelected(selected.split('/').pop());
    }

    useEffect(() => {
        if (campaign !== '') {
            campaignSelected(campaign.split('/').pop());
        }
    }, [campaign]);

    const campaignId = () => {
        return campaign.split('/').pop();
    }

    return (
        <>
            {campaigns.loaded &&
                <div style={{marginRight: '20px'}}>
                    <FormControl>
                        <InputLabel htmlFor='campaign'>{t('Campaign')}</InputLabel>
                        <Select
                            value={campaign}
                            onChange={campaignUpdateHandler}
                            inputProps={{
                                name: 'campaign',
                                id: 'campaign',
                            }}
                            style={{
                                minWidth: '250px'
                            }}
                        >
                            {campaigns.data.map(
                                (campaign, index) =>
                                    <MenuItem key={index} value={campaign['@id']}>{campaign.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>}

            <CampaignFilterFilter value={filterType} onChange={filterTypeUpdateHandler}>
                <MenuItem value={FILTER_CAMPAIGN_READ}>{t('Campaign read')}</MenuItem>
                <MenuItem value={FILTER_CAMPAIGN_NOT_READ}>{t('Campaign not read')}</MenuItem>
                <MenuItem value={FILTER_URI_VISITED}>{t('Uri visited')}</MenuItem>
                <MenuItem value={FILTER_URI_NOT_VISITED}>{t('Uri not visited')}</MenuItem>
            </CampaignFilterFilter>
            {isUriEvent() && links.loaded && links.data.hasOwnProperty(campaignId()) &&
                <div style={{marginRight: '20px', width: '100%'}}>
                    {links.data.hasOwnProperty(campaignId()) && links.data[campaignId()].length > 0 &&
                        <FormControl style={{width: '100%'}}>
                            <InputLabel htmlFor='campaign'>{t('Link')}</InputLabel>
                            <Select
                                value={link}
                                onChange={linkUpdateHandler}
                                inputProps={{
                                    name: 'link',
                                    id: 'link',
                                }}
                                style={{
                                    minWidth: '250px',
                                    width: '100%',
                                }}
                            >
                                {links.data[campaign.split('/').pop()].map(
                                    (link, index) =>
                                        <MenuItem key={index} value={link.url}>{link.url}</MenuItem>
                                )}
                            </Select>
                        </FormControl>}

                    {links.data.hasOwnProperty(campaignId()) && links.data[campaignId()].length === 0 && <span>{t('Campaign has no tracked links')}</span>}
                </div>}

            {!isUriEvent() && <div style={{width: '100%'}}/>}
        </>
    );
}

CampaignFilterEventAttributes.propType = {
    filter: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onUpdate: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    campaigns: state.campaign.wizard.campaigns,
    links: state.campaign.wizard.links,
});

const mapDispatchToProps = dispatch => ({
    campaignSelected: (value) => dispatch(campaignWizardFilterCampaignSelect(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFilterEventAttributes);
