import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import {makeStyles} from "@material-ui/styles";
import {withTranslation} from "react-i18next";
import CardWithBackground from "../common/CardWithBackground";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: red[500],
    }
}));

const DashboardSubscriptionCard = (props) => {
    const classes = useStyles();
    const {contactCount, contactLimit, infoLoading, isOverMessageLimit, subscriptionType, t} = props;

    return (
        <CardWithBackground>
            <CardHeader avatar={
                <Avatar aria-label={'Subscription'} className={classes.avatar}>{t('Subscription').substring(0, 1)}</Avatar>
            } title={t('Subscription')} subheader={subscriptionType}/>
            {!infoLoading &&
            <CardContent>
                <Typography variant={'body1'}>{`${t('Contacts')}: ${contactCount.toLocaleString()} / ${contactLimit.toLocaleString()}`}</Typography>
                {isOverMessageLimit && <Typography color={'primary'}>{t('Your account has used more than the allowed amount of messages.')}</Typography>}
            </CardContent>}
            {infoLoading && <LinearProgress />}
        </CardWithBackground>);
};

DashboardSubscriptionCard.propTypes = {
    contactCount: PropTypes.number.isRequired,
    contactLimit: PropTypes.number.isRequired,
    infoLoading: PropTypes.bool.isRequired,
    isOverMessageLimit: PropTypes.bool.isRequired,
    subscriptionType: PropTypes.string.isRequired,
};

export default withTranslation()(DashboardSubscriptionCard);
