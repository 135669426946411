import React from 'react';
import {connect} from 'react-redux';
import {
    Datagrid,
    EditButton,
    List,
    TextField,
} from 'react-admin';
import CardActions from '../common/CardActions';
import DateField from '../common/Field/DateField';
import WebhookSecret from './WebhookSecret';
import {withTranslation} from 'react-i18next';
import WebhookStateField from "./WebhookStateField";
import WebhookFilter from "./WebhookFilter";
import WebhookTypeField from "./WebhookTypeField";

const WebhookList = ({modules, dispatch, t, ...props}) => (
    <List title={t('webhooks')} {...props} filters={<WebhookFilter/>} sort={{field: 'name', order: 'ASC'}} actions={<CardActions/>}>
        <Datagrid>
            <TextField source={'name'} sortBy={'name.value'} label={t('Name')}/>
            <TextField source={'destination'} sortBy={'destination.url'} label={t('Target URL')}/>
            <WebhookTypeField label={t('Type')} sortBy={'type.value'} translate={t}/>
            <WebhookStateField label={t('State')} sortBy={'state.value'} translate={t}/>
            <DateField source={'createdAt'} label={t('Created')}/>
            {props.hasEdit && <EditButton/>}
            <WebhookSecret {...props}/>
        </Datagrid>
    </List>
);

const mapStateToProps = (state) => {
    return {
        modules: state.account.info.modules,
    }
};
export default connect(mapStateToProps)(withTranslation()(WebhookList));
