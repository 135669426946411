import { put, takeEvery } from 'redux-saga/effects';
import {CRUD_CREATE_SUCCESS, refreshView} from 'react-admin';
import {uiBlacklistImportDialogHide} from "../actions/UiActions";

function* reloadBlacklist (action) {
    const {meta: {resource}} = action;

    if (resource !== 'blacklist/contacts/import') {
        return;
    }

    yield put(uiBlacklistImportDialogHide());
    yield put(refreshView());
}

export function* blacklistSagas() {
    yield takeEvery(CRUD_CREATE_SUCCESS, reloadBlacklist);
}