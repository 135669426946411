import React from 'react';

const WebhookTypeField = ({record: {type}, translate: t}) => {
    const translator = {
        'bounce': t('Bounce'),
    };

    return <span>{translator[type]}</span>;
}

export default WebhookTypeField;
