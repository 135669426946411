import React from 'react';
import {
    TextField,
    Datagrid,
    EditButton,
    List,
    ShowButton,
} from 'react-admin';
import CardActions from '../common/CardActions';
import MessageFilter from './MessageFilter';
import MessageListBulkActionButtons from './MessageListBulkActionButtons';
import MessageIcons from './MessageIcons';
import MessageDuplicateButton from './MessageDuplicateButton';
import DateField from '../common/Field/DateField';
import {useTranslation} from 'react-i18next';

const MessageList = (props) => {
    const {t} = useTranslation();

    return (
        <List
            {...props}
            actions={<CardActions/>}
            bulkActionButtons={<MessageListBulkActionButtons/>}
            filters={<MessageFilter/>}
            sort={{
                field: 'createdAt',
                order: 'DESC'
            }}
            title={t('Messages')}
        >
            <Datagrid>
                <MessageIcons
                    label={t('Published')}
                    sortBy={'publishState.state'}
                    source={'publishState'}
                />
                <TextField
                    label={t('Name')}
                    sortBy={'name.value'}
                    source={'name'}
                />
                <DateField label={t('Created')} source={'createdAt'}/>
                {props.hasShow && <ShowButton/>}
                {props.hasEdit && <EditButton/>}
                <MessageDuplicateButton/>
            </Datagrid>
        </List>
    );
}

export default MessageList;
