import produce from 'immer';
import {
    MESSAGES_DESTROY,
    MESSAGE_LINK_TOGGLE_ENABLED,
    MESSAGE_PREVIEW_HIDE,
    MESSAGE_PREVIEW_SHOW,
    MESSAGE_SAVE,
    MESSAGE_SAVED,
    MESSAGE_SET_LINKS,
    MESSAGE_SHOW_IN_BROWSER,
    MESSAGE_TOPOL_SAVING, MESSAGE_CHANGE, MESSAGE_SEND_PREVIEW_TO,
} from "../actions/actionTypes";
import {INITIALIZE} from 'redux-form/es/actionTypes';

const initialState = {
    data: {
        id: null,
        name: '',
        html: '',
        structured: '',
        text: '',
        title: '',
        hasTextVariant: false,
        publishState: false,
        builder: 'structured',
        links: [],
        privacy: 'private',
    },
    meta: {
        action: null,
        builder: '',
        errors: [],
        id: null,
        saving: false,
        preview: {
            open: false,
        },
        sendPreviewTo: null
    },
    links: []
};

const messageReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case INITIALIZE:
                if (action.payload['@type'] !== 'Message') {
                    break;
                }

                const message = action.payload;

                draft.data.id = message.id;
                draft.data.name = message.name;
                draft.data.html = message.html;
                draft.data.structured = message.structured;
                draft.data.text = message.text;
                draft.data.hasTextVariant = Boolean(message.text);
                draft.data.publishState = message.publishState;
                draft.data.builder = message.builder;
                draft.data.privacy = message.privacy;
                draft.data.title = message.title;

                // @todo remove
                if (message.links.hasOwnProperty('hydra:member')) {
                    console.log('@deprecated');
                    draft.data.links = message.links['hydra:member'];
                    draft.links = action.payload.links['hydra:member'];

                } else {
                    draft.data.links = message.links;
                    draft.links = action.payload.links;

                }

                draft.meta.id = message.id;
                draft.meta.builder = action.payload.builder ? action.payload.builder : state.meta.builder;
                break;

            case MESSAGE_CHANGE:
                const {meta: {field}} = action;

                if (initialState.data.hasOwnProperty(field)) {
                    draft.data[field] = action.payload;
                    if (draft.meta.errors.hasOwnProperty(field)) {
                        delete draft.meta.errors[field];
                    }
                }

                if (!draft.data.hasTextVariant) {
                    draft.data.text = '';
                }
                break;

            case MESSAGE_LINK_TOGGLE_ENABLED:
                draft.data.links = state.data.links.map(l => l.url === action.url ? {...l, enabled: !l.enabled} : l);
                break;

            case MESSAGE_SEND_PREVIEW_TO:
                draft.meta.sendPreviewTo = action.payload.to;
                break;

            case MESSAGE_SHOW_IN_BROWSER:
                draft.meta.action = 'show-in-browser';
                break;

            case MESSAGE_PREVIEW_HIDE:
                draft.meta.preview.open = false;
                break;

            case MESSAGE_PREVIEW_SHOW:
                draft.meta.preview.open = true;
                break;

            case MESSAGE_SAVE:
                draft.meta.saving = true;
                if (action.payload.action) {
                    draft.meta.action = action.payload.action;
                }
                break;

            case MESSAGE_SAVED:
                draft.meta.action = null;
                draft.meta.saving = false;
                break;

            case MESSAGE_TOPOL_SAVING:
                draft.meta.action = action.payload.action;
                draft.meta.saving = true;
                break;

            case MESSAGE_SET_LINKS:
                draft.data.links = action.payload.links;
                break;

            case MESSAGES_DESTROY:
                return initialState;

            // no default
        }
    })
};

export default messageReducer;
