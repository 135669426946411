import React from 'react';

const CampaignInput = ({children}) => (
    <div style={{
        marginBottom: '24px',
        marginTop: '8px',
    }}>
        {children}
    </div>
);

export default CampaignInput;