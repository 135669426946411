import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/styles";
import CampaignReportMetricsBoxRows from "./CampaignReportMetricsBoxRows";
import CampaignReportRatesRow from "./CampaignReportRatesRow";
import CampaignReportMetricsMessage from "./CampaignReportMetricsMessage";
import {useTranslation} from 'react-i18next';
import {localize} from '../../util/date';

const useStyles = makeStyles(theme => ({
    flex: {
        display: 'flex',
        paddingBottom: '12px',
    },
    divider: {
        marginBottom: '24px',
        marginTop: '24px',
    },
    link: {
        color: '#b24d43',
        textDecoration: 'none',
    },
    typographyTitle: {
        fontWeight: 'bold',
    },
    typographyContent: {
        paddingLeft: '5px',
    },
}));

const CampaignReportMetrics = (props) => {
    const classes = useStyles();
    const {campaign, campaign: {report}} = props;
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <div className={classes.flex} style={{paddingBottom: '0px'}}>
                        <Link to={`/campaigns/${campaign.id}/report/list/sent`} className={classes.link}>
                            <Typography noWrap variant={'title'}
                                        color={'primary'}>{report.contactSentActions}</Typography>
                        </Link>
                        <Typography noWrap variant={'title'}
                                    className={classes.typographyContent}>{t('Recipients')}</Typography>
                    </div>
                    <Typography
                        variant={'caption'}>
                        {report.contactBounceActions === 0 && t('No contacts bounced. Delivery rate: 100%')}
                        {report.contactBounceActions > 0 &&
                        <React.Fragment>
                            <Link to={`/campaigns/${campaign.id}/report/list/bounced`}
                                  className={classes.link}>{`${report.contactBounceActions} `}
                            </Link>
                            {t('contacts bounced. Delivery rate: %{deliveryRate}%', {deliveryRate: report.deliveryRate})}
                        </React.Fragment>
                        }
                    </Typography>
                    <Divider className={classes.divider}/>
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <div className={classes.flex}>
                                <Typography noWrap variant={'caption'}
                                            className={classes.typographyTitle}>{t('Subject')}: </Typography>
                                <Typography noWrap variant={'caption'}
                                            className={classes.typographyContent}>{campaign.subjectLine}</Typography>
                            </div>

                            <div className={classes.flex}>
                                <Typography noWrap variant={'caption'}
                                            className={classes.typographyTitle}>{t('Message')}: </Typography>
                                <Typography noWrap variant={'caption'}
                                            className={classes.typographyContent}><CampaignReportMetricsMessage id={campaign.message.split('/').pop()}/></Typography>
                            </div>

                            <div className={classes.flex}>
                                <Typography noWrap variant={'caption'} className={classes.typographyTitle}>{t('Recipients')}:</Typography>
                                <Typography noWrap variant={'caption'}
                                            className={classes.typographyContent}>{campaign.recipients.groups.join(',')}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.flex}>
                                <Typography noWrap variant={'caption'} className={classes.typographyTitle}>{t('Started sending on')}:</Typography>
                                <Typography noWrap variant={'caption'}
                                            className={classes.typographyContent}>{localize(report.sendingStartedAt)}</Typography>
                            </div>

                            <div className={classes.flex}>
                                <Typography noWrap variant={'caption'} className={classes.typographyTitle}>{t('Finished sending on')}:</Typography>
                                <Typography noWrap variant={'caption'}
                                            className={classes.typographyContent}>{localize(report.sendingFinishedAt)}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider variant={'middle'} className={classes.divider}/>
            <CampaignReportRatesRow report={report}/>
            <Divider variant={'middle'} className={classes.divider}/>
            <CampaignReportMetricsBoxRows id={campaign.id} report={report}/>
        </React.Fragment>
    )
        ;
};

CampaignReportMetrics.propTypes = {
    campaign: PropTypes.object
};

export default CampaignReportMetrics;
