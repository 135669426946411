import {GET_LIST} from 'react-admin';

/**
 * Decorator for messages related requests
 *
 * @param requestHandler
 * @returns {function(...[*]=)}
 */
export const smtpMessagesDecorator = requestHandler => (type, resource, params) => {
    if (resource !== 'smtp/messages') {
        return requestHandler(type, resource, params);
    }

    if (type === GET_LIST) {
        params = applyDefaultArchiveFilter(params);
    }

    return requestHandler(type, resource, params);
};

/**
 * Make sure the inArchive filter is always set
 *
 * @param params
 * @returns {*}
 */
const applyDefaultArchiveFilter = (params) => {
    const {filter} = params;

    if (Object.keys(filter).length === 0) {
        params.filter['sender'] = '0';
    }

    return params;
};
