import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { validateName } from './validateGroup';
import GroupTitle from './GroupTitle';
import {useTranslation} from 'react-i18next';

export const GroupEdit = props => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={<GroupTitle/>}>
            <SimpleForm>
                <TextInput autoFocus label={t('Contact list name')} source={'name'} validate={validateName} fullWidth />
            </SimpleForm>
        </Edit>
    );
}

export default GroupEdit;