import React from "react";
import {SaveButton, Toolbar} from 'react-admin';
import PropTypes from "prop-types";

const ContactImportToolbar = props => (
    <Toolbar>
        <SaveButton label={props.label} onClick={props.handleSaveClick}/>
    </Toolbar>
);

ContactImportToolbar.propTypes = {
    handleSaveClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};

export default ContactImportToolbar;