import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import DashboardSubscriptionCard from "./DashboardSubscriptionCard";
import DashboardRatiosCard from "./DashboardRatiosCard";
import DashboardRecentCampaigns from "./DashboardRecentCampaigns";
import DashboardRecentMessages from "./DashboardRecentMessages";
import DashboardScheduledCampaigns from "./DashboardScheduledCampaigns";
import {withModules} from "../common/WithModules";
import Statistics from "../smtp/StatisticsContainer";
import DashboardSmtpSubscriptionCard from "./DashboardSmtpSubscriptionCard";
import {useTranslation} from "react-i18next";

const Dashboard = (props) => {
    const {
        campaignsScheduled,
        campaignsScheduledLoading,
        campaignsSent,
        campaignsSentLoading,
        contactCount,
        contactLimit,
        infoLoading,
        isOverContactLimit,
        isOverMessageLimit,
        hasModule,
        messageCount,
        messageLimit,
        messages,
        messagesLoading,
        stats,
        statsLoading,
        subscription,
        username,
    } = props;

    const {t} = useTranslation();

    return (
            <Card raised={false}>
                <CardHeader title={t('Welcome %{name}', {name: username})}/>
                <CardContent>
                    {hasModule('BULK') &&
                    <Grid container={true} spacing={24}>
                        <Grid item xs={8}>
                            <Grid container={true} spacing={24}>
                                <Grid item xs={12}>
                                    {hasModule('BULK') && <DashboardSubscriptionCard contactCount={contactCount}
                                                               contactLimit={contactLimit}
                                                               infoLoading={infoLoading}
                                                               isOverContactLimit={isOverContactLimit}
                                                               isOverMessageLimit={isOverMessageLimit}
                                                               subscriptionType={subscription}/>}
                                </Grid>
                                <Grid item xs={12}>
                                    {hasModule('BULK') && <DashboardRecentCampaigns campaigns={campaignsSent}
                                                              campaignsLoading={campaignsSentLoading}/>}
                                </Grid>
                                <Grid item xs={12}>
                                    {hasModule('BULK') && <DashboardScheduledCampaigns campaigns={campaignsScheduled}
                                                                 campaignsLoading={campaignsScheduledLoading}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container={true} spacing={24}>
                                <Grid item xs={12}>
                                    {hasModule('BULK') && <DashboardRatiosCard clickCount={stats.contactClickActions}
                                                         clickRatio={stats.ratioClick}
                                                         openCount={stats.contactOpenActions}
                                                         openRatio={stats.ratioOpen}
                                                         totalCampaignsSent={stats.campaignsSent}
                                                         totalContactsSent={stats.contactsSent}
                                                         statsLoading={statsLoading}
                                                         unsubscribeCount={stats.contactUnsubscribeActions}
                                                         unsubscribeRatio={stats.ratioUnsubscribe}/>}
                                </Grid>
                                <Grid item xs={12}>
                                    {hasModule('BULK') && <DashboardRecentMessages messages={messages}
                                                             messagesLoading={messagesLoading}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {hasModule('SMTP') && <Grid container={true} spacing={24}>
                        <Grid container={true} spacing={40}>
                            <Grid item xs={3}>
                                <DashboardSmtpSubscriptionCard
                                    messageCount={messageCount}
                                                           messageLimit={messageLimit}
                                                           infoLoading={infoLoading}
                                                           isOverMessageLimit={isOverMessageLimit}
                                                           subscriptionType={subscription}/>
                            </Grid>
                            <Grid item xs={12}>

                                <Grid item xs={12}>
                                    <Statistics/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                </CardContent>
            </Card>
    );
};

Dashboard.propTypes = {
    username: PropTypes.string.isRequired,
};

export default withModules(Dashboard);
