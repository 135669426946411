// import base64Download from './util/base64Download';
// base64Download(data,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

const base64Download = (filename, data, datatype) => {
    data = atob(data);
    let arraybuffer = new ArrayBuffer(data.length);
    let view = new Uint8Array(arraybuffer);

    for (let i=0; i<data.length; i++) {
        view[i] = data.charCodeAt(i) & 0xff;
    }

    let blob;

    try {
        // This is the recommended method:
        blob = new Blob([arraybuffer], {type: datatype});
    } catch (e) {
        // The BlobBuilder API has been deprecated in favour of Blob, but older
        // browsers don't know about the Blob constructor
        // IE10 also supports BlobBuilder, but since the `Blob` constructor
        //  also works, there's no need to add `MSBlobBuilder`.
        let bb = new (window.WebKitBlobBuilder || window.MozBlobBuilder)();
        bb.append(arraybuffer);
        blob = bb.getBlob(datatype); // <-- Here's the Blob
    }

    // Use the URL object to create a temporary URL
    let url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = filename;
    a.click();
};



export default base64Download;