import {
    SIGNUP_FETCH_FORM,
    SIGNUP_FETCH_GROUPS,
    SIGNUP_FETCH_SENDERS,
    SIGNUP_FORM_CHANGE,
    SIGNUP_HIDE,
    SIGNUP_HIDE_REQUESTED,
    SIGNUP_SAVE,
    SIGNUP_SAVED,
    SIGNUP_SET_FORM,
    SIGNUP_SET_GROUP,
    SIGNUP_SET_SENDERS,
    SIGNUP_SHOW,
    SIGNUP_SHOW_CANCEL_CONFIRMATION
} from './actionTypes';

export const signupFetchForm = () => ({type: SIGNUP_FETCH_FORM});
export const signupFetchGroups = () => ({type: SIGNUP_FETCH_GROUPS});
export const signupFetchSenders = () => ({type: SIGNUP_FETCH_SENDERS});
export const signupFormChange = (category, field, value) => ({
    type: SIGNUP_FORM_CHANGE,
    payload: {
        category,
        field,
        value
    }
});
export const signupHide = () => ({type: SIGNUP_HIDE});
export const signupHideRequested = () => ({type: SIGNUP_HIDE_REQUESTED});
export const signupSave = (hide) => ({type: SIGNUP_SAVE, hide});
export const signupSaved = () => ({type: SIGNUP_SAVED});
export const signupSetForm = (form) => ({type: SIGNUP_SET_FORM, form});
export const signupSetGroup = (group) => ({type: SIGNUP_SET_GROUP, group});
export const signupSetSenders = (senders) => ({type: SIGNUP_SET_SENDERS, senders});
export const signupShow = (group) => ({type: SIGNUP_SHOW, group});
export const signupShowCancelConfirmation = () => ({type: SIGNUP_SHOW_CANCEL_CONFIRMATION});
