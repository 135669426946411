import produce from 'immer';
import {ADMIN_SET_USER} from '../actions/actionTypes';

const initialState = {
    selectedUser: {
        id: null,
    }
};

const adminReducer = (state = initialState, action) => {
    return produce(state, draft => {
        const {payload, type} = action;

        switch (type) {
            case ADMIN_SET_USER:
                draft.selectedUser.id = payload.id;
                break;

            // no default
        }
    })
};

export default adminReducer;