import React from 'react';
import { Button } from 'react-admin';
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import LaunchIcon from '@material-ui/icons/Send';
import {useTranslation} from 'react-i18next';

const CampaignLaunchDialog = ({
                                  launchDisabled,
                                  validateRecipients,
                                  open,
                                  onClose,
                                  onLaunch,
                                  isLaunching,
                                  title,
                                  recipients
                              }) => {
    const {t} = useTranslation();

    return <Dialog open={open}>
        <DialogTitle>{t('Confirmation required')}</DialogTitle>
        <DialogContent>
            <DialogContentText>{t('You are about to launch this campaign "%{title}" to a maximum of %{recipients} recipients.', {recipients, title})}</DialogContentText>
            <DialogContentText>{t('To make sure this is what you intend, you are required to type the number of recipients in the textbox below.')}</DialogContentText>
            <TextField autoFocus disabled={isLaunching} label={t('Number of recipients')} placeholder={`${recipients}`} onChange={validateRecipients} />
        </DialogContent>
        <DialogActions>
            <Button label={t('Launch')} disabled={launchDisabled || isLaunching} onClick={onLaunch}>
                <LaunchIcon />
            </Button>
            <Button label={t('Close')} onClick={onClose} />
        </DialogActions>
        {isLaunching && <LinearProgress />}
    </Dialog>;
};

CampaignLaunchDialog.propTypes = {
    onClose: PropTypes.func,
    launchDisabled: PropTypes.bool,
    open: PropTypes.bool,
    onLaunch: PropTypes.func,
    isLaunching: PropTypes.bool,
    recipients: PropTypes.number,
    title: PropTypes.string,
    validateRecipients: PropTypes.func,
};

export default CampaignLaunchDialog;
