import React from 'react';

const WebhookStateField = ({record: {state}, translate: t}) => {
    const translator = {
        'enabled': t('Enabled'),
        'disabled': t('Disabled'),
    };

    return <span>{translator[state]}</span>;
}

export default WebhookStateField;
