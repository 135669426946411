import React, {useState} from 'react';
import {Button} from 'react-admin';
import {Https as LockIcon, Visibility, VisibilityOff} from '@material-ui/icons';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from '@material-ui/core';
import {withTranslation} from "react-i18next";

const SmtpCredentials = ({record: {email, smtpCredentials: {username = '', password= ''} = {}}, t}) => {
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showUsername, setShowUsername] = useState(false);

    return <React.Fragment>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{t('SMTP Credentials')}</DialogTitle>
            <DialogContent>
                <Typography variant={'caption'} paragraph={true}>
                    {t('Authentication matches username, password AND from address.')}
                </Typography>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {t('Server')}
                            </TableCell>
                            <TableCell>
                                <Typography component={'div'} style={{
                                    paddingLeft: '48px'
                                }}>{`smtp.mynuma.io [${t('port')}: 587 (tls)]`}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('From address')}</TableCell>
                            <TableCell><Typography component={'div'} style={{
                                paddingLeft: '48px'
                            }}>{email}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('SMTP Username')}</TableCell>
                            <TableCell>
                                <IconButton aria-label={t('Show password')} onClick={() => setShowUsername(!showUsername)}>
                                    {showUsername ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                                {showUsername ? username : '...'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('SMTP Password')}</TableCell>
                            <TableCell>
                                <IconButton aria-label={t('Show password')} onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                                {showPassword ? password : '...'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
        <Button label={t('SMTP Credentials')} onClick={() => setOpen(true)}><LockIcon/></Button>
    </React.Fragment>;
}

export default withTranslation()(SmtpCredentials);