import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import {Button} from 'react-admin';
import ImportIcon from '@material-ui/icons/CloudUpload';
import ContactImportDialog from './ContactImportDialog';
import {
    contactImportTransactionResetState,
    contactImportTransactionToggleDialog
} from '../../actions/contactImportActions';
import {useTranslation} from 'react-i18next';

const ImportButton = ({importing, showDialog, hideDialog}) => {
    const {t} = useTranslation();

    return <Fragment>
        <Button label={t('Import')} onClick={showDialog} disabled={importing}>
            <ImportIcon/>
        </Button>
        <ContactImportDialog  onClose={hideDialog}/>
    </Fragment>;
};


const mapStateToProps = state => ({
    importing: state.contactImport.importing,
    dialog: state.contactImport
});

const mapDispatchToProps = (dispatch) => {
    return {
        showDialog: () => {
            dispatch(contactImportTransactionResetState());
            dispatch(contactImportTransactionToggleDialog(true));
        },
        hideDialog: () => {
            dispatch(contactImportTransactionToggleDialog(false));
            dispatch(reset('import-upload-form'));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportButton);