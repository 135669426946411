import i18next from 'i18next';

const validation = (values) => {
    const errors = {};

    if (!values.label) {
        errors.label = [i18next.t('%{field} is required', {field: i18next.t('Label')})];
    }

    return errors;
};

export default validation;