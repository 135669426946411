import React from 'react';
import {Edit} from 'react-admin';
import WebhookForm from "./WebhookForm";
import {withTranslation} from 'react-i18next';

const WebhookEdit = ({t, ...props}) => (
    <Edit {...props} title={t('Create webhook')}>
        <WebhookForm {...props} t={t}/>
    </Edit>
);

export default withTranslation()(WebhookEdit);
