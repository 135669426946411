import {httpClient} from './components/httpClient';
import httpErrorHandler from './components/http/httpErrorHandler';
import {entrypoint} from './config/config';

class API {
    static execute(uri, options) {
        return httpClient(uri, options).catch(({body, status}) => {
            return httpErrorHandler(status, body);
        })
    }

    static accountGetInfo() {
        return API.execute(`${entrypoint}/account/info`, {});
    }

    static accountGetStats() {
        return API.execute(`${entrypoint}/account/statistics`);
    }

    static campaignCountUniqueContacts(data) {
        return API.execute(`${entrypoint}/groups/count-unique`, {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then((response) => response)
            .catch(() => {
                return {
                    json: {
                        contacts: 0
                    }
                };
            });
    }

    static campaignDispatch(id) {
        return API.execute(`${entrypoint}/campaigns/${id}/launch`, {
            method: 'POST'
        });
    }

    static campaignFetch(id) {
        return API.execute(`${entrypoint}/campaigns/${id}`);
    }

    static campaignFetchLinks(id) {
        return API.execute(`${entrypoint}/campaigns/${id}/report/links`);
    }

    static campaignFetchList(id, event, page, perPage) {
        return API.execute(`${entrypoint}/campaigns/${id}/list/${event}?page=${(page + 1)}&perPage=${perPage}`);
    }

    static campaignFetchListClicks(link, page, perPage) {
        return API.execute(`${entrypoint}/campaign-clicks?link.id=${link}&page=${(page + 1)}&perPage=${perPage}`);
    }

    static contactImportCreateTransaction(data) {
        return API.execute(`${entrypoint}/contacts/import/transactions`, {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    static contactImportTransactionSendData(id, data) {
        return API.execute(`${entrypoint}/contacts/import/transactions/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        });
    }

    static contactImportTransactionProcess(id) {
        return API.execute(`${entrypoint}/contacts/import/transactions/${id}/process`, {
            method: 'POST'
        });
    }

    static contactImportTransactionReport(id) {
        return API.execute(`${entrypoint}/contacts/import/transactions/${id}/report`, {
            method: 'POST'
        });
    }

    static dashboardGetSentCampaigns() {
        return API.execute(`${entrypoint}/campaigns?state.value=done&order%5Breport.sendingFinishedAt%5D=DESC&page=1&perPage=5`);
    }

    static dashboardGetScheduledCampaigns() {
        return API.execute(`${entrypoint}/campaigns?state.value=scheduled&order%5BscheduledOn.dateTimeUtc%5D=ASC&page=1&perPage=5`);
    }

    static dashboardGetMessages() {
        return API.execute(`${entrypoint}/messages?order%5BupdatedAt%5D=DESC&page=1&perPage=5`);
    }

    static getAttributes() {
        return API.execute(`${entrypoint}/attributes`);
    }

    static getGroups() {
        return API.execute(`${entrypoint}/groups`);
    }

    static getMessage(id) {
        return API.execute(`${entrypoint}/messages/${id}`);
    }

    static getLandingPages(isPublic = false) {
        let filter = isPublic ? '?privacy.value=public' : '';

        return API.execute(`${entrypoint}/messages${filter}`)
            .then(
                data => {
                    if (data.json.hasOwnProperty('hydra:member')) {
                        return data.json['hydra:member'];
                    }

                    return []
                }
            )
    }

    static messageGetPreview(id) {
        return API.execute(`${entrypoint}/messages/${id}/preview`);
    }

    static messageAddToArchive(id) {
        return API.execute(`${entrypoint}/messages/${id}/archive`, {
            method: 'POST',
        });
    }

    static sendMessagePreview(id, to) {
        return API.execute(`${entrypoint}/messages/${id}/actions/preview/${to}`, {
            method: 'POST',
        });
    }

    static messageDuplicate(id, name) {
        return API.execute(`${entrypoint}/messages/${id}/duplicate`, {
            method: 'POST',
            body: JSON.stringify({
                name
            }),
        });
    }

    static messageRestoreFromArchive(id) {
        return API.execute(`${entrypoint}/messages/${id}/restore`, {
            method: 'POST',
        });
    }

    static campaignAddToArchive(id) {
        return API.execute(`${entrypoint}/campaigns/${id}/archive`, {
            method: 'POST',
        });
    }

    static campaignRestoreFromArchive(id) {
        return API.execute(`${entrypoint}/campaigns/${id}/restore`, {
            method: 'POST',
        });
    }

    static smtpMessage(id) {
        return API.execute(`${entrypoint}/smtp/messages/${id}`);
    }

    static smtpMessageSource(id) {
        return API.execute(`${entrypoint}/smtp/messages/${id}/source`);
    }

    static smtpStatistics(year, month) {
        return API.execute(`${entrypoint}/smtp/messages/statistics/${year}/${month}`);
    }

    static getToken(key) {
        return API.execute(`${entrypoint}/one_time_passwords/${key}`);
    }

    static resetUserPassword(email) {
        return API.execute(`${entrypoint}/admin/users/reset-credentials/${email}`, {
            method: 'POST',
        });
    }

    static setCredentials(key, password) {
        return API.execute(`${entrypoint}/admin/users/set-credentials`, {
            method: 'POST',
            body: JSON.stringify({key, password})
        });
    }
}

export default API;
