import React from 'react';
import {CardActions as BaseCardActions, CreateButton} from 'react-admin';

const CardActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showCreateButton = true,
                         showFilter,
                     }) => (
    <BaseCardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {showCreateButton && <CreateButton basePath={basePath}/>}
    </BaseCardActions>
);

export default CardActions;