import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TextField} from '@material-ui/core/';
import {campaignSelectCampaign} from '../../selectors/campaignSelector';
import {campaignChange} from '../../actions/campaignActions';
import CampaignInput from './CampaignInput';

const CampaignTextField = ({autoFocus = false, campaign, change, error = false, field, label, required = false, validate = null}) => {
    const [dirty, setDirty] = useState(false);

    return (
        <CampaignInput>
            <TextField autoFocus={autoFocus}
                       fullWidth
                       error={error || (validate && dirty && !validate(campaign[field]))}
                       id={field}
                       label={label}
                       onChange={(e) => {
                           setDirty(true);
                           change(field, e.target.value);
                       }}
                       required={required}
                       value={campaign ? campaign[field] : ''}
            />
        </CampaignInput>
    );
};

CampaignTextField.propTypes = {
    autoFocus: PropTypes.bool,
    campaign: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    error: PropTypes.bool,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

const mapStateToProps = state => ({
    campaign: campaignSelectCampaign(state)
});

const mapDispatchToProps = dispatch => ({
    change: (field, value) => dispatch(campaignChange(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignTextField);