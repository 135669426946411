import React from 'react';
import {
    BooleanInput,
    Filter,
    TextInput
} from 'react-admin';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {withTranslation} from 'react-i18next';

const WebhookFilter = ({t, ...props}) => (
    <Filter {...props}>
        <TextInput autoFocus label={t('Name')} source="name.value" />
        <TextInput autoFocus label={t('Target URL')} source="destination.url" />
        <BooleanInput label={t('State')}
                      format={v => v === 'enabled'}
                      parse={v => v ? 'enabled' : 'disabled'}
                      source="state.value"
                      options={{checkedIcon: <VerifiedUserIcon />}}

        />
    </Filter>
);

export default withTranslation()(WebhookFilter);
