import React from 'react';
import {connect} from 'react-redux';
import {
    Datagrid,
    EditButton,
    List,
    TextField,
} from 'react-admin';
import CardActions from '../common/CardActions';
import DateField from '../common/Field/DateField';
import {withTranslation} from 'react-i18next';
import ReceiverStateField from './ReceiverStateField';
import Details from './Details';

const ReceiverList = ({modules, dispatch, t, ...props}) => (
    <List title={t('Receivers')} {...props} sort={{field: 'name', order: 'ASC'}} actions={<CardActions/>}>
        <Datagrid>
            <TextField source={'name'} sortBy={'name.value'} label={t('Name')}/>
            <TextField source={'url'} label={t('url')}/>
            <ReceiverStateField label={t('State')} sortable={false} translate={t}/>
            <DateField source={'createdAt'} label={t('Created')}/>
            <EditButton/>
            <Details {...props}/>
        </Datagrid>
    </List>
);

const mapStateToProps = (state) => {
    return {
        modules: state.account.info.modules,
    }
};
export default connect(mapStateToProps)(withTranslation()(ReceiverList));
