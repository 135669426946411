import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DateField from "../common/Field/DateField";
import {useTranslation} from 'react-i18next';

const CampaignReportRate = (props) => {
    const {label, ratio, uniqueActions, totalActions, lastOccurrence} = props;
    const {t} = useTranslation();

    return (
        <Card raised={true}>
            <CardContent>
                <div style={{display: 'flex'}}>
                    <Typography variant={'title'} color={'primary'}>{`${ratio}%`}</Typography>
                    <Typography variant={'caption'} style={{marginLeft: '5px', fontWeight: 'bold'}}>{label}</Typography>
                </div>
                <LinearProgress value={ratio} variant={'determinate'}/>

                <Table style={{backgroundColor: 'rgb(238, 240, 251)'}}>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'caption'}>{t('Unique contacts')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'caption'} align={'right'}>{uniqueActions}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'caption'}>{t('Total actions')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'caption'} align={'right'}>{totalActions}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'caption'}>{t('Last occurrence')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'caption'} align={'right'}>
                                    {lastOccurrence
                                        ? <DateField label={''} source={'lastOccurrence'} record={{
                                                lastOccurrence: lastOccurrence
                                            }} style={{
                                                color: 'rgba(0, 0, 0, 0.54)',
                                                fontSize: '12px',
                                            }}/>
                                        :
                                        '/'
                                    }
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

CampaignReportRate.propTypes = {
    label: PropTypes.string.isRequired,
    ratio: PropTypes.number.isRequired,
    uniqueActions: PropTypes.number.isRequired,
    totalActions: PropTypes.number.isRequired,
    lastOccurrence: PropTypes.string,
};

export default CampaignReportRate;
