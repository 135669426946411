import React from 'react';
import PropTypes from 'prop-types';
import CampaignLaunchDialog from './CampaignLaunchDialog';

class CampaignLaunchDialogContainer extends React.Component {
    state = {
        open: true,
        launching: false,
        recipients: null,
        launchDisabled: true
    };

    constructor(props) {
        super(props);

        // initialize state
        this.state.open = props.open;
        this.state.recipients = props.recipients;

        // bind functions
        this.validateRecipients = this.validateRecipients.bind(this);
        this.launch = this.launch.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.recipients !== nextProps.recipients) {
            this.setState({recipients: nextProps.recipients});
        }
    }

    // Modal, display functions
    display = (open) => (
        this.setState(Object.assign({}, this.state, {open: open}))
    );

    close = () => {
        this.props.hideLaunchDialog();
        this.display(false);
    };

    validateRecipients(e) {
        this.setState({
            launchDisabled: (this.state.recipients !== Number(e.target.value))
        });
    }

    launch() {
        this.setState({launching:  true});
        this.props.launch();
    }

    render() {
        const {recipients, title} = this.props;
        const {launching, launchDisabled} = this.state;
        const open = (!this.props.open) ? this.state.open : true;

        return <CampaignLaunchDialog isLaunching={launching}
                                     launchDisabled={launchDisabled}
                                     onClose={this.close}
                                     onLaunch={this.launch}
                                     open={open}
                                     validateRecipients={this.validateRecipients}
                                     recipients={recipients}
                                     title={title}
        />

    };
}

CampaignLaunchDialogContainer.propTypes = {
    hideLaunchDialog: PropTypes.func,
    launch: PropTypes.func,
    open: PropTypes.bool,
    recipients: PropTypes.number,
    title: PropTypes.string,
};

export default CampaignLaunchDialogContainer;