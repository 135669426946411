import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import MessagesIcon from '@material-ui/icons/ArtTrack';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import {withTranslation} from 'react-i18next';
import CardWithBackground from "../common/CardWithBackground";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none'
    },
    avatar: {
        backgroundColor: green[500],
    },
}));

const DashboardRecentMessages = (props) => {
    const classes = useStyles();
    const {messages, messagesLoading, t} = props;
    return (
        <CardWithBackground>
            <CardHeader title={t('Last modified messages')}
                        avatar={<Avatar className={classes.avatar}>
                            <MessagesIcon/>
                        </Avatar>}/>
            {!messagesLoading &&
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Message')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages.map(message => (
                            <TableRow hover key={message.id}>
                                <TableCell>
                                    <Link to={`/messages/${message.id}`} className={classes.link}>
                                        <Typography variant={'body1'}>{message.name}</Typography>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
            }
            {messagesLoading && <LinearProgress/>}
        </CardWithBackground>
    );
};

DashboardRecentMessages.propTypes = {
    messages: PropTypes.array.isRequired,
    messagesLoading: PropTypes.bool.isRequired,
};

export default withTranslation()(DashboardRecentMessages);
