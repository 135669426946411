import React from 'react';
import {
    Filter,
    TextInput,
} from 'react-admin';
import {useTranslation} from 'react-i18next';

const AttributeFilter = (props) => {
    const {t} = useTranslation();

    return (
        <Filter {...props}>
            <TextInput autoFocus label={t('Name')} source={'name.value'} />
        </Filter>
    );
}

export default AttributeFilter;