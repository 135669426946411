import React from 'react';
import {connect} from 'react-redux';
import CampaignReport from "./CampaignReport";

const CampaignReportContainer = (props) => (
    props.campaign && props.campaign.message ? <CampaignReport {...props}/> : null
);

const mapStateToProps = (state) => {
    return {
        campaign: state.campaign.report,
        reportLoading: !state.campaign.report
    }
};

export default connect(mapStateToProps)(CampaignReportContainer);