import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button} from 'react-admin';
import ArchiveIcon from '@material-ui/icons/Archive';
import {refreshView} from 'react-admin';
import {setListSelectedIds} from "../../../actions/reactAdmin";
import {addToArchive} from "../../../actions/commonActions";

class BulkArchiveButton extends React.Component {
    handleClick = () => {
        const {addToArchive, refreshView, resource, selectedIds, setListSelectedIds} = this.props;

        selectedIds.map((id) => (
            addToArchive(resource, id)
        ));

        refreshView();
        setListSelectedIds(resource, []);
    };

    render = () => {
        const {label} = this.props;

        return (<Button label={label} onClick={this.handleClick}><ArchiveIcon/></Button>);
    };
}

BulkArchiveButton.defaultProps = {
    label: 'Archive'
};

BulkArchiveButton.propTypes = {
    label: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    addToArchive: (resource, id) => dispatch(addToArchive(resource, id)),
    setListSelectedIds: (resource, ids) => dispatch(setListSelectedIds(resource, ids)),
    refreshView: () => dispatch(refreshView()),
});

export default connect(null, mapDispatchToProps)(BulkArchiveButton);