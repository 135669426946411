import i18n from 'i18next';
import Polyglot from "i18next-polyglot";
import {initReactI18next} from 'react-i18next';
import {getLocale} from './util/i18n';
import en from './i18n/en';
import nl from './i18n/nl';

const resources = {
    en: en,
    nl: nl,
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Polyglot)
    .init({
        resources,
        lng: getLocale(),
        nsSeparator: '>',

        keySeparator: false, // we do not use keys in form messages.'e

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
