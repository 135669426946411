import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Field, reduxForm, change} from 'redux-form';
import {changeLocale} from 'react-admin';
import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Paper,
    Toolbar,
    Typography,
    TextField,
} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import theme from "../../config/theme";
import {withTranslation} from 'react-i18next';
import i18n from "i18next";

const styles = () => ({
    card: {
        minWidth: '300px',
        margin: 'auto',
        width: '300px',
    },
    pullRight: {
        marginLeft: 'auto',
        marginRight: '10px',
    }
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
                         meta: {touched, error} = {touched: false, error: ''}, // eslint-disable-line react/prop-types
                         input: {...inputProps}, // eslint-disable-line react/prop-types
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

class CredentialsForm extends React.Component {
    constructor(props) {
        super(props);

        props.dispatch(change('setCredentials', 'key', props.token));
    }

    render() {
        const {classes, isLoading, handleSubmit, t} = this.props;

        return (
            <React.Fragment>
                <MuiThemeProvider theme={theme}>
                    <AppBar position={'static'} color={'secondary'}>
                        <Toolbar>
                            <Typography variant={'title'} color={'inherit'}>
                                mynuma
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Paper
                        style={{
                            width: '100%',
                            height: '100vh',
                            backgroundColor: '#f3f9fe',
                            margin: 0,
                            paddingTop: '6em'
                        }}
                        elevation={0}>
                        <Card className={classes.card}>
                            <form onSubmit={handleSubmit}>
                                <CardContent>
                                    <Field autoFocus
                                           id={'password'}
                                           name={'password'}
                                           label={t('Password')}
                                           component={renderInput}
                                           type={'password'}
                                    />
                                    <Field id={'confirmPassword'}
                                           name={'confirmPassword'}
                                           label={t('Confirm password')}
                                           component={renderInput}
                                           type={'password'}
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button variant="raised"
                                            type="submit"
                                            color="primary"
                                            disabled={isLoading}
                                            fullWidth>
                                        {isLoading && (
                                            <CircularProgress
                                                className={classes.icon}
                                                size={18}
                                                thickness={2}
                                                style={{marginRight: '10px'}}
                                            />
                                        )}
                                        {t('Reset password')}
                                    </Button>
                                </CardActions>
                            </form>
                        </Card>
                    </Paper>
                </MuiThemeProvider>
                {/* <Notifications/> */}
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    changeLocale: (locale) => dispatch(changeLocale(locale)),
});

const enhance = compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
    withTranslation(),
    reduxForm({
            form: 'setCredentials',
            validate: (values, props) => {
                const errors = {username: '', password: ''};
                if (!values.password) {
                    errors.password = i18n.t('Required');
                } else if (values.password.length < 10) {
                    errors.password = i18n.t('Password too short', {'min': 10});
                } else if (null === values.password.match(/[a-z]/)) {
                    errors.password = i18n.t('Password requires at least one lower case character');
                } else if (null === values.password.match(/[A-Z]/)) {
                    errors.password = i18n.t('Password requires at least one upper case character');
                } else if (null === values.password.match(/[0-9]/)) {
                    errors.password = i18n.t('Password requires at least on numeric character');
                } else if (null === values.password.match(/[\W]/)) {
                    errors.password = i18n.t('Password require at least on special character');
                } else if (!values.confirmPassword) {
                    errors.confirmPassword = i18n.t('Required');
                } else if (values.confirmPassword !== values.password) {
                    errors.confirmPassword = i18n.t('Passwords do not match');
                }

                return errors;
            },
        },
    ),
);

CredentialsForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
};

export default enhance(CredentialsForm);
