import {
    CAMPAIGN_DISPATCHED,
    CAMPAIGN_LAUNCH,
    CAMPAIGN_REPORT_FETCHED,
    CAMPAIGN_REPORT_FETCHING,
    CAMPAIGN_REPORT_LIST_CHANGE_PAGE,
    CAMPAIGN_REPORT_LIST_FETCHED,
    CAMPAIGN_REPORT_LIST_FETCHING,
    CAMPAIGN_REPORT_LIST_SET_CAMPAIGN_ID,
    CAMPAIGN_REPORT_LIST_SET_EVENT,
    CAMPAIGN_REPORT_LIST_SET_ROWS_PER_PAGE,
    CAMPAIGN_SET_SEND_METHOD,
    CAMPAIGN_UNIQUE_CONTACTS_COUNTED,
    CAMPAIGN_UNIQUE_CONTACT_COUNT_FETCH,
    CAMPAIGN_CHANGE,
    CAMPAIGN_WIZARD_INITIALIZE,
    CAMPAIGN_WIZARD_FETCH_SENDERS,
    CAMPAIGN_WIZARD_SET_SENDERS,
    CAMPAIGN_WIZARD_FETCH_MESSAGES,
    CAMPAIGN_WIZARD_SET_MESSAGES,
    CAMPAIGN_WIZARD_FETCH_GROUPS,
    CAMPAIGN_WIZARD_SET_GROUPS,
    CAMPAIGN_WIZARD_SAVE,
    CAMPAIGN_WIZARD_SAVE_ERROR,
    CAMPAIGN_WIZARD_SAVE_SUCCESS,
    CAMPAIGN_WIZARD_FETCH_TAGS,
    CAMPAIGN_WIZARD_SET_TAGS,
    CAMPAIGN_WIZARD_SET_ATTRIBUTES,
    CAMPAIGN_WIZARD_SET_CAMPAIGNS,
    CAMPAIGN_WIZARD_SET_FILTERS,
    CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECTED,
    CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCH,
    CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCHED, CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECT,
} from './actionTypes';

export const campaignChange = (field, value) => ({
    type: CAMPAIGN_CHANGE,
    meta: {
        field,
    },
    payload: value,
});

export const campaignDispatch = () => ({
    type: CAMPAIGN_DISPATCHED
});

export const campaignLaunch = (id) => ({
    type: CAMPAIGN_LAUNCH,
    id: id,
});

export const campaignReportFetched = (report) => ({
    type: CAMPAIGN_REPORT_FETCHED,
    payload: { report },
});

export const campaignReportFetching = () => ({
    type: CAMPAIGN_REPORT_FETCHING
});

export const campaignReportListChangePage = (page) => ({
    type: CAMPAIGN_REPORT_LIST_CHANGE_PAGE,
    payload: { page }
});

export const campaignReportListFetched = (report) => ({
    type: CAMPAIGN_REPORT_LIST_FETCHED,
    payload: { report },
});

export const campaignReportListFetching = () => ({
    type: CAMPAIGN_REPORT_LIST_FETCHING
});

export const campaignReportListSetCampaignId = (id) => ({
    type: CAMPAIGN_REPORT_LIST_SET_CAMPAIGN_ID,
    payload: { id },
});

export const campaignReportListSetEvent = (event) => ({
    type: CAMPAIGN_REPORT_LIST_SET_EVENT,
    payload: { event },
});

export const campaignReportListSetRowsPerPage = (rows) => ({
    type: CAMPAIGN_REPORT_LIST_SET_ROWS_PER_PAGE,
    payload: { rows },
});

export const campaignSetSendMethod = (type) => ({
    type: CAMPAIGN_SET_SEND_METHOD,
    payload: {
        method: type
    }
});

export const campaignUniqueContactsCount = (numberOfContacts) => ({
    type: CAMPAIGN_UNIQUE_CONTACTS_COUNTED,
    payload: {
        'unique-contacts': numberOfContacts
    }
});

export const campaignUniqueContactsCountFetch = () => ({
    type: CAMPAIGN_UNIQUE_CONTACT_COUNT_FETCH
});

export const campaignWizardFetchGroups = () => ({type: CAMPAIGN_WIZARD_FETCH_GROUPS});
export const campaignWizardFetchMessages = () => ({type: CAMPAIGN_WIZARD_FETCH_MESSAGES});
export const campaignWizardFetchSenders = () => ({type: CAMPAIGN_WIZARD_FETCH_SENDERS});
export const campaignWizardFetchTags = () => ({type: CAMPAIGN_WIZARD_FETCH_TAGS});
export const campaignWizardInitialize = () => ({type: CAMPAIGN_WIZARD_INITIALIZE});
export const campaignWizardSave = (redirect, recipientsVerified) => ({
    type: CAMPAIGN_WIZARD_SAVE,
    meta: {
        redirect,
        recipientsVerified,
    }
});
export const campaignWizardSaveError = (errors) => ({type: CAMPAIGN_WIZARD_SAVE_ERROR, payload: errors});
export const campaignWizardSaveSuccess = () => ({type: CAMPAIGN_WIZARD_SAVE_SUCCESS});
export const campaignWizardSetGroups = (groups) => ({type: CAMPAIGN_WIZARD_SET_GROUPS, payload: groups});
export const campaignWizardSetMessages = (messages) => ({type: CAMPAIGN_WIZARD_SET_MESSAGES, payload: messages});
export const campaignWizardSetSenders = (senders) => ({type: CAMPAIGN_WIZARD_SET_SENDERS, payload: senders});
export const campaignWizardSetTags = (tags) => ({type: CAMPAIGN_WIZARD_SET_TAGS, payload: tags});
export const campaignWizardSetAttributes = (attributes) => ({type: CAMPAIGN_WIZARD_SET_ATTRIBUTES, payload: attributes});
export const campaignWizardSetCampaigns = (campaigns) => ({type: CAMPAIGN_WIZARD_SET_CAMPAIGNS, payload: campaigns});

export const campaignWizardFilterCampaignSelect = (campaign) => ({type: CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECT, payload: campaign});
export const campaignWizardFilterCampaignSelected = (campaign) => ({type: CAMPAIGN_WIZARD_FILTER_CAMPAIGN_SELECTED, payload: campaign});
export const campaignWizardCampaignLinksFetch = (campaign) => ({type: CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCH, payload: campaign});
export const campaignWizardCampaignLinksFetched = (links) => ({type: CAMPAIGN_WIZARD_CAMPAIGN_LINKS_FETCHED, payload: links});

export const campaignWizardSetFilters = (filters) => ({type: CAMPAIGN_WIZARD_SET_FILTERS, payload: filters})
