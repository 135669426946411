import React from 'react';
import {useTranslation} from 'react-i18next';

const AttributeTitle = ({record}) => {
    const {t} = useTranslation();

    return (
        <span>{t('Attribute # %{name}', {name: record.name})}</span>
    );
}

export default AttributeTitle;