import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin'
import {regex} from 'react-admin';
import validation from './validation';
import {withTranslation} from 'react-i18next';

const validateUrl = regex(/https:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?/);

const ReceiverCreate = ({t, ...props}) => (
    <Create {...props} title={t('Create sender')}>
        <SimpleForm redirect={'/receivers'} validate={validation}>
            <TextInput source={'name'} label={t('Name')} autoFocus fullWidth/>
            <TextInput source={'url'} label={t('Url')} validate={validateUrl} fullWidth/>
        </SimpleForm>
    </Create>
);


export default withTranslation()(ReceiverCreate);