import React from 'react';
import {
    BooleanInput,
    Create,
    SimpleForm,
    TextInput
} from 'react-admin';
import {useTranslation} from 'react-i18next';

const MessageCreate = (props) => {
    const {t} = useTranslation();
    const defaultValues = {
        html: '',
        text: '',
        builder: 'structured',
    };

    return (
        <Create {...props}>
            <SimpleForm defaultValue={defaultValues}>
                <TextInput autoFocus label={t('Name')} source={'name'} fullWidth/>
                <BooleanInput
                    source={'builder'}
                    label={t('I want to edit the HTML myself')}
                    format={(v) => v === 'html'}
                    parse={(v) => v ? 'html' : 'structured'}
                    fullWidth
                />
            </SimpleForm>
        </Create>
    );
}

export default MessageCreate;