import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {useTranslation} from 'react-i18next';

const options = {
    chart: {
        type: 'line',
        height: 350
    },
    colors:['#8693AB', '#274690', '#71B243', '#FF756D'],
    stroke: {
        curve: 'smooth',
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        hover: {
            sizeOffset: 4
        }
    }
};

const SmtpChart = ({data: {sent, read, bounced, visited}}) => {
    const {t} = useTranslation();

    // remove month totals
    const [, ...sentDaily] = sent;
    const [, ...readDaily] = read;
    const [, ...bouncedDaily] = bounced;
    const [, ...visitedDaily] = visited;

    const series = [
        {
            data: sentDaily,
            name: t('Sent'),
        },
        {
            data: readDaily,
            name: t('Read'),
        },
        {
            data: visitedDaily,
            name: t('Clicked'),
        },
        {
            data: bouncedDaily,
            name: t('Bounced'),
        },
    ];

    return (
        <div id="chart">
            <Chart options={options} series={series} type="line" height={350}/>
        </div>
    );
}

SmtpChart.propTypes = {
    data: PropTypes.object.isRequired,
}

export default SmtpChart;
