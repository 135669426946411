import {call, put, select, takeLatest} from 'redux-saga/effects';
import {CREATE, GET_ONE, GET_LIST, UPDATE, showNotification} from 'react-admin';
import {SIGNUP_HIDE_REQUESTED, SIGNUP_SAVE, SIGNUP_SHOW} from '../actions/actionTypes';
import ldjsonServer from '../components/ldjsonServer';
import {entrypoint} from '../config/config';
import {httpClient} from '../components/httpClient';
import {
    signupFetchForm,
    signupFetchGroups,
    signupFetchSenders,
    signupHide,
    signupSaved,
    signupSetForm,
    signupSetGroup,
    signupSetSenders,
    signupShowCancelConfirmation
} from '../actions/signupActions';
import {signupSelectForm, signupSelectUiIsDirty} from "../selectors/signupSelector";
import i18n from 'i18next';

const dataProvider = ldjsonServer(entrypoint, httpClient);

export function* fetchGroup(action) {
    try {
        yield put(signupFetchGroups());
        const {data} = yield call(dataProvider, GET_ONE, 'groups', {id: action.group});
        if (data.signUpForm) {
            yield call(fetchSignupForm, data.signUpForm.split('/').pop());
        }
        yield put(signupSetGroup(data));
    } catch (exception) {
        yield put(signupHide());
        yield put(showNotification(i18n.t('Group exception: %{message}', {message: exception.message})));
    }
}

export function* fetchSignupForm(id) {
    try {
        yield put(signupFetchForm());
        const {data} = yield call(dataProvider, GET_ONE, 'sign_up_forms', {id});
        yield put(signupSetForm(data));
    } catch (exception) {
        yield put(signupHide());
        yield put(showNotification(i18n.t('Form exception: %{message}', {message: exception.message})));
    }
}

export function* fetchSenders() {
    try {
        yield put(signupFetchSenders());
        const response = yield call(dataProvider, GET_LIST, 'campaign/senders', {
            filter: {
                state: true,
            },
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        });

        if (response.data.length === 0) {
            yield put(signupHide());
            yield put(showNotification(i18n.t('You need to define a sender first')));
        }

        yield put(signupSetSenders(response.data));
    } catch (exception) {
        yield put(signupHide());
        yield put(showNotification(i18n.t('Senders exception: %{message}', {message: exception.message})));
    }
}

export function* save(action) {
    const data = yield select(signupSelectForm);
    let response = {};
    try {
        if (data.id) {
            response = yield call(dataProvider, UPDATE, 'sign_up_forms', {
                data: data,
                id: data.id,
            })
        } else {
            response = yield call(dataProvider, CREATE, 'sign_up_forms', {data})
        }

        if (action.hide) {
            yield put(signupHide());
        } else {
            yield put(signupSetForm(response.data));
        }
    } catch (exception) {
        alert(i18n.t('Something went wrong: %{message}', {message: exception.message}));
    }
    yield put(signupSaved());
}

export function* hideRequested() {
    const isDirty = yield select(signupSelectUiIsDirty);

    if (isDirty) {
        yield put(signupShowCancelConfirmation());
    } else {
        yield put(signupHide());
    }
}

export function* signupSagas() {
    yield takeLatest(SIGNUP_SHOW, fetchGroup);
    yield takeLatest(SIGNUP_SHOW, fetchSenders);
    yield takeLatest(SIGNUP_SAVE, save);
    yield takeLatest(SIGNUP_HIDE_REQUESTED, hideRequested);
}
