import React from 'react';
import {
    BooleanInput,
    Filter,
    TextInput,
} from 'react-admin';
import {useTranslation} from 'react-i18next';

const MessageFilter = (props) => {
    const {t} = useTranslation();

    return (
        <Filter {...props}>
            <TextInput
                autoFocus
                label={t('Name')}
                source={'name.value'}
            />
            <BooleanInput
                alwaysOn
                label={t('In archive')}
                source={'inArchive'}
            />
            <BooleanInput
                alwaysOn
                label={t('Landing pages')}
                source={'privacy.value'}
                parse={value =>  value ? 'public' : 'private'}
                format={value => value === 'public'}
                />
        </Filter>
    );
}

export default MessageFilter;
