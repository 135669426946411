import { put, select, takeEvery } from 'redux-saga/effects';
import API from "../api";

import {
    contactImportTransactionCreated,
    contactImportTransactionImported,
    contactImportTransactionImporting,
    contactImportTransactionSetAttributes,
    contactImportTransactionSetData,
    contactImportTransactionSetProgressMessage,
    contactImportTransactionSetProgressValue
} from "../actions/contactImportActions";

import {
    CONTACT_IMPORT_TRANSACTION_CREATE,
    CONTACT_IMPORT_TRANSACTION_TOGGLE_UI
} from '../actions/actionTypes';

import {
    attributesSelector,
    dataSelector,
    duplicateActionSelector,
    filenameSelector,
    groupSelector,
    mapSelector
} from '../selectors/contactImportSelectors';

export function* fetchData() {
    try {

    } catch (error) {
        yield console.log(error);
    }
}

export function* fetchAttributes(action) {
    try {
        if (false === action.visible) {
            return true;
        }

        const attributes = yield select(attributesSelector);

        if (null !== attributes) {
            return true;
        }

        const response = yield API.getAttributes();
        const {status, json } = response;
        if (status !== 200) {
            new Error('Could not retrieve attributes');
        }
        yield put(contactImportTransactionSetAttributes(json['hydra:member']));
    } catch (error) {
        yield console.log(error);
    }
}

export function* createContactImportTransaction() {
    try {
        const groupId = yield select(groupSelector);
        const map = yield select(mapSelector);
        const filename = yield select(filenameSelector);
        const duplicateAction = yield select(duplicateActionSelector);
        const data = {duplicateAction, groupId, map, filename};

        yield put(contactImportTransactionSetProgressMessage('Initializing data transaction'));
        const response = yield API.contactImportCreateTransaction(data);
        const {status} = response;

        if (status !== 201) {
            new Error('A transactions could not be create');
        }

        const {json: {id}} = response;

        yield put(contactImportTransactionCreated(id));

        let contacts = yield select(dataSelector);
        let send = [];

        yield put(contactImportTransactionSetProgressValue(10));
        yield put(contactImportTransactionSetProgressMessage('Transmitting data to server'));
        yield put(contactImportTransactionImporting());

        while (contacts.length > 0) {
            send = {data: contacts.splice(0, 100)};
            yield API.contactImportTransactionSendData(id, send);
            yield put(contactImportTransactionSetData(contacts));
            contacts = yield select(dataSelector);
        }
        yield put(contactImportTransactionImported());
        yield put(contactImportTransactionSetProgressValue(50));
        yield put(contactImportTransactionSetProgressMessage('Starting transaction'));

       yield API.contactImportTransactionProcess(id);

        yield put(contactImportTransactionSetProgressValue(55));
        yield put(contactImportTransactionSetProgressMessage('Processing on server'));

    } catch (error) {
        yield console.log(error);
    }
}

export function* watchForContactImportDialog() {
    yield takeEvery(CONTACT_IMPORT_TRANSACTION_TOGGLE_UI, fetchAttributes);
    yield takeEvery(CONTACT_IMPORT_TRANSACTION_CREATE, createContactImportTransaction);
}