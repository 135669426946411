import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const CampaignTitle = ({campaign: {name}}) => {
    const {t} = useTranslation();

    return (
        <span>{t('Campaign # %{name}', {name})}</span>
    );
}

CampaignTitle.propTypes = {
    campaign: PropTypes.object.isRequired,
};

export default CampaignTitle;
