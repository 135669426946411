import React from 'react';
import {Edit} from 'react-admin';
import TagForm from './TagForm';
import TagTitle from './TagTitle';

const TagEdit = props => (
    <Edit {...props} title={<TagTitle/>}>
        <TagForm/>
    </Edit>
);

export default TagEdit;