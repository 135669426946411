import React from 'react';
import {Edit, LinearProgress, SimpleForm, TextInput} from 'react-admin'
import {connect} from 'react-redux';
import {change} from 'redux-form';
import ContactTitle from './ContactTitle'
import {withTranslation} from 'react-i18next';

const LoadingAttributes = ({attributes}) => (
    !attributes ? <LinearProgress style={{width: '100%'}}/> : null
);

class ContactEdit extends React.Component {
    getRedirectUrl = (form) => {
        if (form.hasOwnProperty('record-form')
            && form['record-form'].values
            && form['record-form'].values.group) {
            return `/contacts?group=${form['record-form'].values.group.split('/').pop()}`;
        }

        return null;
    };

    render() {
        const {attributes, change, dispatch, form, t, ...props} = this.props;
        const redirect = this.getRedirectUrl(form);

        return <React.Fragment>
            <Edit {...props} title={<ContactTitle/>}>
                <SimpleForm redirect={redirect}>
                    <React.Fragment>
                        <TextInput autoFocus label={t('Email')} source={'email'} record={this.props.record} fullWidth/>
                        {attributes && attributes.map((attribute) =>
                            <TextInput fullWidth={true}
                                       key={attribute.id}
                                       label={attribute.name}
                                       source={`attribute_${attribute.id}`}/>
                        )}
                        <LoadingAttributes attributes={attributes}/>
                    </React.Fragment>
                </SimpleForm>
            </Edit>
        </React.Fragment>;
    }
}

function mapStateToProps(state) {
    return {
        form: state.form,
        attributes: state.account.data.attributes,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch: dispatch,
        change: (form, field, value) => dispatch(change(form, field, value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactEdit));