import React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const MessageEditLinks = ({links, toggleEnabled}) => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            {links.length > 0 && <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding={'checkbox'}>
                            {t('Enabled')}
                        </TableCell>
                        <TableCell>
                            {t('Link')}
                        </TableCell>
                        <TableCell>
                            {t('Location')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {links.map(
                        link => (
                            <TableRow
                                id={link.url}
                                key={link.url}
                            >
                                <TableCell>
                                    <Checkbox
                                        color={'primary'}
                                        data-id={link.url}
                                        checked={link.enabled}
                                        onChange={toggleEnabled.bind(null, link.url)}
                                    />
                                </TableCell>
                                <TableCell>
                                    {link.url}
                                </TableCell>
                                <TableCell>
                                    {link.in.join(', ')}
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>}
            {links.length === 0 &&
            <Typography
                paragraph={true}
                style={{
                    marginTop: '20px'
                }}
                variant={'caption'}
            >
                {t('No links in message')}
            </Typography>}
        </React.Fragment>
    );
}

MessageEditLinks.propTypes = {
    links: PropTypes.array.isRequired,
};

export default MessageEditLinks