import React from 'react';
import {
    CardActions,
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    List,
    TextField,
} from 'react-admin';
import UserFilter from "./UserFilter";
import SubscriptionsButton from "./SubscriptionsButton";

const UserListActions = ({
                             basePath,
                             displayedFilters,
                             filters,
                             filterValues,
                             resource,
                             showFilter
                         }) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath}/>
    </CardActions>
);

export const UserList = (props) => (
    <List {...props} filters={<UserFilter/>} sort={{field: 'createdAt', order: 'DESC'}} actions={<UserListActions/>}>
        <Datagrid>
            <TextField source={'company'} label={'Company'} sortBy={'profile.company'}/>
            <TextField source={'email'} label={'Mail'} sortBy={'email'}/>
            <TextField source={'firstName'} label={'First Name'} sortBy={'profile.firstName'}/>
            <TextField source={'lastName'} label={'Last Name'} sortBy={'profile.lastName'}/>
            <DateField source={'createdAt'} label={'Created at'} sortBy={'createdAt'} locales={'fr-FR'}/>
            <SubscriptionsButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export default UserList;