import React from 'react';
import {Create} from 'react-admin';
import AttributeForm from './AttributeForm';
import {useTranslation} from 'react-i18next';

const AttributeCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create attribute')}>
            <AttributeForm/>
        </Create>
    );
}

export default AttributeCreate;