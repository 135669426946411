import produce from 'immer';
import {
    SIGNUP_FETCH_FORM,
    SIGNUP_FETCH_GROUPS, SIGNUP_FETCH_SENDERS,
    SIGNUP_FORM_CHANGE,
    SIGNUP_HIDE, SIGNUP_SAVE, SIGNUP_SAVED, SIGNUP_SET_FORM, SIGNUP_SET_GROUP, SIGNUP_SET_SENDERS,
    SIGNUP_SHOW, SIGNUP_SHOW_CANCEL_CONFIRMATION
} from '../actions/actionTypes';
import i18n from 'i18next';

const initialState = {
    form: {
        id: null,
        group: null,
        sender: 'none',
        alreadyConfirmedPage: {
            title: i18n.t('Sign up re-confirmed'),
            text: i18n.t('You where already confirmed! Thanks for being awesome!'),
        },
        alreadySignedUpPage: {
            title: i18n.t('Whoops'),
            text: i18n.t('You are already a member of out mailing list! You are awesome!'),
        },
        confirmationPage: {
            title: i18n.t('Sign up confirmed'),
            text: i18n.t('You have successfully subscribed to our mailing list. Welcome!'),
        },
        signUpPage: {
            title: i18n.t('Signed up!'),
            text: i18n.t('You have successfully signed up to our mailing list. You will receive a confirmation mail to confirm your subscription!'),
        },
        confirmationMail: {
            subject: i18n.t('Welcome to our mailing list'),
            title: i18n.t('Welcome to our mailing list!'),
            text: i18n.t('Please click the link below to confirm your subscription?!'),
        }
    },
    group: {
        id: null,
        name: null,
    },
    senders: [],
    ui: {
        cancelConfirmation: {
            visible: false
        },
        dirty: false,
        loading: 0,
        saving: false,
        visible: false,
    },
};

const signupReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            // ui cases
            case SIGNUP_SHOW:
                draft.ui.visible = true;
                break;

            case SIGNUP_HIDE:
                return initialState;

            // loading
            case SIGNUP_FETCH_FORM:
            case SIGNUP_FETCH_GROUPS:
            case SIGNUP_FETCH_SENDERS:
                draft.ui.loading = draft.ui.loading + 1;
                break;

            // group cases
            case SIGNUP_SET_GROUP:
                draft.group.id = action.group.id;
                draft.group.name = action.group.name;
                draft.form.group = `/groups/${action.group.id}`;
                draft.ui.loading = draft.ui.loading - 1;
                break;

            // sender cases
            case SIGNUP_SET_SENDERS:
                draft.senders = action.senders;
                draft.ui.loading = draft.ui.loading - 1;

                if ('none' === draft.form.sender) {
                    draft.form.sender = action.senders[0]['@id'];
                }

                break;

            // form cases
            case SIGNUP_SET_FORM:
                if (draft.ui.loading > 0) {
                    draft.ui.loading = draft.ui.loading - 1;
                }
                if (action.form.id) {
                    draft.form.id = action.form.id;
                }
                if (action.form['@id']) {
                    draft.form.id = action.form['@id'].split('/').pop();
                }
                draft.form.group = action.form.group;
                draft.form.sender = action.form.sender;
                draft.form.alreadyConfirmedPage.title = action.form.alreadyConfirmedPage.title;
                draft.form.alreadyConfirmedPage.text = action.form.alreadyConfirmedPage.text;
                draft.form.alreadySignedUpPage.title = action.form.alreadySignedUpPage.title;
                draft.form.alreadySignedUpPage.text = action.form.alreadySignedUpPage.text;
                draft.form.confirmationPage.title = action.form.confirmationPage.title;
                draft.form.confirmationPage.text = action.form.confirmationPage.text;
                draft.form.signUpPage.title = action.form.signUpPage.title;
                draft.form.signUpPage.text = action.form.signUpPage.text;
                draft.form.confirmationMail.subject = action.form.confirmationMail.subject;
                draft.form.confirmationMail.title = action.form.confirmationMail.title;
                draft.form.confirmationMail.text = action.form.confirmationMail.text;
                break;

            case SIGNUP_FORM_CHANGE:
                draft.ui.dirty = true;

                switch (action.payload.category) {
                    case 'alreadySignedUpPage':
                        draft.form.alreadySignedUpPage[action.payload.field] = action.payload.value;
                        break;

                    case 'alreadyConfirmedPage':
                        draft.form.alreadyConfirmedPage[action.payload.field] = action.payload.value;
                        break;

                    case 'confirmationPage':
                        draft.form.confirmationPage[action.payload.field] = action.payload.value;
                        break;

                    case 'signUpPage':
                        draft.form.signUpPage[action.payload.field] = action.payload.value;
                        break;

                    case 'confirmationMail':
                        draft.form.confirmationMail[action.payload.field] = action.payload.value;
                        break;

                    default:
                        draft.form[action.payload.field] = action.payload.value;
                }
                break;

            case SIGNUP_SHOW_CANCEL_CONFIRMATION:
                draft.ui.cancelConfirmation.visible = true;
                break;

            case SIGNUP_SAVE:
                draft.ui.saving = true;
                break;

            case SIGNUP_SAVED:
                draft.ui.dirty = false;
                draft.ui.saving = false;
                break;

            // no default
        }
    })
};

export default signupReducer;
