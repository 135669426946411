import React, {useState} from 'react';
import {Button} from 'react-admin';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from '@material-ui/core';
import {withTranslation} from "react-i18next";

const Details = ({record: {email, url, secret}, t}) => {
    const [open, setOpen] = useState(false);
    const [showUsername, setShowUsername] = useState(false);

    return <React.Fragment>
        <Dialog maxWidth={'lg'} open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{t('Receiver Credentials')}</DialogTitle>
            <DialogContent>
                <Typography variant={'caption'} paragraph={true}>
                    {t('Authentication matches username, password AND from address.')}
                </Typography>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {t('Email')}
                            </TableCell>
                            <TableCell>
                                <Typography component={'div'} style={{
                                    paddingLeft: '48px'
                                }}>{email}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('Url')}</TableCell>
                            <TableCell><Typography component={'div'} style={{
                                paddingLeft: '48px'
                            }}>{url}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('Secret')}</TableCell>
                            <TableCell>
                                <IconButton aria-label={t('Show secret')} onClick={() => setShowUsername(!showUsername)}>
                                    {showUsername ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                                {showUsername ? secret : '...'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
        <Button label={t('Details')} onClick={() => setOpen(true)}><Visibility/></Button>
    </React.Fragment>;
}

export default withTranslation()(Details);