import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: red[500],
    }
}));

const DashboardSmtpSubscriptionCard = (props) => {
    const classes = useStyles();
    const {messageCount, messageLimit, infoLoading, isOvermessageLimit, subscriptionType} = props;

    return (
        <Card>
            <CardHeader avatar={
                <Avatar aria-label={'Subscription'} className={classes.avatar}>S</Avatar>
            } title={'Subscription'} subheader={subscriptionType}/>
            {!infoLoading &&
            <CardContent >
                <Typography variant={'body1'}>{`Contacts: ${messageCount.toLocaleString()} / ${messageLimit.toLocaleString()}`}</Typography>
                {isOvermessageLimit && <Typography color={'primary'}>Your account has used more than the allowed amount of contacts.</Typography>}
            </CardContent>}
            {infoLoading && <LinearProgress />}
        </Card>);
};

DashboardSmtpSubscriptionCard.propTypes = {
    messageCount: PropTypes.number.isRequired,
    messageLimit: PropTypes.number.isRequired,
    infoLoading: PropTypes.bool.isRequired,
    subscriptionType: PropTypes.string.isRequired,
};

export default DashboardSmtpSubscriptionCard;