import React from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import BlacklistCardActions from './BlacklistCardActions';
import {useTranslation} from 'react-i18next';

const BlacklistFilter = (props) => {
    const {t} = useTranslation();

    return (
        <Filter {...props}>
            <TextInput autoFocus label={t('Email')} source="email"/>
            <SelectInput label={t('Reason')} source="reason.value" choices={[
                {id: 'spam', name: t('Spam')},
                {id: 'manual', name: t('Manual')},
                {id: 'unsubscribed', name: t('Unsubscribed')},
            ]}/>
        </Filter>
    );
}

const ReasonField = ({record, t}) => {
    const map = {
        'spam': t('Spam'),
        'manual': t('Manual'),
        'unsubscribed': t('Unsubscribed'),
    };

    return <TextField record={{parsed: map[record.reason]}} source={'parsed'}/>;
}

const BlacklistList = (props) => {
    const {t} = useTranslation();

    return (
        <List {...props} filters={<BlacklistFilter t={t}/>} sort={{field: 'email', order: 'ASC'}}
              actions={<BlacklistCardActions/>}>
            <Datagrid>
                <TextField source="email" label={t('Contact')}/>
                <ReasonField label={t('Reason')} sortable={false} t={t}/>
                <TextField source="createdAt" label={t('Created')}/>
                {props.hasEdit && <EditButton/>}
            </Datagrid>
        </List>
    );
}

export default BlacklistList;