import React from 'react';
import PropTypes from 'prop-types';
import {CardActions} from 'react-admin'
import PreviewButtonContainer from './PreviewButtonContainer';
import MessagePreviewContainer from './MessagePreviewContainer';

const MessageEditActions = (props) => (
    <CardActions>
        <MessagePreviewContainer {...props}/>
        <PreviewButtonContainer/>
    </CardActions>
);

MessageEditActions.propTypes = {
    id: PropTypes.string,
    messageSaving: PropTypes.bool.isRequired
};

export default MessageEditActions;