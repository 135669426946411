import React from 'react';
import BlacklistButton from './BlacklistButton';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

const BlacklistButtonContainer = (props) => (
    <BlacklistButton {...props} />
);

const mapDispatchToProps = (dispatch) => {
    return {
        onClickHandler: () => dispatch(push(`/blacklist/contacts`))
    }
};

export default connect(null, mapDispatchToProps)(BlacklistButtonContainer);