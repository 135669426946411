import {call, put, select, takeEvery} from 'redux-saga/effects';
import {LOCATION_CHANGE} from 'react-router-redux';
import {
    UPDATE,
    refreshView,
    showNotification
} from 'react-admin';
import {
    ARCHIVE_RECORD,
    MESSAGE_DUPLICATE,
    MESSAGE_SAVE,
    MESSAGE_TOPOL_SAVED,
    MESSAGE_TOPOL_SAVING,
    RESTORE_RECORD,
} from '../actions/actionTypes';
import {
    extractLinksFromMessage,
    messageChange,
    messageDestroy,
    messagePreviewHide,
    messageSave,
    messageSaved,
    messageSetLinks,
} from '../actions/messageActions';
import {
    selectMessageId,
    selectMessageSaveAction,
    selectMessageSendPreviewTo,
    selectMessage
} from '../selectors/messageSelectors';
import API from '../api';
import {push} from 'react-router-redux';
import {dataProvider} from '../components/http/dataProvider';
import i18n from 'i18next';

export function* destroyMessage(action) {
    const id = yield select(selectMessageId);

    if (!id) {
        return;
    }

    const {payload: {pathname}} = action;

    if (!pathname.includes('/messages')) {
        yield put(messageDestroy());
    }

    if (pathname.endsWith('/messages')) {
        yield put(messageDestroy());
    }
}

export function* messageExportLinks(html, text) {
    const message = yield select(selectMessage);
    const content = {
        html: html,
        text: text,
    };

    content.links = yield call(extractLinksFromMessage, content, message.links);
    content.id = message.id;

    yield put(messageSetLinks(content.links));
}

export function* messageTopolSave() {
    if (!window.TopolPlugin) {
        return;
    }

    yield call(window.TopolPlugin.save);
}

export function* messageTopolSetHtml(action) {
    yield put(messageChange('html', action.payload.html));
    yield put(messageChange('structured', action.payload.json));
    yield put(messageSave());
}

export function* handleMessageSave() {
    const saveAction = yield select(selectMessageSaveAction);
    const message = yield select(selectMessage);
    yield call(messageExportLinks, message.html, message.text);

    const data = {};

    ['html', 'links', 'name', 'publishState', 'structured', 'text', 'privacy', 'title'].forEach(
        prop => data[prop] = message[prop]
    );

    yield call(dataProvider, UPDATE, 'messages', {
        id: message.id,
        data: data
    });

    if (saveAction === 'saveClose') {
        yield put(push('/messages'));
    }

    if (saveAction === 'preview') {
        yield handleMessageSendPreview();
    }

    if (saveAction === 'links') {
        yield put(push(`/messages/${message.id}/2`));
    }
    yield put(messageSaved());
}


export function* messageAddToArchive(action) {
    if ('messages' !== action.payload.resource) {
        return;
    }
    yield call(API.messageAddToArchive, action.payload.id);
    yield put(refreshView());
}

export function* messageRestoreFromArchive(action) {
    if ('messages' !== action.payload.resource) {
        return;
    }
    yield call(API.messageRestoreFromArchive, action.payload.id);
    yield put(refreshView());
}

export function* handleMessageSendPreview() {
    const message = yield select(selectMessage);
    const to = yield select(selectMessageSendPreviewTo);

    yield put(showNotification(i18n.t('Sending preview...')));
    yield put(messagePreviewHide());
    yield call(API.sendMessagePreview, message.id, to);
    yield put(showNotification(i18n.t('Preview sent')));
}

export function* messageDuplicate(action) {
    const {payload: {id, name}} = action;

    try {
        const response = yield call(API.messageDuplicate, id, name);

        yield put(push(`/messages/${response.json.id}`));
    } catch (exception) {
        console.log(exception);
        yield put(showNotification(i18n.t('Form exception: %{message}', {message: exception.message})));
    }
}

export function* messageSagas() {
    yield takeEvery(LOCATION_CHANGE, destroyMessage);
    yield takeEvery(MESSAGE_TOPOL_SAVING, messageTopolSave);
    yield takeEvery(MESSAGE_TOPOL_SAVED, messageTopolSetHtml);
    yield takeEvery(MESSAGE_SAVE, handleMessageSave);
    yield takeEvery(ARCHIVE_RECORD, messageAddToArchive);
    yield takeEvery(RESTORE_RECORD, messageRestoreFromArchive);
    yield takeEvery(MESSAGE_DUPLICATE, messageDuplicate);
}
