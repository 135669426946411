import React from "react";
import {Button, Link} from 'react-admin';
import RotateRight from '@material-ui/icons/RotateRight';
import {connect} from 'react-redux';
import {adminSetUser} from '../../../actions/adminActions';

const SubscriptionsButton = ({onClick, record: {id}}) => {
    return <Link to={`/admin/user/subscriptions/?owner=${id}`} onClick={onClick}>
        <Button label="Subscriptions">
            <RotateRight/>
        </Button>
    </Link>
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const {record: {id}} = ownProps;
    return {
        onClick: () => {
            dispatch(adminSetUser(id));
        }
    }
};

export default connect(null, mapDispatchToProps)(SubscriptionsButton);

