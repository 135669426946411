import React from 'react';
import {connect} from 'react-redux';
import {
    Dialog,
    DialogTitle,
} from '@material-ui/core';
import {LoginForm} from 'react-admin';
import decodeJwt from 'jwt-decode';
import {
    getAuthDialogDisplay,
    getAuthDialogInterval,
} from '../../selectors/UiSelector';
import {
    uiAuthDialogShow,
    uiAuthDialogSetInterval,
} from '../../actions/UiActions';
import {pathSelector} from "../../selectors/accountSelectors";
import {withTranslation} from "react-i18next";

class AuthDialog extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.interval) {
            clearInterval(this.props.interval);
        }

        this.props.dispatch(uiAuthDialogSetInterval(setInterval(this.checkToken, 10000)));
    }

    checkToken = () => {
        if (this.props.display) {
            return;
        }

        const token = localStorage.getItem('token');

        if (!token) {
            this.props.dispatch(uiAuthDialogShow());
            return;
        }

        const decodedToken = decodeJwt(localStorage.getItem('token'));

        if (decodedToken.exp < (Date.now() / 1000) + 5) {
            this.props.dispatch(uiAuthDialogShow());
        }
    };

    render = () => {
        const {display, pathname, t} = this.props;

        return (
            <Dialog open={display}>
                <DialogTitle>{t('Your session has been expired')}</DialogTitle>
                <LoginForm redirectTo={pathname}/>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    display: getAuthDialogDisplay(state),
    interval: getAuthDialogInterval(state),
    pathname: pathSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AuthDialog));
