import {
    UI_AUTH_DIALOG_SHOW,
    UI_AUTH_DIALOG_SET_INTERVAL,
    UI_BLACKLIST_IMPORT_DIALOG_HIDE,
    UI_BLACKLIST_IMPORT_DIALOG_SHOW,
    UI_CONTACT_LIST_SET_LIST_NAME
} from './actionTypes';

export const uiAuthDialogShow = () => ({type: UI_AUTH_DIALOG_SHOW});
export const uiAuthDialogSetInterval = (id) => ({
    type: UI_AUTH_DIALOG_SET_INTERVAL,
    payload: {
        id,
    }
});
export const uiBlacklistImportDialogHide = () => ({type: UI_BLACKLIST_IMPORT_DIALOG_HIDE});
export const uiBlacklistImportDialogShow = () => ({type: UI_BLACKLIST_IMPORT_DIALOG_SHOW});
export const uiContactListSetListName = (name) => ({
    type: UI_CONTACT_LIST_SET_LIST_NAME,
    payload: {
        name,
    }
});