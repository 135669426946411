import React from 'react';

const ReceiverStateField = ({record: {state}, translate: t}) => {
    const translator = {
        'active': t('active'),
        'paused': t('paused'),
    };

    return <span>{translator[state]}</span>;
}

export default ReceiverStateField;
