import { put, select, takeEvery } from 'redux-saga/effects';
import {LOCATION_CHANGE} from 'react-router-redux';
import {reset} from 'redux-form';
import {ACCOUNT_RESET_PASSWORD} from '../actions/actionTypes';
import API from '../api';
import {showNotification} from 'react-admin';
import i18n from "i18next";

import {
    accountGetInfo,
    accountGetStats,
    accountSetAttributes
} from '../actions/accountActions';

import {
    accountIdSelector,
    attributesSelector,
    getResetPasswordEmail,
} from '../selectors/accountSelectors';

export function* fetchAttributes(action) {
    try {
        if (action.type === 'RA/CRUD_CREATE_SUCCESS' ||
            action.type === 'RA/CRUD_UPDATE_SUCCESS' ||
            action.type === 'RA/CRUD_DELETE_SUCCESS' ||
            action.type === 'RA/CRUD_DELETE_MANY_SUCCESS'
        ) {
            if (action.meta.resource !== 'attributes') {
                return true;
            }
        }

        if (action.type === 'RA/USER_CHECK' && false === action.visible) {
            return true;
        }

        const attributes = yield select(attributesSelector);

        if (action.type === 'RA/USER_CHECK' && null !== attributes) {
            return true;
        }

        const response = yield API.getAttributes();
        const {status, json } = response;
        if (status !== 200) {
            new Error('Could not retrieve attributes');
        }
        yield put(accountSetAttributes(json['hydra:member']));
    } catch (error) {
        yield console.log(error);
    }
}

function* fetchAccountInfo (action) {
    if (action.payload.pathname === '/login'
        || action.payload.pathname.startsWith('/set-credentials/')) {
        return;
    }
    const id = yield select(accountIdSelector);
    if (null !== id && action.payload.pathname !== '/home') {
        return;
    }
    const info = yield API.accountGetInfo();
    if (undefined === info) {
        return;
    }
    yield put(accountGetInfo(info.json));
}

function* fetchAccountStats (action) {
    if (action.payload.pathname !== '/home') {
        return;
    }
    const stats = yield API.accountGetStats();
    if (undefined === stats) {
        return;
    }
    yield put(accountGetStats(stats.json));
}

function* accountResetPassword() {
    yield put({type: 'RA/USER_LOGIN_LOADING'});

    const email = yield select(getResetPasswordEmail);

    if (!email) {
        yield put(showNotification(i18n.t('Email is required')));
        yield put({type: 'RA/USER_LOGIN_SUCCESS'});
        return;
    }

    yield API.resetUserPassword(email);

    yield put(reset('recoverPassword'));
    yield put({type: 'RA/USER_LOGIN_SUCCESS'});
    yield put(showNotification(i18n.t('Password reset has been submitted, you will receive an email shortly')));
}

export function* watchForAccount() {
    yield takeEvery('RA/USER_CHECK', fetchAttributes);
    yield takeEvery('RA/CRUD_CREATE_SUCCESS', fetchAttributes);
    yield takeEvery('RA/CRUD_UPDATE_SUCCESS', fetchAttributes);
    yield takeEvery('RA/CRUD_DELETE_SUCCESS', fetchAttributes);
    yield takeEvery('RA/CRUD_DELETE_MANY_SUCCESS', fetchAttributes);
    yield takeEvery(LOCATION_CHANGE, fetchAccountInfo);
    yield takeEvery(LOCATION_CHANGE, fetchAccountStats);
    yield takeEvery(ACCOUNT_RESET_PASSWORD, accountResetPassword);
}
