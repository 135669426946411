import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CampaignReportMetricBox from './CampaignReportMetricBox';
import {useTranslation} from 'react-i18next';

const CampaignReportMetricsBoxRows = ({id, report, ...rest}) => {
    const {t} = useTranslation();

    return (
        <Grid container spacing={0} {...rest}>
            <Grid item xs={3}>
                <CampaignReportMetricBox id={id} label={t('Opened')} type={'opened'} value={report.totalOpenActions}/>
            </Grid>
            <Grid item xs={3}>
                <CampaignReportMetricBox id={id} label={t('Clicked')} type={'clicked'} value={report.totalClickActions}/>
            </Grid>
            <Grid item xs={3}>
                <CampaignReportMetricBox id={id} label={t('Bounced')} type={'bounced'} value={report.contactBounceActions}/>
            </Grid>
            <Grid item xs={3}>
                <CampaignReportMetricBox id={id} label={t('Unsubscribed')} type={'unsubscribed'} value={report.contactUnsubscribeActions}/>
            </Grid>
        </Grid>
    );
};

CampaignReportMetricsBoxRows.propTypes = {
    id: PropTypes.string.isRequired,
    report: PropTypes.object.isRequired
};

export default CampaignReportMetricsBoxRows;