import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin'
import {passwordValidation} from './userValidation';
import UserFormBasic from './UserFormBasic';

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <UserFormBasic/>
            <TextInput source={'password'} label={'Password'} validate={passwordValidation} fullWidth/>
        </SimpleForm>
    </Create>
);

export default UserCreate;