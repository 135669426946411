import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CampaignToolbar from "./CampaignToolbar";

const CampaignToolbarContainer = (props) => {
    // get props
    const {launch, method, recipients = 0, save, schedule, tab, wizardSaving, ...rest} = props;

    // use state
    const [showLaunchDialog, setShowLaunchDialog] = useState(false);
    const [showScheduleDialog, setShowScheduleDialog] = useState(false);

    return <CampaignToolbar {...rest}
                            displayLaunchDialog={() => setShowLaunchDialog(true)}
                            displayScheduleDialog={() => setShowScheduleDialog(true)}
                            hideLaunchDialog={() => setShowLaunchDialog(false)}
                            hideScheduleDialog={() => setShowScheduleDialog(false)}
                            launch={launch}
                            method={method}
                            recipients={recipients}
                            schedule={schedule}
                            showLaunchDialog={showLaunchDialog}
                            showScheduleDialog={showScheduleDialog}
                            save={save}
                            wizardSaving={wizardSaving}
                            tab={tab}/>;
};

CampaignToolbarContainer.propTypes = {
    launch: PropTypes.func,
    method: PropTypes.string,
    recipients: PropTypes.number,
    save: PropTypes.func.isRequired,
    schedule: PropTypes.func,
    tab: PropTypes.number.isRequired,
    wizardSaving: PropTypes.bool.isRequired
};

export default CampaignToolbarContainer;
