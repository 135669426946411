import React from 'react';
import {connect} from 'react-redux';
import Script from 'react-load-script'
import forEach from 'lodash/forEach';
import {messageTopolSaved} from "../../actions/messageActions";
import {Modal} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {getLocale} from '../../util/i18n';
import {getLandingPagesMergeTags} from "../../util/topol/landingPages";

class Topol extends React.Component {
    state = {
        duplicating: false
    };

    async scriptLoaded() {
        const {accountId, messageId, dispatch, t} = this.props;

        const landingPagesMergeTags = await getLandingPagesMergeTags(t);

        // noinspection HtmlUnknownTarget
        const TOPOL_OPTIONS = {
            id: '#topol-container',
            language: getLocale(),
            authorize: {
                apiKey: 'Med6kGtUDYWYq2SLbIVQrdL3SBOq77rikND1Ca4Z4BWaYf6TU6rko6zfeUda',
                userId: accountId,
            },
            removeTopBar: true,
            light: true,
            callbacks: {
                onSaveAndClose: function (json, html) {
                    dispatch(messageTopolSaved({json: JSON.stringify(json), html}));
                },
                onSave: function (json, html) {
                    dispatch(messageTopolSaved({json: JSON.stringify(json), html}));
                },
            },
            contentBlocks: {
                product: {
                    disabled: true,
                    disabledText: 'Disabled',
                    hidden: true,
                }
            },
            googleFonts: [
                'Anton',
                'Bitter',
                'Baloo Tamma 2',
                'Bebas Neue',
                'Cabin',
                'Dancing Script',
                'Inconsolata',
                'Indie Flower',
                'Lato',
                'Libre Baskerville',
                'Lobster',
                'Merriweather',
                'Nunito',
                'Open Sans',
                'Pangolin',
                'PT Sans',
                'PT Serif',
                'Roboto',
                'Shadows Into Light',
                'Sriracha',
                'Baloo Tamma 2',
                'Ubuntu',
            ],
            fonts: [
                {
                    'label': 'Anton',
                    'style': 'Anton, sans-serif'
                },
                {
                    'label': 'Arial',
                    'style': 'Arial, sans-serif'
                },
                {
                    'label': 'Bebas Neue,',
                    'style': 'Bebas Neue, sans-serif'
                },
                {
                    'label': 'Bitter',
                    'style': 'Bitter, serif'
                },
                {
                    'label': 'Baloo Tamma 2',
                    'style': 'Baloo Tamma 2, sans-serif'
                },
                {
                    'label': 'Cabin',
                    'style': 'Cabin, sans-serif'
                },
                {
                    'label': 'Dancing Script',
                    'style': 'Dancing Script, sans-serif'
                },
                {
                    'label': 'Georgia',
                    'style': 'Georgia, serif'
                },
                {
                    'label': 'Inconsolata',
                    'style': 'Inconsolata, cursive'
                },
                {
                    'label': 'Indie Flower',
                    'style': 'Indie Flower, sans-serif'
                },
                {
                    'label': 'Helvetica',
                    'style': 'Helvetica, sans-serif'
                },
                {
                    'label': 'Lato',
                    'style': 'Lato, sans-serif'
                },
                {
                    'label': 'Libre Baskerville',
                    'style': 'Libre Baskerville, serif'
                },
                {
                    'label': 'Lobster',
                    'style': 'Lobster, sans-serif'
                },
                {
                    'label': 'Merriweather',
                    'style': 'Merriweather, serif'
                },
                {
                    'label': 'Nunito',
                    'style': 'Nunito, sans-serif'
                },
                {
                    'label': 'Open Sans',
                    'style': 'Open Sans, sans-serif'
                },
                {
                    'label': 'Open Sans Condensed',
                    'style': 'Open Sans Condensed, sans-serif'
                },
                {
                    'label': 'Pangolin',
                    'style': 'Pangolin, sans-serif'
                },
                {
                    'label': 'PT Sans',
                    'style': 'PT Sans, sans-serif'
                },
                {
                    'label': 'PT Serif',
                    'style': 'PT Serif, serif'
                },
                {
                    'label': 'Roboto',
                    'style': 'Roboto, Tahoma, sans-serif'
                },
                {
                    'label': 'Shadows Into Light',
                    'style': 'Shadows Into Light, sans-serif'
                },
                {
                    'label': 'Sriracha',
                    'style': 'Sriracha, Tahoma, cursive'
                },
                {
                    'label': 'Ubuntu',
                    'style': 'Ubuntu, sans-serif'
                },
                {
                    'label': 'Verdana',
                    'style': 'Verdana, sans-serif'
                }
            ],
            mergeTags: [
                {
                    name: t('Merge tags'),
                    items: [
                        {
                            value: "*|email|*",
                            text: "Email",
                            label: t('Contact attribute: %{field}', {field: 'Email'}),
                        }
                    ]
                },
                {
                    name: t('Special links'),
                    items: [
                        {
                            value: '<a href="*|unsubscribe|*">Unsubscribe</a>',
                            text: t('Unsubscribe link'),
                            label: t('Unsubscribe link'),
                        },
                        {
                            value: '<a href="*|show-in-browser|*">Web version</a>',
                            text: t('Web version'),
                            label: t('Web version'),
                        },
                    ]
                },
                landingPagesMergeTags
            ],
            sendTestEmail: false,
        };

        forEach(this.props.attributes, (value) => {
            TOPOL_OPTIONS.mergeTags[0].items.push({
                value: '*|' + value.name + '|*',
                text: value.name,
                label: t('Contact attribute: %{field}', {field: value.name}),
            });
        });

        window.TopolPlugin.init(TOPOL_OPTIONS);

        // duplicating
        if (this.props.json !== '' && this.props.html === '') {
            this.setState({duplicating: true});

            setTimeout(() => {
                window.TopolPlugin.load(this.props.json);
                this.setState({duplicating: false});
            }, 5000);
        } else if (this.props.json !== '') {
            window.TopolPlugin.load(this.props.json);
        }

        if (!window.autoSaveTimer) {
            window.autoSaveTimer = setInterval(() => window.TopolPlugin.save(), 60 * 1000);
        }
    }

    componentWillUnmount() {
        if (window.TopolPlugin) {
            window.TopolPlugin.destroy();
        }
    }

    render() {
        const {accountId, messageId} = this.props;
        const {duplicating} = this.state;

        if (!accountId || !messageId) {
            return <span/>;
        }

        return (
            <div>
                {duplicating &&
                <Modal open={true}>
                    <span/>
                </Modal>
                }

                <Script url={'https://d5aoblv5p04cg.cloudfront.net/editor-3/loader/build.js'}
                        onLoad={() => this.scriptLoaded()}/>
                <div id={'topol-container'} style={{height: '65vh'}}/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(null, mapDispatchToProps)(withTranslation()(Topol));
