export const localize = (date) => {
    const parts = date.split(' ');
    const input = parts[0] + 'T' + parts[1] + '.000+00:00';
    const formatter = new Intl.DateTimeFormat('be-NL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    const dateParts = formatter.formatToParts(
        new Date(
            Date.parse(input)
        )
    );

    return dateParts[4].value + '-' +
        dateParts[2].value + '-' +
        dateParts[0].value + ' ' +
        dateParts[6].value + ':' +
        dateParts[8].value + ':' +
        dateParts[10].value;
}
